import React from "react";
function frame6() {
  return (
    <div className=" d-flex flex-column c-gray font-Montserrat slider-text">
      <h5
        className="text-center tw-mb-5"
        style={{ fontWeight: "bolder", color: "black",fontSize: "24px"  }}
      >
        Garden Pathways
      </h5>
      <p>
        Garden pathways are often seen as purely functional elements, simply a
        way to get from one point to another without trampling on the garden
        beds. However, they can be so much more than that. When thoughtfully
        designed and integrated into the overall landscape, garden pathways can
        play a vital role in enhancing the beauty, functionality, and even the
        emotional impact of your outdoor space.
      </p>
    </div>
  );
}

export default frame6;
