import React, { useEffect, useState } from "react";
import logo from "./Images/Tarang-logo.png";
import { Link, useLocation } from "react-router-dom";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useBreakpointValue,
  Stack,
} from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";

function Navbar({popUpActive}) {
  const { pathname } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isDrawer = useBreakpointValue({ base: true, md: false });
  const [isSticky, setIsSticky] = useState(false);
  const [isActive,setActive] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={"tw-fixed tw-min-w-[100%] tw-z-50  md:text-white w-screen h-fit flex-row flex justify-around md:mx-6 gap-32 lg:gap-56  items-center "}>
      {isDrawer ? (
        <>
        <div>

        </div>
          <div
            className={`${isSticky?"tw-fixed tw-top-[4.3rem] tw-min-w-[100%] tw-mb-1  tw-flex tw-justify-between tw-py-2 tw-h-[69px]":"tw-relative tw-top-24 tw-mb-1 tw-flex tw-justify-between tw-rounded-2xl tw-px-4 tw-mx-9 tw-py-2 tw-h-[69px]"}`}
            style={{
              backgroundColor: "#ffffff",
              marginTop: "-69px",
              filter: "blur(1.3px)",
              boxShadow:"2px 2px 20px gray"
              
            }}
          >
            <img src={logo} alt="logo"></img>
            <button className="" onClick={onOpen}>
              <GiHamburgerMenu className="humb" size={25} />
            </button>
          </div>
          <div
            className={`${isSticky?" tw-fixed tw-top-[4.3rem] tw-min-w-[100%] tw-flex tw-justify-between tw-px-5 tw-py-2  tw-h-[62px]":"tw-relative tw-top-24 tw-flex tw-justify-between tw-px-4 tw-py-2 tw-mx-10 tw-h-[62px]"}`}
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "10px",
              marginTop: "-69px",
            }}
          >
            <img src={logo} alt="logo"></img>
            <button className="" onClick={onOpen}>
              <GiHamburgerMenu className="humb" size={25} />
            </button>
          </div>
          <Drawer
            colorScheme="blackAlpha"
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
          >
            <DrawerOverlay>
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                  <img src={logo} alt="phone" />
                </DrawerHeader>
                <DrawerBody>
                  <Stack>
                    <div className="d-flex flex-row align-items-center justify-content-between nav-div-nest">
                      <div className="tw-flex tw-items-center">
                        <ul style={{ listStyle: "none" }} className="tw-flex pt-4 pb-2 tw-flex-col">
                          <li   className={
                              pathname === "/"
                                ? " d-flex flex-column align-items-center tw-text-[#86241D] tw-bg-[#d7f3aa] hover:tw-text-[#87D900] tw-font-bold"
                                : " d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"
                            }>
                            <Link className="hover:tw-text-[#87D900]" to="/">
                              HOME
                            </Link>
                            {/* <span className="relative hover-bar"></span> */}
                          </li>
                          <li
                            className={
                              pathname === "/products"
                              ? " d-flex flex-row align-items-center justify-content-center tw-text-[#86241D] tw-bg-[#d7f3aa] hover:tw-text-[#87D900] tw-font-bold"
                              : " d-flex flex-row align-items-center justify-content-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"
                            }
                          >
                            <Link
                              className="hover:tw-text-[#87D900]"
                              to="/products"
                            >
                              PRODUCTS 
                            </Link>
                
                          </li>
                         
                            <li
                            className={
                              pathname === "/ourServices"
                              ? " d-flex flex-row align-items-center justify-content-center tw-text-[#86241D] tw-bg-[#d7f3aa] hover:tw-text-[#87D900] tw-font-bold"
                              : " d-flex flex-row align-items-center justify-content-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"
                            }
                          >
                            <Link
                              className="hover:tw-text-[#87D900]"
                              to="/ourServices"
                            >
                              SERVICES
                            </Link>
                
                            { isActive ?<span className="tw-left-2 tw-relative" onClick={()=>{setActive(false)}}><i class="bi bi-chevron-up"></i></span> : <span className="tw-left-2 tw-relative" onClick={()=>{setActive(true)}}><i class="bi bi-chevron-down"></i></span>}
                          </li>
                          <h1 className={isActive ? "tw-flex tw-flex-col tw-w-[320px] tw-text-center product-li" :"tw-flex tw-flex-col tw-w-[320px] tw-text-center product-li-after" }>
                            <Link
                                className={
                                  pathname === "/plantSelling"
                                  ? " d-flex flex-row align-items-center justify-content-center tw-text-[#86241D] tw-bg-[#d7f3aa] hover:tw-text-[#87D900] tw-font-bold tw-py-5 tw-transition-all tw-duration-300"
                                  : " d-flex flex-row align-items-center justify-content-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900] tw-py-5 tw-transition-all tw-duration-300"
                                }
                              to="/plantSelling"
                            >
                              PLANTSELLING
                            </Link>
                            <Link
                                className={
                                  pathname === "/landscaping"
                                  ? " d-flex flex-row align-items-center justify-content-center tw-text-[#86241D] tw-bg-[#d7f3aa] hover:tw-text-[#87D900] tw-font-bold tw-py-5 tw-transition-all tw-duration-300"
                                  : " d-flex flex-row align-items-center justify-content-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900] tw-py-5 tw-transition-all tw-duration-300"
                                }
                              to="/landscaping"
                            >
                            LANDSCAPING
                            </Link>
                            <Link
                                className={
                                  pathname === "/maintenance"
                                  ? " d-flex flex-row align-items-center justify-content-center tw-text-[#86241D] tw-bg-[#d7f3aa] hover:tw-text-[#87D900] tw-font-bold tw-py-5 tw-transition-all tw-duration-300"
                                  : " d-flex flex-row align-items-center justify-content-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900] tw-py-5 tw-transition-all tw-duration-300"
                                }
                              to="/maintenance"
                            >
                            MAINTENANCE
                            </Link>

                            </h1>
                          <li
                            className={
                              pathname === "/certificates"
                              ? " d-flex flex-column align-items-center tw-text-[#86241D] tw-bg-[#d7f3aa] hover:tw-text-[#87D900] tw-font-bold"
                              : " d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"
                            }
                          >
                            <Link  className="hover:tw-text-[#87D900]" to="/certificates">
                              CERTIFICATES
                            </Link>
                            {/* <span
                              className={
                                pathname === "/certificates"
                                  ? " hover-bar-path relative"
                                  : "relative hover-bar"
                              }
                            ></span> */}
                          </li>
                          <li
                            className={
                              pathname === "/clients"
                              ? " d-flex flex-column align-items-center tw-text-[#86241D] tw-bg-[#d7f3aa] hover:tw-text-[#87D900] tw-font-bold"
                              : " d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"
                            }
                          >
                            <Link
                              className="hover:tw-text-[#87D900]"
                              to="/clients"
                            >
                              OUR CLIENTS
                            </Link>
                            {/* <span
                              className={
                                pathname === "/clients"
                                  ? " hover-bar-path relative"
                                  : "relative hover-bar"
                              }
                            ></span> */}
                          </li>
                          <li
                            className={
                              pathname === "/teams"
                              ? " d-flex flex-column align-items-center tw-text-[#86241D] tw-bg-[#d7f3aa] hover:tw-text-[#87D900] tw-font-bold"
                              : " d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"
                            }
                          >
                            <Link
                               className="hover:tw-text-[#87D900]"
                              to="/teams"
                            >
                              {" "}
                              OUR TEAMS
                            </Link>
                            {/* <span
                              className={
                                pathname === "/teams"
                                  ? " hover-bar-path relative"
                                  : "relative hover-bar"
                              }
                            ></span> */}
                          </li>
                          <li
                            className={
                              pathname === "/about"
                              ? " d-flex flex-column align-items-center tw-text-[#86241D] tw-bg-[#d7f3aa] hover:tw-text-[#87D900] tw-font-bold"
                              : " d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"
                            }
                          >
                            <Link
                              className="hover:tw-text-[#87D900]"
                              to="/about"
                            >
                              ABOUT US
                            </Link>
                            {/* <span
                              className={
                                pathname === "/about"
                                  ? " hover-bar-path relative"
                                  : "relative hover-bar"
                              }
                            ></span> */}
                          </li>
                          <li
                            className={
                              pathname === "/contact"
                              ? " d-flex flex-column align-items-center tw-text-[#86241D] tw-bg-[#d7f3aa] hover:tw-text-[#87D900] tw-font-bold"
                              : " d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"
                            }
                          >
                            <Link
                             className="hover:tw-text-[#87D900]"
                              to="/contact"
                            >
                              CONTACT
                            </Link>
                            {/* <span
                              className={
                                pathname === "/contact"
                                  ? " hover-bar-path relative"
                                  : "relative hover-bar"
                              }
                            ></span> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Stack>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        </>
      ) : (
        <div  >
        <div
          className={`${isSticky?" tw-fixed tw-top-[4.2rem] tw-w-[100%] tw-pt-1 d-flex flex-row align-items-center justify-content-between px-4":"d-flex flex-row align-items-center justify-content-between tw-px-[12%] tw-w-12/12 tw-rounded-2xl tw-h-[77px] nav-div-nest tw-absolute tw-left-[1%] tw-right-[1%] tw-top-[97px]"}`}
          style={{
            backgroundColor: "#ffffff",
  
            marginTop: "-75px",
            filter: "blur(1.7px)",
            boxShadow:"2px 2px 20px gray"
            
          }}
        >
          <div className="tw-z-20">
            
          </div>
          <div
            className="tw-flex tw-text-white tw-items-center tw-p-6 tw-z-30"
          
          >
            <ul
              style={{ listStyle: "none", fontSize: "1.2vW" }}
              className="tw-flex tw-text-white"
            >
              <li className=" hover:tw-text-[#86241D]  hover:tw-font-bold">
                <Link className="hover:tw-font-bold" to="/">
                  HOME
                </Link>
                <span className=" tw-text-white relative hover-bar"></span>
              </li>
              <li
                className={
                  pathname === "/products "
                    ? " "
                    : ""
                }
              >
                <Link className="tw-text-white " to="/products">
                  PRODUCTS
                </Link>
                <span
                
                ></span>
              </li>
              <li
                className={
                  pathname === "/ourServices"
                    ? " tw-text-white"
                    : " r"
                }
              >
                <Link className="tw-text-white" to="/ourServices">
                  {" "}
                  SERVICES
                </Link>
                <span
                
                ></span>
              </li>
              <li
                className={
                  pathname === "/certificates"
                    ? " tw-text-white"
                    : " tw-text-white"
                }
              >
                <Link className="tw-text-white" to="/certificates">
                  CERTIFICATES
                </Link>
               
              </li>
              <li
                className={
                  pathname === "/clients"
                    ? " tw-text-white item-div d-flex flex-column align-items-center"
                    : "item-div d-flex flex-column align-items-center"
                }
              >
                <Link className="tw-text-white" to="/clients">
                  OUR CLIENTS
                </Link>
          
              </li>
              <li
                className={
                  pathname === "/teams"
                    ? " tw-text-white item-div d-flex flex-column align-items-center"
                    : "item-div d-flex flex-column align-items-center"
                }
              >
                <Link className="tw-text-white" to="/teams">
                  {" "}
                  OUR TEAMS
                </Link>
            
              </li>
              <li
                className={
                  pathname === "/about"
                    ? "tw-text-white "
                    : "item-div"
                }
              >
                <Link className="tw-text-white" to="/about">
                  ABOUT US
                </Link>
           
              </li>
              <li
                className={
                  pathname === "/contact"
                    ? " tw-text-white"
                    : ""
                }
              >
                <Link className="tw-text-white" to="/contact">
                  CONTACT
                </Link>
            
              </li>
            </ul>
          </div>
        </div>
        <div
          className={ `${isSticky? " tw-fixed tw-top-[4.2rem] tw-w-[100%] tw-pt-1 d-flex flex-row align-items-center justify-content-between px-4":"d-flex flex-row align-items-center justify-content-between  mx-5 px-4 nav-div-nest tw-relative tw-top-[95px]"}`}
          style={{
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            marginTop: "-68px",
          }}
        >
          <div className={ popUpActive ?"tw-z-10" :"tw-z-20"}>
            <img className="tw-pb-2" src={logo} alt="logo"></img>
          </div>
          <div
            className="tw-flex tw-items-center  tw-z-30"
            style={{ filter: "blur(0px)" }}
          >
            <ul
              style={{ listStyle: "none", fontSize: "1.2vW" }}
              className="tw-flex "
            >
              <li className="item-div d-flex flex-column align-items-center ">
                <Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to="/">
                  HOME
                </Link>
                <span className="relative hover-bar"></span>
              </li>
              {/* <li
                className={
                  pathname === "/products"
                    ? " tw-text-[#86241D] item-div d-flex flex-column align-items-center tw-font-bold"
                    : "item-div d-flex flex-column align-items-center"
                }
              >
                <Link className="hover:tw-text-[#86241D]" to="/products">
                  PRODUCTS
                </Link>
                <span
                  className={
                    pathname === "/products"
                      ? " hover-bar-path relative"
                      : "relative hover-bar"
                  }
                ></span>
              </li> */}
              <li    className={
                  pathname === "/products"
                    ? " tw-text-[#86241D] item-div d-flex flex-column align-items-center tw-font-bold dropdown"
                    : "item-div d-flex flex-column align-items-center "
                }>
                <Link className="hover:tw-text-[#86241D] hover:tw-font-bold " to='/products'>
                PRODUCTS
                </Link>
               <span   className={
                    pathname === "/products"
                      ? " hover-bar-path relative"
                      : "relative hover-bar"
                  }></span>
            
              </li>
              <li
                className={
                  pathname === "/ourServices"
                    ? " tw-text-[#86241D] item-div d-flex flex-column align-items-center tw-font-bold drop"
                    : "item-div d-flex flex-column align-items-center drop"
                }
              >
                <Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to="/ourServices">
                  {" "}
                  SERVICES
                </Link>
                <span
                  className={
                    pathname === "/ourServices"
                      ? " hover-bar-path relative"
                      : "relative hover-bar"
                  }
                ></span>
                            <ul class="menu  tw-bg-white tw-absolute tw-top-10 border tw-rounded-lg tw-text-gray-700 tw-pt-1">
      
      <li className=" d-flex flex-column tw-py-2 align-items-center tw-border-b-2"><Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/plantSelling'> PLANT SELLING</Link>
    {/* <span className="relative hover-bar"></span> */}
    </li>
    <li className=" d-flex flex-column tw-py-2 align-items-center tw-border-b-2"><Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/landscaping'>LANDSCAPING</Link>
     {/* <span className="relative hover-bar"></span> */}
    </li>
    <li className=" d-flex flex-column tw-py-2 align-items-center"><Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/maintenance'>MAINTENANCE</Link>
     {/* <span className="relative hover-bar"></span> */}
    </li>
  </ul>
              </li>
              <li
                className={
                  pathname === "/certificates"
                    ? " tw-text-[#86241D] item-div d-flex flex-column align-items-center tw-font-bold"
                    : "item-div d-flex flex-column align-items-center"
                }
              >
                <Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to="/certificates">
                  CERTIFICATES
                </Link>
                <span
                  className={
                    pathname === "/certificates"
                      ? " hover-bar-path relative"
                      : "relative hover-bar"
                  }
                ></span>
              </li>
              <li
                className={
                  pathname === "/clients"
                    ? " tw-text-[#86241D] item-div d-flex flex-column align-items-center tw-font-bold"
                    : "item-div d-flex flex-column align-items-center"
                }
              >
                <Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to="/clients">
                  OUR CLIENTS
                </Link>
                <span
                  className={
                    pathname === "/clients"
                      ? " hover-bar-path relative"
                      : "relative hover-bar"
                  }
                ></span>
              </li>
              <li
                className={
                  pathname === "/teams"
                    ? " tw-text-[#86241D] item-div d-flex flex-column align-items-center tw-font-bold"
                    : "item-div d-flex flex-column align-items-center"
                }
              >
                <Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to="/teams">
                  {" "}
                  OUR TEAMS
                </Link>
                <span
                  className={
                    pathname === "/teams"
                      ? " hover-bar-path relative"
                      : "relative hover-bar"
                  }
                ></span>
              </li>
              <li
                className={
                  pathname === "/about"
                    ? " tw-text-[#86241D] item-div d-flex flex-column align-items-center tw-font-bold"
                    : "item-div d-flex flex-column align-items-center"
                }
              >
                <Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to="/about">
                  ABOUT US
                </Link>
                <span
                  className={
                    pathname === "/about"
                      ? " hover-bar-path relative"
                      : "relative hover-bar"
                  }
                ></span>
              </li>
              <li
                className={
                  pathname === "/contact"
                    ? " tw-text-[#86241D] item-div d-flex flex-column align-items-center tw-font-bold"
                    : "item-div d-flex flex-column align-items-center"
                }
              >
                <Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to="/contact">
                  CONTACT
                </Link>
                <span
                  className={
                    pathname === "/contact"
                      ? " hover-bar-path relative"
                      : "relative hover-bar"
                  }
                ></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      )}
    </header>
  );
}

export default Navbar;
