import React, { useContext, useEffect, useState } from "react";
import background from "../Images/AboutUs/aboutus.png";
import leafBackground from "../Images/AboutUs/leafBackground.png";
import founder from "../Images/AboutUs/founder.png";
import frame1 from "../Images/AboutUs/section1.png";
import frame2 from "../Images/AboutUs/section2.png";
import frame3 from "../Images/AboutUs/section3.png";
import frame4 from "../Images/AboutUs/section4.png";
import frame5 from "../Images/AboutUs/section5.png";
import frame6 from "../Images/AboutUs/section6.png";
import AnimateContext from "../Components/useContext";
import { useSpring, animated } from "react-spring";
import { motion } from "framer-motion";
import Connect from "../Components/Connect";
import Navbar2 from "../Navbar2";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import ReactGA from "react-ga4";

function About() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "About",
    });
  }, []);
  const animate = useContext(AnimateContext);
  const [counterOn, setCounterOn] = useState(false);

  return (
    <motion.div
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
      className="tw-overflow-hidden tw-mb-[10%]"
    >
      <Navbar2 />
      <Connect />
      <div
        className="tw-bg-cover tw-h-80 tw-flex tw-justify-center tw-items-center"
        style={{ backgroundImage: `url(${background})` }}
      >
        <h1 className="tw-font-Montserrat tw-text-3xl tw-font-bold tw-text-emerald-50">
          ABOUT US
        </h1>
      </div>
      <div className="tw-flex sm:tw-flex-row tw-flex-col tw-justify-evenly tw-font-Montserrat sm:tw-mx-8 tw-mx-5">
        <motion.p
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
          className="sm:tw-p-10 tw-text-lg tw-text-justify"
          style={{ color: "#707070" }}
        >
          <p className="tw-my-10">
            At TARAaang Landscape we are successfully serving our valuable
            clients for last 13 years and all of our premium clients are
            satisfied with our dedicated services and offerings. We supply our
            plants and seeds in all over India and also some another country in
            the world. We also provide plant pots supply for our local clients
            because off shipping problem. You get from our nursery one of the
            best quality and variety of plants, seeds among all nursery. Our
            capabilities and services are well being with client’s satisfaction.
          </p>
          <p className="tw-my-10">
            We are situated in Barrackpore, West Bengal, aims to promote nature
            and environmental sustainability, to shield our earth against
            pollution and encourage people to purchase more plants to be with
            nature. With our mission to build an excellent reputation and
            growing our business by selling the most beautiful Indoor Plants,
            Garden Furniture, Fertilizer and more, we offer the best gardening
            and garden decoration products.
          </p>
          <p>
            The Team was founded by{" "}
            <span className="tw-font-semibold">Mr. Mithun Chowdhary</span> - who
            is expert in horticulture and agriculture who was one of the{" "}
            <span className="tw-font-semibold">
              Best Student of AGRI HORTICULTURE SOCIETY OF INDIA, ALIPORE
              KOLKATA
            </span>
          </p>
        </motion.p>

        <motion.img
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
          className="tw-p-[1.5rem] tw-mt-10"
          src={founder}
          alt="founder"
        ></motion.img>
      </div>
      <div className="tw-mt-[10%] sm:tw-mb-0 tw-mb-[9%]">
        <motion.h1
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          style={{ fontWeight: "600" }}
          className="  text-center font-Montserrat sm:tw-text-[36px] tw-text-[24px]"
        >
          {
            <span>
              Why Choosing{" "}
              <span
                className={` ${
                  animate ? "logo is-animetion c-green" : "c-green"
                }`}
              >
                <span>U</span>
                <span>S</span>
              </span>
            </span>
          }
        </motion.h1>
      </div>
      <div className="tw-flex tw-justify-evenly tw-flex-col sm:tw-flex-row tw-ml-4 sm:tw-mx-0 tw-p-0 sm:tw-p-20 tw-pb-5">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { duration: 1, delay: 0.3 },
          }}
          className="tw-flex"
        >
          <div>
            <img src={frame3} alt="aboutus"></img>
          </div>
          <div className="tw-h-0">
            <span
              className="tw-m-4 tw-mb-0 tw-px-2 tw-py-1 tw-rounded-sm"
              style={{ backgroundColor: "#66A400", color: "white" }}
            >
              20+ Teams
            </span>
            <img className="tw-p-4 tw-pb-0" src={frame1} alt="about"></img>
            <img className="tw-p-4" src={frame2} alt="about"></img>
          </div>
        </motion.div>

        <div className=" tw-flex tw-flex-col sm:tw-w-2/4 sm:tw-p-10 sm:tw-pt-0 tw-pt-0 tw-font-Montserrat sm:tw-mt-0 tw-mt-[4.5rem]">
          <motion.h1
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 1, delay: 0.6 },
            }}
            className=" sm:tw-text-4xl "
          >
            Transform The Ordinary Into <br />
            <span
              className={` ${
                animate ? "logo is-animetion c-green" : "c-green"
              }`}
            >
              <span>E</span>
              <span>x</span>
              <span>t</span>
              <span>r</span>
              <span>a</span>
              <span>o</span>
              <span>r</span>
              <span>d</span>
              <span>i</span>
              <span>n</span>
              <span>a</span>
              <span>r</span>
              <span>y</span>
            </span>
            <div></div>
            {/* extraordinary */}
          </motion.h1>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 1, delay: 0.9 },
            }}
            className="tw-pt-10 tw-text-justify "
            style={{ color: "#707070" }}
          >
            <p>
              Imagine stepping outside and being greeted by a landscape that
              transcends the ordinary. A space that reflects your unique style
              and elevates your property to new heights.{" "}
              <span className="tw-font-bold">At TARAaang Landscape,</span>
              we specialize in crafting extraordinary landscapes that not only
              enhance your curb appeal but also create a functional and inviting
              outdoor oasis. We begin by understanding your vision, lifestyle,
              and the unique characteristics of your property. Through
              collaborative discussions, we identify hidden potent
            </p>
          </motion.div>
        </div>
      </div>
      <div
        className="tw-bg-cover sm:tw-h-80 tw-h-[30rem] tw-flex tw-justify-center tw-items-center tw-mt-[7%]"
        style={{ backgroundImage: `url(${leafBackground})` }}
      >
        <ScrollTrigger
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(false)}
        >
          <div className="sm:tw-grid tw-flex tw-flex-wrap tw-mx-[1%] tw-justify-center sm:tw-grid-cols-5 sm:tw-gap-20 tw-gap-[2.5rem] tw-text-center tw-font-Montserrat">
            <div className="about-cards tw-flex tw-flex-col sm:tw-min-w-[200px] tw-min-w-[145px] sm:tw-min-h-[150px] tw-justify-center">
              <h1 className=" tw-font-[600]">
                {counterOn && (
                  <CountUp start={0} end={813} duration={2} delay={0} />
                )}
                +
              </h1>
              <text>Projects</text>
            </div>
            <div className="about-cards tw-flex tw-flex-col sm:tw-min-w-[200px] tw-min-w-[145px] sm:tw-min-h-[150px] tw-justify-center">
              <h1 className=" tw-font-[600]">
                {counterOn && (
                  <CountUp start={0} end={4215} duration={2} delay={0} />
                )}
                +
              </h1>
              <text>Clients</text>
            </div>
            <div className="about-cards tw-flex tw-flex-col sm:tw-min-w-[200px] tw-min-w-[145px] sm:tw-min-h-[150px] tw-justify-center">
              <h1 className=" tw-font-[600]">
                {counterOn && (
                  <CountUp start={0} end={13} duration={2} delay={0} />
                )}
                +
              </h1>
              <text>Experience</text>
            </div>
            <div className="about-cards tw-flex tw-flex-col sm:tw-min-w-[200px] tw-min-w-[145px] sm:tw-min-h-[150px] tw-justify-center">
              <h1 className=" tw-font-[600]">
                {counterOn && (
                  <CountUp start={0} end={96} duration={2} delay={0} />
                )}
                +
              </h1>
              <text>Awards</text>
            </div>
            <div className="about-cards tw-flex tw-flex-col sm:tw-min-w-[200px] tw-min-w-[145px] sm:tw-min-h-[150px] tw-justify-center">
              <h1 className=" tw-font-[600]">
                {counterOn && (
                  <CountUp start={0} end={300} duration={2} delay={0} />
                )}
                +
              </h1>
              <text>Employees</text>
            </div>
          </div>
        </ScrollTrigger>
      </div>
      <div className="sm:tw-mt-[10%]">
        <motion.h1
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          style={{ fontWeight: "600" }}
          className="mt-5  tw-mb-10 text-center font-Montserrat sm:tw-text-[36px] tw-text-[24px]"
        >
          Our{" "}
          {
            <span className="c-green">
              <span className={` ${animate ? "logo is-animetion" : ""}`}>
                <span>W</span>
                <span>o</span>
                <span>r</span>
                <span>k</span>
              </span>{" "}
            </span>
          }
          Power
        </motion.h1>
        <div className="tw-flex tw-justify-evenly sm:tw-mx-0 tw-mx-3 sm:tw-mt-20 tw-flex-col-reverse sm:tw-flex-row">
          <motion.div
            className="sm:tw-w-2/4 tw-font-Montserrat sm:tw-p-20 sm:tw-pt-0 tw-ps-1"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
          >
            <h1 className=" sm:tw-text-4xl sm:tw-p-1">
              <div>
                <span className="c-green">
                  <span className={` ${animate ? "logo is-animetion" : ""}`}>
                    <span>T</span>
                    <span>e</span>
                    <span>a</span>
                    <span>m</span>
                    <span>&nbsp;</span>
                    <span>W</span>
                    <span>o</span>
                    <span>r</span>
                    <span>k</span>{" "}
                  </span>
                </span>
                Is The Tool Of Create New Thing
              </div>
            </h1>
            <div
              className="tw-pt-10 tw-px- tw-text-justify"
              style={{ color: "#707070" }}
            >
              <p>
                In the world of landscaping, where creativity and collaboration
                breathe life into outdoor spaces, teamwork is the fertile ground
                where fresh ideas blossom and innovative designs take root.
                <p className="tw-pt-10">
                  A team brings together diverse perspectives and skillsets.
                  Designers, horticulturists, and technicians collaborate,
                  merging their knowledge to create unique and functional
                  landscapes that cater to specific client needs and
                  environmental considerations. It's the very essence of a
                  successful landscaping company. By fostering a collaborative
                  environment, you empower your team to orchestrate a symphony
                  of landscapes, where each project becomes a unique and
                  harmonious expression of creativity and expertise.
                </p>
              </p>
            </div>
          </motion.div>
          <motion.div
            className=" sm:tw-block tw-hidden"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
          >
            <div className="d-sm-flex">
              <img src={frame5} alt="aboutus"></img>
              <div>
                <img className="tw-px-5" src={frame4} alt="about"></img>
              </div>
            </div>
            <div>
              <img
                className="tw-z-10 tw-relative tw-bottom-28 tw-left-28"
                src={frame6}
                alt="about"
              ></img>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}

export default About;
