import { useState } from "react";
import "./App.css";
import Footer from "./Components/Footer";
import Navbar from "./Navbar";
import Clients from "./Routes/Clients";
import About from "./Routes/About";
import Home from "./Routes/Home";
import { useEffect } from "react";
import AnimateContext from "./Components/useContext";
import Teams from "./Routes/Teams";
import Contact from "./Routes/Contact";
import Products from "./Routes/Prodcuts/Products";
import OurServices from "./Routes/OurServices";
import { Routes, Route, useLocation } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import PlantSelling from "./Routes/Prodcuts/PlantSelling";
import Maintenance from "./Routes/Prodcuts/Maintenance";
import OurWorks from "./Components/OurWorks";
import LandScaping from "./Routes/Prodcuts/LandScaping";
import Certificates from "./Routes/Certificates";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import Chatbot from "./Components/Chatbot";
import { CartProvider } from "./Components/ContextReducer";
ReactGA.initialize('G-S78KZVCW0F');
function App() {
  const location = useLocation();
  const [animate, setAnimate] = useState();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnimate((prevAnimate) => !prevAnimate);
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>
          TARAaang Landscape - Your best & only destination for Landscaping and
          Gardening
        </title>
        <meta
          name="description"
          content="Taraaang Landscape has been a trusted supplier of premium plants, seeds, and pots for the past 13 years. Our commitment to quality and client satisfaction sets us apart, as we deliver a wide variety of plants and seeds across India and to select international destinations. Browse our selection of indoor and outdoor plants, gardening tools, and more, and discover why Taraaang Landscape is the preferred choice for gardening enthusiasts."
        />
        <meta
          name="keywords"
          content="tarang landscape,taraaanglandscape,taraaang landscape, taraaang landscape landscaping, gardening, plants, seeds, pots, India, international, indoor plants, outdoor plants, gardening tools, gardening enthusiasts"
        />
        <meta
          property="og:title"
          content="TARAaang Landscape - Your best & only destination for Landscaping and Gardening"
        />
        <meta
          property="og:description"
          content="Taraaang Landscape has been a trusted supplier of premium plants, seeds, and pots for the past 13 years. Our commitment to quality and client satisfaction sets us apart, as we deliver a wide variety of plants and seeds across India and to select international destinations. Browse our selection of indoor and outdoor plants, gardening tools, and more, and discover why Taraaang Landscape is the preferred choice for gardening enthusiasts."
        />
        <meta
          property="og:image"
          content="https://lh3.googleusercontent.com/p/AF1QipOjldGgplmgQ9fyTGSD_sg_9Zw3Jy6M_ARfHNKJ=s680-w680-h510"
        />
        <meta property="og:url" content="https://taraaanglandscape.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <CartProvider>

        <AnimatePresence>
          <ChakraProvider>
            <AnimateContext.Provider value={animate}>
              <div>
                <Routes location={location} key={location.pathname}>

                  <Route path="/" element={<Home />} />

                  <Route path="/products" element={<Products />} />
                  <Route path="/ourServices" element={<OurServices />} />

                  <Route path="/clients" element={<Clients />} />
                  <Route path="/teams" element={<Teams />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/plantSelling" element={<PlantSelling />} />
                  <Route path="/landscaping" element={<LandScaping />} />
                  <Route path="/maintenance" element={<Maintenance />} />
                  <Route path="/ourWorks" element={<OurWorks />} />
                  <Route path="/certificates" element={<Certificates />} />
                </Routes>
              </div>
              <Footer></Footer>
              <Chatbot />
            </AnimateContext.Provider>
          </ChakraProvider>
        </AnimatePresence>
      </CartProvider>
    </>
  );
}

export default App;
