const SummerFlowers = [
  {
    id: 0,
    name: "Hibiscus",
    url: require("../../Images/PlantSelling/Summer Flowers/Hibiscus/hibiscus_rosasinensis.webp"),
    images: [
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/hibiscus_rosasinensis.webp"),
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/c84762391b6278096cd37d9586e8a3de.jpg"),  
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/hibiscus-orange.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/hibiscus-yellow-seed2plant-2.webp"),
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/Hibiscus-Blue-3.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/nurserylive-g-hibiscus-gudhal-flower-orange-color-plant-705397.webp"),
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/h2vuurdcbb2a1.jpg"),
    ],
    he1: "Hibiscus",
    he2: "",
    Description:
      "Hibiscus is a flowering plant prized for its vibrant and showy blooms, adding a touch of tropical elegance to gardens and landscapes.",
    Fact: "Hibiscus is a popular flowering plant known for its vibrant blooms and ornamental foliage, making it a favorite choice for adding color and beauty to gardens, landscapes, and indoor spaces. With its wide range of varieties and adaptable growing conditions, hibiscus is versatile in landscaping, used as hedge plants, specimen shrubs, or container plants, and valued for its ability to attract pollinators and create stunning focal points in outdoor settings.",
  },
  {
    id: 1,
    name: "Rose",
    url: require("../../Images/PlantSelling/Summer Flowers/Rose/rose-plant-1642136318-6157288.jpeg"),
    images: [
      require("../../Images/PlantSelling/Summer Flowers/Rose/rose-plant-1642136318-6157288.jpeg"),
      require("../../Images/PlantSelling/Summer Flowers/Rose/abe0c2cdc2ba87a572195952062e87fc.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Rose/nurserylive-g-plants-rose-dark-pink-plant-in-grower-round-black-pot-922015_600x600.webp"),
      require("../../Images/PlantSelling/Summer Flowers/Rose/nurserylive-g-rose-baby-pink-plant.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Rose/nurseryserve-Damascus-RoseScented-Rose-Any-Color.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Rose/rose-colors-4175293-hero-a79624df1730467abb9396ff31db8caa.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Rose/roses-various-colours-plants.jpg"),
    ],
    he1: "Rose",
    he2: "",
    Description:
      "Roses are iconic flowering plants renowned for their beauty, fragrance, and wide variety of colors, making them cherished additions to gardens, bouquets, and floral arrangements.",
    Fact: "Roses are essential for adding elegance and romance to gardens and landscapes, serving as focal points, borders, or hedges, and providing stunning cut flowers for bouquets and floral displays. With their versatile growth habits and wide range of cultivars, roses are prized for their beauty, fragrance, and longevity, making them popular choices for both ornamental and practical purposes in landscaping and horticulture.",
  },
  {
    id: 2,
    name: "MariGold",
    url: require("../../Images/PlantSelling/Summer Flowers/Marigold/Orange-Marigold-scaled-e1589381914614.webp"),
    images: [
      require("../../Images/PlantSelling/Summer Flowers/Marigold/Orange-Marigold-scaled-e1589381914614.webp"),
      require("../../Images/PlantSelling/Summer Flowers/Marigold/African-Marigolds-Tagetes-erecta-ClX7nBkn4249Jtr_56xLiE-8a86e59a8cbb4b45812f4b8385556173.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Marigold/Marigold-genotypes-varieties-with-diverse-colored-petals-used-in-the-study.png"),
      require("../../Images/PlantSelling/Summer Flowers/Marigold/keep-marigolds-blooming.jpg"),
    ],
    he1: "MariGold",
    he2: "",
    Description:
      "Marigold is a cheerful and vibrant flowering plant known for its bright yellow, orange, or red blooms, prized for its pest-repelling properties and used for adding color to gardens, borders, and floral arrangements.",
    Fact: "Marigolds are essential for gardeners as they not only add vibrant color to landscapes but also serve as natural pest repellents, deterring harmful insects and protecting nearby plants. Their easy cultivation, long flowering period, and adaptability to various growing conditions make them versatile choices for borders, beds, containers, and companion planting in vegetable gardens, promoting both aesthetic appeal and garden health.",
  },
  {
    id: 3,
    name: "Sunflower",
    url: require("../../Images/PlantSelling/Summer Flowers/Sunflower/ring-of-fire-sunflower-bicolor-sunflower-all-america-selections_12080.jpg"),
    images: [
      require("../../Images/PlantSelling/Summer Flowers/Sunflower/ring-of-fire-sunflower-bicolor-sunflower-all-america-selections_12080.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Sunflower/annual-yes-yes-sunflower-006-1-pot-sunlight-original-imagkhzfmggv6dct.webp"),
      require("../../Images/PlantSelling/Summer Flowers/Sunflower/bertsunflower-bertsunflower-image-24350-birt-2.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Sunflower/sunflower-yellow-field-1678722527946.webp"),
    ],
    he1: "Sunflower",
    he2: "",
    Description:
      "Sunflower is a striking and iconic flowering plant characterized by its large, golden-yellow blooms and tall, sturdy stems, valued for its beauty, edible seeds, and ecological benefits in gardens, landscapes, and agricultural fields.",
    Fact: "Sunflowers are essential for adding a vibrant splash of color to gardens and landscapes while providing a valuable food source for birds and pollinators with their nutritious seeds. Their tall stature and robust growth make them ideal for creating visual interest, windbreaks, and wildlife habitats, enhancing biodiversity and ecological balance in outdoor environments.",
  },
  {
    id: 4,
    name: "Aparajita",
    url: require("../../Images/PlantSelling/Summer Flowers/Aparajita/51YJtSEcNUL._AC_UF1000,1000_QL80_.jpg"),
    images: [
      require("../../Images/PlantSelling/Summer Flowers/Aparajita/51YJtSEcNUL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Aparajita/81WxhiMpbAL.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Aparajita/aparajite-white.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Aparajita/maxresdefault.jpg"),
    ],
    he1: "Aparajita",
    he2: "",
    Description:
      "Aparajita, also known as Butterfly Pea, is a beautiful flowering vine prized for its striking blue or purple flowers and medicinal properties, commonly used in traditional medicine and culinary applications.",
    Fact: "Aparajita is essential for gardens and landscapes as it adds a splash of vibrant color and attracts pollinators like butterflies and bees, enhancing biodiversity and ecosystem health. Additionally, its flowers are valued for their medicinal properties and culinary uses in teas, beverages, and herbal remedies, making it a versatile and beneficial plant for both ornamental and practical purposes.",
  },
  {
    id: 5,
    name: "Zinnia",
    url: require("../../Images/PlantSelling/Summer Flowers/Zinnia/Zinnia_KOT9209-Desktop-Hero-1920x978.jpg"),
    images: [
      require("../../Images/PlantSelling/Summer Flowers/Zinnia/Zinnia_KOT9209-Desktop-Hero-1920x978.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Zinnia/612xwjhhyNL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Zinnia/zinnia.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Zinnia/zinnia-flower-plant.jpeg"),
    ],
    he1: "Zinnia",
    he2: "",
    Description:
      "Zinnia is a colorful and easy-to-grow annual flowering plant prized for its vibrant blooms in a wide range of colors, making it a popular choice for adding cheerful accents to gardens, borders, and containers.",
    Fact: "Zinnias are essential for gardeners seeking vibrant and long-lasting blooms, providing a burst of color and attracting pollinators like butterflies and bees to gardens and landscapes. With their wide range of colors, sizes, and forms, zinnias are versatile plants used for borders, beds, cut flowers, and mass plantings, adding beauty and charm to outdoor spaces throughout the growing season.",
  },
];
const WinterFlowers = [
  {
    id: 115,
    name: "Aster",
    url: require("../../Images/PlantSelling/Winter Flower/Aster/aster-smooth-blue.jpg"),
    images: [
      require("../../Images/PlantSelling/Winter Flower/Aster/aster-smooth-blue.jpg"),
      require("../../Images/PlantSelling/Winter Flower/Aster/nurserylive-category-aster-plants.jpg"), 
      require("../../Images/PlantSelling/Winter Flower/Aster/maxresdefault.jpg"),
    ],
    he1: "Aster",
    he2: "",
    Description:
      "Aster is a delightful flowering plant known for its daisy-like blooms in various colors, ranging from vibrant purples and blues to soft pinks and whites, adding charm and elegance to gardens and floral arrangements.",
    Fact: "Asters are essential for gardeners seeking late-season blooms, providing a burst of color and attracting pollinators like bees and butterflies to gardens and landscapes. With their diverse range of colors and compact growth habits, asters are versatile plants used for borders, beds, containers, and cut flowers, extending the beauty of the garden well into the fall season.",
  },
  {
    id: 116,
    name: "Cosmos",
    url: require("../../Images/PlantSelling/Winter Flower/Cosmos/103257632-the-beautiful-flowerpot-on-balcony-with-cosmos-flowers-and-other-balcony-flowers.jpg"),
    images: [
      require("../../Images/PlantSelling/Winter Flower/Cosmos/103257632-the-beautiful-flowerpot-on-balcony-with-cosmos-flowers-and-other-balcony-flowers.jpg"),
      require("../../Images/PlantSelling/Winter Flower/Cosmos/516wOsMJIVL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Winter Flower/Cosmos/61BGiYKShNL._AC_UF1000,1000_QL80_.jpg"),
    ],
    he1: "Cosmos",
    he2: "",
    Description:
      "Cosmos is a graceful and airy flowering plant admired for its delicate, daisy-like blooms in various hues of pink, white, and purple, adding a touch of whimsy and charm to gardens and landscapes.",
    Fact: "Cosmos is essential for gardeners seeking easy-to-grow and long-lasting blooms, providing continuous color and attracting beneficial insects like bees and butterflies to gardens and landscapes. With its wide range of colors, including shades of pink, white, purple, and red, cosmos offers versatility in garden design, allowing for vibrant borders, cheerful mass plantings, and charming cut flower arrangements throughout the growing season.",
  },
  {
    id: 117,
    name: "Dahlia",
    url: require("../../Images/PlantSelling/Winter Flower/Dahlia/dahlia-plants-500x500.webp"),
    images: [
      require("../../Images/PlantSelling/Winter Flower/Dahlia/dahlia-plants-500x500.webp"),
      require("../../Images/PlantSelling/Winter Flower/Dahlia/nurserylive-dahlia-plants-category-image-803619.jpg"),
      require("../../Images/PlantSelling/Winter Flower/Dahlia/yvmaklvkudy61.jpg"),
    ],
    he1: "Dahlia",
    he2: "",
    Description:
      "Dahlia is a stunning and diverse flowering plant prized for its intricate and colorful blooms, ranging from bold and vibrant to soft and pastel hues, adding drama and elegance to gardens, borders, and floral displays.",
    Fact: "Dahlias are essential for gardeners seeking a wide array of blooms, from large, showy flowers to smaller, delicate forms, offering endless possibilities for garden design and floral arrangements. With their extensive range of colors, including vibrant reds, oranges, yellows, pinks, and purples, dahlias provide versatility in landscaping, allowing for striking focal points, colorful borders, and captivating cut flower displays that brighten outdoor spaces and indoor settings alike.",
  },
  {
    id: 118,
    name: "Baby's Breath",
    url: require("../../Images/PlantSelling/Winter Flower/Baby Birth/81MRnIiRDXL._AC_UF894,1000_QL80_.jpg"),
    images: [
      require("../../Images/PlantSelling/Winter Flower/Baby Birth/81MRnIiRDXL._AC_UF894,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Winter Flower/Baby Birth/IMG_9318.jpg"),
      require("../../Images/PlantSelling/Winter Flower/Baby Birth/white-flowers-4207289_960_720gypsophila.webp"),
    ],
    he1: "Baby's ",
    he2: "Breath",
    Description:
      "Baby's Breath is a delicate and airy flowering plant renowned for its tiny, clustered blooms and fine, lacy foliage, often used as a filler in floral arrangements to add texture and softness.",
    Fact: "Baby's Breath is essential for florists seeking to enhance floral designs with its dainty and versatile blooms, providing a delicate and romantic touch to bouquets, centerpieces, and wedding decorations. With its classic white color, Baby's Breath complements a wide range of flower varieties and color schemes, while newer cultivars offer shades of pink and lavender, expanding its decorative possibilities in floral arrangements and garden landscapes.",
  },
  {
    id: 119,
    name: "Celosia",
    url: require("../../Images/PlantSelling/Winter Flower/Celosia/80-238-celo-jbmr-zixa-original-imagd7yhfys5xeef.webp"),
    images: [
      require("../../Images/PlantSelling/Winter Flower/Celosia/80-238-celo-jbmr-zixa-original-imagd7yhfys5xeef.webp"),
      require("../../Images/PlantSelling/Winter Flower/Celosia/92-celosia-seeds-ishika-original-imag5zhkrfdnzzrf.webp"),
      require("../../Images/PlantSelling/Winter Flower/Celosia/Celosiamix_900x622.jpg"),
    ],
    he1: "Celosia",
    he2: "",
    Description:
      "Celosia is a stunning flowering plant prized for its vibrant, flame-like blooms and unique, velvety texture, adding bold color and visual interest to gardens, borders, and floral displays.",
    Fact: "Celosia is essential for gardeners seeking dramatic and long-lasting blooms, providing striking accents in garden beds, containers, and cut flower arrangements. With its diverse range of colors, including vivid reds, oranges, yellows, and purples, Celosia offers versatility in garden design, allowing for eye-catching borders, vibrant mass plantings, and captivating floral displays that elevate outdoor spaces and indoor settings alike.",
  },
  {
    id: 120,
    name: "Gaillardia",
    url: require("../../Images/PlantSelling/Winter Flower/Gaillardia/Add-Gaillardia-to-the-Xyriscaped-Garden.jpg"),
    images: [
      require("../../Images/PlantSelling/Winter Flower/Gaillardia/Add-Gaillardia-to-the-Xyriscaped-Garden.jpg"),
      require("../../Images/PlantSelling/Winter Flower/Gaillardia/GaillardiaArizonaSunFlowerSeeds3.jpg"),
      require("../../Images/PlantSelling/Winter Flower/Gaillardia/gaillardia-blanket-flower.jpg"),
    ],
    he1: "Gaillardia",
    he2: "",
    Description:
      "Gaillardia is a charming and resilient flowering plant known for its striking, daisy-like blooms in warm hues of red, orange, and yellow, adding vibrant color and a touch of wild beauty to gardens, borders, and native landscapes.",
    Fact: "Celosia is essential for gardeners seeking vibrant and long-lasting blooms, providing striking accents in garden beds, containers, and cut flower arrangements. With its unique texture and bold colors, Celosia adds visual interest and drama to landscapes, creating stunning focal points and attracting pollinators like butterflies and bees.",
  },
];
const IndoorPlants = [
  {
    id: 130,
    name: "Mother-in-law's tongue",
    url: require("../../Images/PlantSelling/Indoor Plants/Mothe In Law Tongue/pr-tn-indoor-mother-in-laws-tongue.webp"),
    images: [
      require("../../Images/PlantSelling/Indoor Plants/Mothe In Law Tongue/pr-tn-indoor-mother-in-laws-tongue.webp"),
      require("../../Images/PlantSelling/Indoor Plants/Mothe In Law Tongue/easyplants_grande.webp"),  
      require("../../Images/PlantSelling/Indoor Plants/Mothe In Law Tongue/snakeplants.jpg"),
    ],
    he1: "Mother-in-law's",
    he2: "tongue",
    Description:
      "Mother-in-law's tongue, also known as snake plant, is a hardy and low-maintenance houseplant characterized by its tall, upright leaves with striking patterns, making it a popular choice for adding greenery and texture to indoor spaces while requiring minimal care.",
    Fact: "Mother-in-law's tongue is essential for indoor gardening enthusiasts seeking a resilient and easy-to-care-for houseplant, as it thrives in low-light conditions and tolerates neglect, making it ideal for busy individuals or those with limited gardening experience. With its architectural form and air-purifying qualities, Mother-in-law's tongue adds a touch of greenery and style to homes, offices, and indoor environments, enhancing air quality and promoting a sense of well-being.",
  },
  {
    id: 131,
    name: "Pothos Marble Queen",
    url: require("../../Images/PlantSelling/Indoor Plants/Pothos Marble Queen/1651104975256.webp"),
    images: [
      require("../../Images/PlantSelling/Indoor Plants/Pothos Marble Queen/1651104975256.webp"),
      require("../../Images/PlantSelling/Indoor Plants/Pothos Marble Queen/VariegatedPothos.webp"),
      require("../../Images/PlantSelling/Indoor Plants/Pothos Marble Queen/marble-queen-pothos-plant-profile-5206131-hero-19b8dca0cf9e407b87a6f93e50054ed3.jpg"),
    ],
    he1: "Pothos Marble",
    he2: "Queen",
    Description:
      "Pothos Marble Queen is a popular cultivar of the pothos plant, featuring marbled green and white leaves that cascade elegantly from hanging baskets or climb trellises, adding beauty and texture to indoor spaces with minimal care requirements.",
    Fact: "Pothos Marble Queen is essential for indoor gardeners seeking a low-maintenance and visually striking houseplant, as it thrives in a variety of lighting conditions and requires minimal watering. With its trailing growth habit and variegated foliage, Pothos Marble Queen is ideal for hanging baskets, shelves, or as a climbing vine, adding beauty and greenery to homes, offices, and indoor environments while purifying the air.",
  },
  {
    id: 132,
    name: "Dracaena Marginata",
    url: require("../../Images/PlantSelling/Indoor Plants/Dracaena Marginata/a.jpg"),
    images: [
      require("../../Images/PlantSelling/Indoor Plants/Dracaena Marginata/a.jpg"),
      require("../../Images/PlantSelling/Indoor Plants/Dracaena Marginata/dracaena-houseplant-02-88f2640d588243c9822a9ecf5fda218d.jpg"),
      require("../../Images/PlantSelling/Indoor Plants/Dracaena Marginata/online-plant-bazar-red-dracaena-plant-pack-of-1-product-images-orvop4qp5g7-p598423528-4-202302160258.jpg"),
    ],
    he1: "Dracaena",
    he2: "Marginata",
    Description:
      "Dracaena Marginata, also known as the Madagascar Dragon Tree, is a striking indoor plant prized for its slender, arching stems and distinctive, sword-shaped leaves with red or pink edges, adding a touch of elegance and tropical flair to interior spaces with its low-maintenance requirements.",
    Fact: "Dracaena Marginata is essential for indoor gardeners seeking a stylish and easy-to-care-for houseplant, as it tolerates low light and infrequent watering, making it ideal for homes, offices, and indoor environments with limited natural light. With its striking foliage and air-purifying qualities, Dracaena Marginata adds beauty and a touch of tropical ambiance to interior spaces, enhancing indoor air quality and promoting a sense of tranquility and well-being.",
  },
  {
    id: 133,
    name: "Zamioculcas",
    url: require("../../Images/PlantSelling/Indoor Plants/Zamioculas/1684789715-the_sill-variant-white_gloss-zz_plant.jpg"),
    images: [
      require("../../Images/PlantSelling/Indoor Plants/Zamioculas/1684789715-the_sill-variant-white_gloss-zz_plant.jpg"),
      require("../../Images/PlantSelling/Indoor Plants/Zamioculas/7_39a2b43c-02e2-4de4-9598-c3a8e7b278ff_1200x1200.webp"),
      require("../../Images/PlantSelling/Indoor Plants/Zamioculas/zz-zanzibar-gem-plant-profile-4796783-02-e80e5506091f4dcfb226c5a21718deb6.jpg"),
    ],
    he1: "Zamioculcas",
    he2: "",
    Description:
      "Zamioculcas, commonly known as the ZZ plant, is a resilient and low-maintenance houseplant prized for its glossy, dark green foliage and upright growth habit, making it a popular choice for adding greenery and elegance to indoor spaces with minimal care requirements.",
    Fact: "Zamioculcas is essential for indoor gardening enthusiasts seeking a durable and visually appealing houseplant, as it thrives in low-light conditions and requires minimal watering and maintenance. With its glossy foliage and tolerance to neglect, Zamioculcas is ideal for homes, offices, and indoor environments where other plants may struggle, adding beauty and greenery to interior spaces with ease.",
  },
  {
    id: 134,
    name: "Lucky Bamboo",
    url: require("../../Images/PlantSelling/Indoor Plants/Lucky Bamboo/2_layer.jpg"),
    images: [
      require("../../Images/PlantSelling/Indoor Plants/Lucky Bamboo/2_layer.jpg"),
      require("../../Images/PlantSelling/Indoor Plants/Lucky Bamboo/Vastu-tips-for-keeping-bamboo-plant-at-home-FB-1200x700-compressed.jpg"),
      require("../../Images/PlantSelling/Indoor Plants/Lucky Bamboo/lucky-bamboo-plant-3-layer-gift-hamper-32173381320836.webp"),
    ],
    he1: "Lucky",
    he2: "Bamboo",
    Description:
      "Lucky Bamboo, also known as Dracaena sanderiana, is a popular indoor plant revered for its elegant, upright stems and symbolic significance in Feng Shui, believed to bring luck, prosperity, and positive energy to homes and workplaces.",
    Fact: "Lucky Bamboo is essential for indoor gardeners seeking a stylish and auspicious houseplant, as it thrives in low-light conditions and requires minimal care, making it ideal for homes, offices, and indoor environments with limited natural light. With its graceful stems and symbolic significance, Lucky Bamboo adds beauty and positive energy to interior spaces, serving as a decorative accent and bringing a sense of tranquility and prosperity to any setting.",
  },
  {
    id: 135,
    name: "Money Tree",
    url: require("../../Images/PlantSelling/Indoor Plants/Money Tree/The-Spruce-RECIRC-2fa016ab938c4eb9aa952cba2fdb955f.jpg"),
    images: [
      require("../../Images/PlantSelling/Indoor Plants/Money Tree/The-Spruce-RECIRC-2fa016ab938c4eb9aa952cba2fdb955f.jpg"),
      require("../../Images/PlantSelling/Indoor Plants/Money Tree/TheSpruce1-0cdbe5809c914e25b1d24b0122f39c11.jpg"),
      require("../../Images/PlantSelling/Indoor Plants/Money Tree/money-tree-1646759025.jpg"),
    ],
    he1: "Money",
    he2: "Tree",
    Description:
      "Money Tree, scientifically known as Pachira aquatica, is a distinctive indoor plant characterized by its braided trunk and glossy, palmate leaves, believed to bring prosperity, good fortune, and positive energy to homes and workplaces according to Feng Shui principles.",
    Fact: "Money Tree is essential for indoor gardeners seeking a unique and auspicious houseplant, as it adds visual interest and symbolic significance to interior spaces while requiring minimal care and maintenance. With its braided trunk and lush foliage, Money Tree is ideal for homes, offices, and indoor environments where it serves as a decorative accent, bringing a touch of prosperity and positive energy to any setting according to Feng Shui beliefs.",
  },
];
const OutdoorPlants = [
  {
    id: 146,
    name: "Rose",
    url: require("../../Images/PlantSelling/Summer Flowers/Rose/rose-plant-1642136318-6157288.jpeg"),
    images: [
      require("../../Images/PlantSelling/Summer Flowers/Rose/rose-plant-1642136318-6157288.jpeg"),
      require("../../Images/PlantSelling/Summer Flowers/Rose/abe0c2cdc2ba87a572195952062e87fc.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Rose/nurserylive-g-plants-rose-dark-pink-plant-in-grower-round-black-pot-922015_600x600.webp"),
      require("../../Images/PlantSelling/Summer Flowers/Rose/nurserylive-g-rose-baby-pink-plant.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Rose/nurseryserve-Damascus-RoseScented-Rose-Any-Color.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Rose/rose-colors-4175293-hero-a79624df1730467abb9396ff31db8caa.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Rose/roses-various-colours-plants.jpg"),
    ],
    he1: "Rose",
    he2: "",
    Description:
      "Roses are iconic flowering plants renowned for their beauty, fragrance, and wide variety of colors, making them cherished additions to gardens, bouquets, and floral arrangements.",
    Fact: "Roses are essential for adding elegance and romance to gardens and landscapes, serving as focal points, borders, or hedges, and providing stunning cut flowers for bouquets and floral displays. With their versatile growth habits and wide range of cultivars, roses are prized for their beauty, fragrance, and longevity, making them popular choices for both ornamental and practical purposes in landscaping and horticulture.",
  },
  {
    id: 147,
    name: "Adenium",
    url: require("../../Images/PlantSelling/Outdoor Plants/adenium/1.webp"),
    images: [
      require("../../Images/PlantSelling/Outdoor Plants/adenium/1.webp"),
      require("../../Images/PlantSelling/Outdoor Plants/adenium/2.webp"),
      require("../../Images/PlantSelling/Outdoor Plants/adenium/3.webp"),
    ],
    he1: "Shrub",
    he2: "Border",
    Description:
      "Adenium, also known as desert rose, is a stunning succulent known for its vibrant flowers and thick, fleshy stems, making it a striking addition to any arid landscape or indoor garden.",
    Fact: "Adenium is prized for its drought tolerance and low maintenance, making it ideal for xeriscaping and water-wise gardening. Its ornamental flowers and unique growth habit also make it a popular choice for adding color and interest to gardens, balconies, and indoor spaces.",
  },
  {
    id: 148,
    name: "Hostas",
    url: require("../../Images/PlantSelling/Outdoor Plants/hosta/2.jpg"),
    images: [
      require("../../Images/PlantSelling/Outdoor Plants/hosta/2.jpg"),
      require("../../Images/PlantSelling/Outdoor Plants/hosta/1.jpg"),
      require("../../Images/PlantSelling/Outdoor Plants/hosta/3.webp"),
      
    ],
    he1: "Hostas",
    he2: "",
    Description:
      "Hostas are versatile perennials prized for their lush foliage and diverse array of leaf shapes, sizes, and colors, making them excellent choices for shady gardens and landscaping schemes.",
    Fact: "Hostas thrive in shady areas where many other plants struggle, making them essential for adding interest and beauty to gardens with limited sunlight. Their dense foliage also helps suppress weeds, making them valuable components of landscaping designs focused on low-maintenance and weed control.",
  },
  {
    id: 149,
    name: "Petunia",
    url: require("../../Images/PlantSelling/Outdoor Plants/petunia/1.webp"),
    images: [
      require("../../Images/PlantSelling/Outdoor Plants/petunia/1.webp"),
      require("../../Images/PlantSelling/Outdoor Plants/petunia/2.jpg"),
      require("../../Images/PlantSelling/Outdoor Plants/petunia/3.jpg"),
    ],
    he1: "Petunia",
    he2: "",
    Description:
      "Petunias are popular annual flowering plants known for their vibrant blooms and versatility in garden beds, containers, and hanging baskets, adding a splash of color to any landscape or outdoor space.",
    Fact: "Petunias are prized for their prolific flowering habits, making them perfect for adding seasonal color to garden beds, borders, and containers. With their ability to thrive in various soil conditions and sun exposures, they are versatile plants suitable for both beginner and experienced gardeners alike. ",
  },
  {
    id: 150,
    name: "Peace Lily",
    url: require("../../Images/PlantSelling/Outdoor Plants/peace lily/1.jpg"),
    images: [
      require("../../Images/PlantSelling/Outdoor Plants/peace lily/1.jpg"),
      require("../../Images/PlantSelling/Outdoor Plants/peace lily/2.jpg"),
      require("../../Images/PlantSelling/Outdoor Plants/peace lily/3.jpg"),
    ],
    he1: "Peace",
    he2: "Lily",
    Description:
      "Border hedges create beautiful lines and curves in the home landscape. They define an area and add an upscale look. Border hedges can be sculpted to your liking. They can be rounded or angled, whatever fits your personality and landscape best.",
    Fact: "With the help of plants, live hedges can be formed and used as a fence or a green wall. It serves to screen a particular site or building or hiding of unwanted places. They help to partition the garden into several parts. It provides a natural background to a garden, like a frame to a picture.",
  },
  {
    id: 151,
    name: "Calathea",
    url: require("../../Images/PlantSelling/Outdoor Plants/calathea/1.jpg"),
    images: [
      require("../../Images/PlantSelling/Outdoor Plants/calathea/1.jpg"),
      require("../../Images/PlantSelling/Outdoor Plants/calathea/2.webp"),
      require("../../Images/PlantSelling/Outdoor Plants/calathea/3.jpg"),
    ],
    he1: "Calathea",
    he2: "",
    Description:
      "Calathea plants, renowned for their striking foliage patterns and vibrant colors, are popular choices for indoor decoration, bringing a touch of tropical elegance to any interior space.",
    Fact: "Calathea plants are favored for their air-purifying properties and ability to thrive in low-light conditions, making them ideal choices for offices, homes, and other indoor environments. Their unique foliage also adds visual interest and a calming atmosphere to interior décor, enhancing the ambiance of any room.",
  },
];
const MedicinalPlants = [
  {
    id: 161,
    name: "Holy Basil (Tulsi)",
    url: require("../../Images/PlantSelling/Medicinal Plants/Tulsi/1296x728_Holy_Basil.webp"),
    images: [
      require("../../Images/PlantSelling/Medicinal Plants/Tulsi/1296x728_Holy_Basil.webp"),
      require("../../Images/PlantSelling/Medicinal Plants/Tulsi/51bNWUg1+YL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Medicinal Plants/Tulsi/Tulsi.webp"),
    ],
    he1: "Holy",
    he2: "Basil",
    Description:
      "Holy Basil, also known as Tulsi, is a sacred and aromatic herb revered in Hindu culture for its medicinal properties and spiritual significance, commonly used in culinary dishes, herbal remedies, and religious rituals.",
    Fact: "Holy Basil, or Tulsi, is indispensable for herbalists and home gardeners seeking a versatile and healing herb, valued for its anti-inflammatory, antimicrobial, and adaptogenic properties. With its aromatic leaves and wide-ranging health benefits, Holy Basil is used in teas, tinctures, and poultices to support immune health, reduce stress, and promote overall wellness.",
  },
  {
    id: 162,
    name: "Vasaka Plant",
    url: require("../../Images/PlantSelling/Medicinal Plants/Vasaka/2_d52f2d26-4318-4893-a305-f4eb3f6cf5e5_480x480.webp"),
    images: [
      require("../../Images/PlantSelling/Medicinal Plants/Vasaka/2_d52f2d26-4318-4893-a305-f4eb3f6cf5e5_480x480.webp"),
      require("../../Images/PlantSelling/Medicinal Plants/Vasaka/Vasaka-Basuti-Justicia-adhatoda-L.png"),
      require("../../Images/PlantSelling/Medicinal Plants/Vasaka/ayurvedic-medicine-for-cough-500x500.webp"),
    ],
    he1: "Vasaka",
    he2: "Plant",
    Description:
      "Vasaka, also known as Malabar nut or Adhatoda vasica, is a medicinal plant prized for its therapeutic properties in traditional Ayurvedic medicine, commonly used to treat respiratory ailments such as asthma, bronchitis, and coughs.",
    Fact: "Vasaka plant is essential for herbalists and practitioners of traditional medicine seeking a natural remedy for respiratory disorders, known for its bronchodilator and expectorant properties. With its potent medicinal compounds, Vasaka is utilized in various forms, including teas, extracts, and syrups, to alleviate symptoms and promote respiratory health.",
  },
  {
    id: 163,
    name: "Neem Tree",
    url: require("../../Images/PlantSelling/Medicinal Plants/Neem/51AWjGozihL._AC_UF1000,1000_QL80_.jpg"),
    images: [
      require("../../Images/PlantSelling/Medicinal Plants/Neem/51AWjGozihL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Medicinal Plants/Neem/61ZurlJBidL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Medicinal Plants/Neem/nurserylive-g-neem-tree-azadirachta-indica-plant.jpg"),
    ],
    he1: "Neem",
    he2: "Tree",
    Description:
      "Discover the versatile benefits of the Neem Tree with our curated selection of neem-based products, offering natural solutions for skincare, haircare, and pest control, harnessing the power of this ancient botanical treasure.",
    Fact: "Explore the holistic properties of the Neem Tree, revered for its antibacterial, antifungal, and anti-inflammatory properties, making it an essential ingredient in herbal remedies and organic products. From soothing skin irritations to deterring pests in gardens, the Neem Tree offers versatile solutions for health, wellness, and sustainable living.",
  },
  {
    id: 164,
    name: "Pudina",
    url: require("../../Images/PlantSelling/Medicinal Plants/Pudina/6972idea99Mint-1.jpg"),
    images: [
      require("../../Images/PlantSelling/Medicinal Plants/Pudina/6972idea99Mint-1.jpg"),
      require("../../Images/PlantSelling/Medicinal Plants/Pudina/no-perennial-yes-pudina-plant-1-risobnursery-original-imagcfynghbhgazq.webp"),
      require("../../Images/PlantSelling/Medicinal Plants/Pudina/nurserylive-plants-common-mint-plant-pudina-plant-16968793620620.jpg"),
    ],
    he1: "Pudina",
    he2: "",
    Description:
      "Pudina, or mint, is a aromatic herb prized for its cooling and refreshing flavor, commonly used in culinary dishes, beverages, and herbal remedies for its digestive and medicinal properties.",
    Fact: "Pudina is essential for culinary enthusiasts and herbalists seeking a versatile herb with digestive benefits, adding a refreshing flavor and aroma to dishes, beverages, and teas. With its cooling properties, Pudina is also used topically in skincare products and aromatherapy blends to soothe and invigorate the senses, promoting overall well-being.",
  },
  {
    id: 165,
    name: "Thankuni",
    url: require("../../Images/PlantSelling/Medicinal Plants/Thankuni/41Xgkrsq52L._AC_UF1000,1000_QL80_.jpg"),
    images: [
      require("../../Images/PlantSelling/Medicinal Plants/Thankuni/41Xgkrsq52L._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Medicinal Plants/Thankuni/Centella_asiatica_(থানকুনি)_(3).jpg"),
      require("../../Images/PlantSelling/Medicinal Plants/Thankuni/profilbildklar_1024x1024.webp"),
    ],
    he1: "Thankuni",
    he2: "",
    Description:
      "Thankuni, also known as Indian pennywort or Centella asiatica, is a medicinal herb esteemed in traditional Ayurvedic and Asian medicine for its potential cognitive and health benefits, often brewed into teas or incorporated into herbal remedies.",
    Fact: "Thankuni is essential for individuals seeking natural remedies for cognitive support and overall well-being, believed to enhance memory, focus, and mental clarity in traditional medicine. With its rich history in herbal medicine, Thankuni is utilized in various forms, including teas, extracts, and supplements, to promote brain health and cognitive function.",
  },
  {
    id: 166,
    name: "Aloe Vera",
    url: require("../../Images/PlantSelling/Medicinal Plants/Aloe Vera/How-to-grow-aloe-vera-plant-at-home.jpg"),
    images: [
      require("../../Images/PlantSelling/Medicinal Plants/Aloe Vera/How-to-grow-aloe-vera-plant-at-home.jpg"),
      require("../../Images/PlantSelling/Medicinal Plants/Aloe Vera/aloe-vera-pot.webp"),
      require("../../Images/PlantSelling/Medicinal Plants/Aloe Vera/how-to-grow-aloe-vera-plant-outdoors-641a0e2c49b58.jpg"),
    ],
    he1: "Aloe",
    he2: "Vera",
    Description:
      "Aloe Vera is a versatile succulent plant revered for its soothing gel, known for its myriad skincare and medicinal benefits, including hydration, wound healing, and relieving sunburns.",
    Fact: "Aloe Vera is essential for skincare enthusiasts seeking a natural remedy for various skin conditions, from acne to dryness, thanks to its moisturizing and anti-inflammatory properties. With its healing properties, Aloe Vera is also utilized in first aid kits for treating minor burns, cuts, and insect bites, making it a versatile and indispensable plant for home remedies and personal care.",
  },
  {
    id: 167,
    name: "Coriander",
    url: require("../../Images/PlantSelling/Medicinal Plants/Coriander/15-b2c233-pmb-original-imafv9ykhxgtyqgb.webp"),
    images: [
      require("../../Images/PlantSelling/Medicinal Plants/Coriander/15-b2c233-pmb-original-imafv9ykhxgtyqgb.webp"),
      require("../../Images/PlantSelling/Medicinal Plants/Coriander/Cilantro-Coriander-How-Grow-A2935272.jpg"),
      require("../../Images/PlantSelling/Medicinal Plants/Coriander/how-to-grow-cilantro-hero.jpg"),
    ],
    he1: "Coriander",
    he2: "",
    Description:
      "Coriander, also known as cilantro or dhania, is a fragrant herb prized for its vibrant leaves and seeds, adding a fresh and citrusy flavor to culinary dishes worldwide.",
    Fact: "Coriander is indispensable in culinary creations, lending its aromatic and citrusy notes to diverse cuisines, from Asian curries to Latin American salsas. With its antioxidant properties and potential health benefits, coriander is also utilized in herbal medicine for aiding digestion, reducing inflammation, and promoting overall wellness.",
  },
];

const VegetablePlants = [
  {
    id: 180,
    name: "Tomato",
    url: require("../../Images/PlantSelling/Vegetable Plants/Tomato/how-to-grow-tomatoes-1000x666-64108ff3d9444_n.jpg"),
    images: [
      require("../../Images/PlantSelling/Vegetable Plants/Tomato/how-to-grow-tomatoes-1000x666-64108ff3d9444_n.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Tomato/iStock-1299586597.jpeg"),
      require("../../Images/PlantSelling/Vegetable Plants/Tomato/tomato_600x600.webp"),
    ],
    he1: "Tomato",
    he2: "",
    Description:
      "Tomato, a versatile and beloved fruit, is renowned for its juicy flesh and tangy flavor, used in a myriad of culinary dishes, from salads and sauces to soups and sandwiches.",
    Fact: "Tomatoes are essential staples in kitchens worldwide, providing essential nutrients like vitamins C and K, as well as antioxidants, for maintaining overall health.With their versatility in cooking and culinary applications, tomatoes are utilized in a wide range of dishes, adding depth of flavor, vibrant color, and nutritional value to various cuisines.",
  },
  {
    id: 181,
    name: "Potato",
    url: require("../../Images/PlantSelling/Vegetable Plants/Potato/1686066408245.jpg"),
    images: [
      require("../../Images/PlantSelling/Vegetable Plants/Potato/1686066408245.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Potato/Dug-up-mature-potato-plant.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Potato/Growing-Potatoes.jpg"),
    ],
    he1: "Potato",
    he2: "",
    Description:
      "Potato, a humble and versatile tuber, is a staple food crop cherished for its versatility in culinary dishes, from comforting mashed potatoes to crispy fries and hearty soups.",
    Fact: "Potatoes are indispensable in diverse cuisines, offering essential carbohydrates and nutrients for sustaining energy and nutrition. With their adaptability in cooking, potatoes serve as the foundation for countless dishes, from classic comfort foods to gourmet creations, catering to a wide range of culinary preferences and dietary needs.",
  },
  {
    id: 182,
    name: "Brinjal",
    url: require("../../Images/PlantSelling/Vegetable Plants/Brinjal/0_jP71WC2LsfeS9eST.jpg"),
    images: [
      require("../../Images/PlantSelling/Vegetable Plants/Brinjal/0_jP71WC2LsfeS9eST.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Brinjal/41Ak9LLNDUL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Brinjal/4YiRej5Q.jpeg"),
    ],
    he1: "Brinjal",
    he2: "",
    Description:
      "Brinjal, also known as eggplant or aubergine, is a versatile vegetable prized for its rich flavor and creamy texture, used in a variety of culinary cuisines worldwide.",
    Fact: "Brinjal is essential in global cuisine, providing a meaty texture and savory taste to dishes such as curries, stir-fries, and grilled preparations. With its versatility in cooking, brinjal serves as a versatile ingredient for vegetarian and vegan recipes, offering a hearty and satisfying option for plant-based meals.",
  },
  {
    id: 183,
    name: "Capsicum",
    url: require("../../Images/PlantSelling/Vegetable Plants/Capsicum/bell-peppers.webp"),
    images: [
      require("../../Images/PlantSelling/Vegetable Plants/Capsicum/bell-peppers.webp"),
      require("../../Images/PlantSelling/Vegetable Plants/Capsicum/how-to-grow-capsicum.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Capsicum/how-to-grow-peppers-hero.jpg"),
    ],
    he1: "Capsicum",
    he2: "",
    Description:
      "Capsicum, also known as bell pepper or sweet pepper, is a colorful and nutritious vegetable prized for its crisp texture and sweet flavor, adding vibrancy to salads, stir-fries, and stuffed dishes.",
    Fact: "The vibrant colors of capsicum, including red, yellow, and green, not only add visual appeal to dishes but also signify varying levels of ripeness and flavor intensity. With their crunchy texture and sweet taste, capsicum colors are essential for creating visually stunning and flavorful culinary creations, adding depth and versatility to a wide range of recipes, from salads to salsas and beyond.",
  },
  {
    id: 184,
    name: "Carrot",
    url: require("../../Images/PlantSelling/Vegetable Plants/Carrot/Carrots.webp"),
    images: [
      require("../../Images/PlantSelling/Vegetable Plants/Carrot/Carrots.webp"),
      require("../../Images/PlantSelling/Vegetable Plants/Carrot/how_to_grow_carrots.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Carrot/shutterstock_267359693.jpg"),
    ],
    he1: "Carrot",
    he2: "",
    Description:
      "Carrot, a crunchy and vibrant root vegetable, is prized for its sweet flavor and nutritional value, enjoyed raw as a snack or cooked in a variety of dishes, from soups to salads and stews.",
    Fact: "Carrots are essential for maintaining eye health and boosting immunity, thanks to their rich content of beta-carotene and vitamin C. With their versatile nature, carrots serve as a staple ingredient in diverse culinary creations, adding sweetness, texture, and vibrant color to dishes, from savory entrees to baked goods and desserts.",
  },
  {
    id: 185,
    name: "Beet",
    url: require("../../Images/PlantSelling/Vegetable Plants/Beats/Beets-in-rows.jpg"),
    images: [
      require("../../Images/PlantSelling/Vegetable Plants/Beats/Beets-in-rows.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Beats/Learn-to-Plant-and-Grow-Beets.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Beats/beet-foliage_full_width.jpg"),
    ],
    he1: "Beet",
    he2: "",
    Description:
      "Beet, a nutrient-rich root vegetable, is prized for its earthy flavor and vibrant color, enjoyed in salads, soups, and pickled preparations worldwide.",
    Fact: "Beets are essential for promoting cardiovascular health and supporting athletic performance, thanks to their high nitrate content, which may improve blood flow and exercise endurance. With their versatile culinary applications, beets serve as a versatile ingredient in salads, smoothies, and roasted dishes, adding depth of flavor and nutritional value to a variety of meals.",
  },
  {
    id: 186,
    name: "Radish",
    url: require("../../Images/PlantSelling/Vegetable Plants/Radish/610mp06ICCL._AC_UF1000,1000_QL80_.jpg"),
    images: [
      require("../../Images/PlantSelling/Vegetable Plants/Radish/610mp06ICCL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Radish/French-Breakfast-Radish-57c957f85f9b5829f4aa495f.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Radish/radish-daikon-miyashige-seeds-710790.webp"),
    ],
    he1: "Radish",
    he2: "",
    Description:
      "Radish, a crisp and peppery root vegetable, is prized for its crunchy texture and pungent flavor, enjoyed raw in salads, pickled in sandwiches, or cooked in stir-fries and soups.",
    Fact: "Radishes are essential for promoting digestive health and boosting immunity, thanks to their high fiber and vitamin C content. With their versatile nature, radishes serve as a zesty addition to salads, sandwiches, and appetizers, adding a refreshing crunch and spicy kick to culinary creations.",
  },
  {
    id: 187,
    name: "Green beans",
    url: require("../../Images/PlantSelling/Vegetable Plants/Green Beans/French-bean-plant-with-pods.jpg"),
    images: [
      require("../../Images/PlantSelling/Vegetable Plants/Green Beans/French-bean-plant-with-pods.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Green Beans/beans.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Green Beans//snap-beans-web_3ca6176e-3d96-4031-a320-f8d91e098ec0.jpg"),
    ],
    he1: "Green",
    he2: "beans",
    Description:
      "Green beans, also known as string beans or snap beans, are tender and flavorful legumes prized for their crisp texture and fresh taste, enjoyed steamed, sautéed, or stir-fried as a nutritious side dish or ingredient in various culinary recipes.",
    Fact: "Green beans are essential for promoting heart health and supporting overall well-being, as they are rich in dietary fiber, vitamins, and antioxidants. With their versatility in cooking, green beans serve as a nutritious and delicious addition to salads, casseroles, stir-fries, and vegetable medleys, providing a burst of freshness and flavor to a wide range of dishes.",
  },
  {
    id: 188,
    name: "Broccoli",
    url: require("../../Images/PlantSelling/Vegetable Plants/Brocoli/7-23.webp"),
    images: [
      require("../../Images/PlantSelling/Vegetable Plants/Brocoli/7-23.webp"),
      require("../../Images/PlantSelling/Vegetable Plants/Brocoli/companion-plants-for-broccoli-2540037-33-de3a2887369742e2a41e7a89abf27d44.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Brocoli/lede-broccoli-1524256843.jpg"),
    ],
    he1: "Broccoli",
    he2: "",
    Description:
      "Broccoli, a nutrient-dense cruciferous vegetable, is prized for its earthy flavor and crunchy texture, enjoyed steamed, roasted, or raw as a nutritious addition to salads, stir-fries, and pasta dishes.",
    Fact: "Broccoli is essential for promoting bone health and supporting immune function, thanks to its high content of vitamin K and vitamin C. With its versatile culinary applications, broccoli serves as a nutrient-rich addition to soups, salads, stir-fries, and side dishes, offering a delicious and wholesome option for incorporating essential vitamins and minerals into meals.",
  },
  {
    id: 189,
    name: "Cabbage",
    url: require("../../Images/PlantSelling/Vegetable Plants/Cabbage/25-n-3-33-plantzon-original-imafwamugsz8pfdg.webp"),
    images: [
      require("../../Images/PlantSelling/Vegetable Plants/Cabbage/25-n-3-33-plantzon-original-imafwamugsz8pfdg.webp"),
      require("../../Images/PlantSelling/Vegetable Plants/Cabbage/81GbdKjlKwL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Cabbage/Cabbage.webp"),
    ],
    he1: "Cabbage",
    he2: "",
    Description:
      "Cabbage, a versatile cruciferous vegetable, is prized for its crisp texture and mild flavor, enjoyed raw in slaws, pickled in sauerkraut, or cooked in soups, stews, and stir-fries.",
    Fact: "Cabbage is essential for promoting digestive health and supporting detoxification, thanks to its high fiber and antioxidant content. With its versatility in cooking, cabbage serves as a nutritious and budget-friendly ingredient in a variety of dishes, from coleslaws and salads to stuffed cabbage rolls and hearty soups, providing both flavor and nutritional benefits to meals.",
  },
  {
    id: 190,
    name: "Onion",
    url: require("../../Images/PlantSelling/Vegetable Plants/Onion/grow-onions.webp"),
    images: [
      require("../../Images/PlantSelling/Vegetable Plants/Onion/grow-onions.webp"),
      require("../../Images/PlantSelling/Vegetable Plants/Onion/onion-farming-2.jpg"),
      require("../../Images/PlantSelling/Vegetable Plants/Onion/juveynwx3pe4kr622b46b0468f7.jpg"),
    ],
    he1: "Onion",
    he2: "",
    Description:
      "Onion, a pungent and flavorful bulb vegetable, is a staple ingredient in culinary cuisine, adding depth of flavor and aroma to a wide range of savory dishes, from soups and stews to stir-fries and sauces.",
    Fact: "Onions are essential for enhancing the taste and aroma of savory dishes, thanks to their distinctively pungent and sweet flavor profile. With their versatile culinary applications, onions serve as a fundamental ingredient in various cuisines worldwide, providing depth of flavor, texture, and nutritional value to a multitude of recipes, from simple salads to complex sauces and curries.",
  },
];

const FruitPlants = [
  {
    id: 201,
    name: "Mango",
    url: require("../../Images/PlantSelling/Fruit Plants/Mango/517H-xojHHL.jpg"),
    images: [
      require("../../Images/PlantSelling/Fruit Plants/Mango/517H-xojHHL.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Mango/green-line-rare-miyazaki-hybrid-2-healthy-live-plant-mango-grafted-live-plant-variety-outdoor-garden-live-plant-product-images-orvihhj1fyz-p603871366-3-2.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Mango/kapil-agro-kesar-mango-plants-1557307358-4893986.jpeg"),
    ],
    he1: "Mango",
    he2: "",
    Description:
      'Mango, often hailed as the "king of fruits," is a tropical delicacy prized for its luscious texture, sweet flavor, and aromatic fragrance, enjoyed fresh, dried, or incorporated into smoothies, desserts, chutneys, and savory dishes.',
    Fact: "Mangoes are essential for promoting skin health and supporting immune function, thanks to their high content of vitamins A and C, as well as antioxidants. With their versatile culinary applications, mangoes serve as a delectable addition to fruit salads, desserts, beverages, and savory dishes, offering a burst of tropical sweetness and vibrant color to a variety of recipes.",
  },
  {
    id: 202,
    name: "Orange",
    url: require("../../Images/PlantSelling/Fruit Plants/Orange/41QEHFthiS.jpg"),
    images: [
      require("../../Images/PlantSelling/Fruit Plants/Orange/41QEHFthiS.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Orange/618Aed46s+L._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Orange/Chinese-orange.jpg"),
    ],
    he1: "Orange",
    he2: "",
    Description:
      "Orange, a vibrant and juicy citrus fruit, is cherished for its refreshing flavor, high vitamin C content, and versatility in culinary and beverage preparations, enjoyed fresh, juiced, or incorporated into salads, desserts, and savory dishes.",
    Fact: "Oranges are essential for promoting immune health and supporting overall well-being, thanks to their abundant vitamin C and antioxidants. With their versatile culinary applications, oranges serve as a flavorful addition to salads, marinades, desserts, and beverages, adding a zesty and refreshing twist to a wide range of recipes.",
  },
  {
    id: 203,
    name: "Jamrul",
    url: require("../../Images/PlantSelling/Fruit Plants/Jamrul/41Wdn6+xyXL._AC_UF350,350_QL80_.jpg"),
    images: [
      require("../../Images/PlantSelling/Fruit Plants/Jamrul/41Wdn6+xyXL._AC_UF350,350_QL80_.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Jamrul/istockphoto-1320365402-612x612.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Jamrul/white-water-appleplant-with-fruit-image-Hasiruagro-600x600.jpg"),
    ],
    he1: "Jamrul",
    he2: "",
    Description:
      "Jamrul, also known as rose apple or water apple, is a tropical fruit prized for its crisp texture, mild sweetness, and floral aroma, enjoyed fresh or used in jams, jellies, and beverages.",
    Fact: "Jamrul is essential for promoting hydration and supporting digestive health, thanks to its high water content and dietary fiber. With its versatile culinary applications, jamrul serves as a refreshing snack, ingredient in fruit salads, and flavoring agent in jams, sorbets, and tropical drinks, offering a delightful burst of sweetness and aroma to a variety of culinary creations.",
  },
  {
    id: 204,
    name: "Cucumber",
    url: require("../../Images/PlantSelling/Fruit Plants/Cucumber/green-fingers-cucumber-0d9e0b36-10a18e309f664e028d33cccc3cc758de.jpg"),
    images: [
      require("../../Images/PlantSelling/Fruit Plants/Cucumber/green-fingers-cucumber-0d9e0b36-10a18e309f664e028d33cccc3cc758de.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Cucumber/how-to-grow-cucumbers-641ce2c9653ec.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Cucumber/marketer-cucumber-60e158aa-c3f14f3be8a74d1a98b3a57e589a3e84.jpg"),
    ],
    he1: "Cucumber",
    he2: "",
    Description:
      "Cucumber, a crisp and hydrating vegetable, is prized for its refreshing flavor and versatile culinary uses, enjoyed raw in salads, sandwiches, and appetizers, or pickled in relishes and sauces.",
    Fact: "Cucumbers are essential for promoting hydration and supporting skin health, thanks to their high water content and nutrient-rich composition. With their versatile culinary applications, cucumbers serve as a refreshing addition to salads, sandwiches, and wraps, as well as a natural remedy for soothing sunburns and reducing puffiness around the eyes.",
  },
  {
    id: 205,
    name: "Strawberry",
    url: require("../../Images/PlantSelling/Fruit Plants/Strawberry/71lwiu+YOiL._AC_UF1000,1000_QL80_.jpg"),
    images: [
      require("../../Images/PlantSelling/Fruit Plants/Strawberry/71lwiu+YOiL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Strawberry/Strawberry-Plant.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Strawberry/nurserylive-strawberry-plant-sweet-charlie-jharaber-grafted-plant.jpg"),
    ],
    he1: "Strawberry",
    he2: "",
    Description:
      "Strawberry, a vibrant and juicy berry, is prized for its sweet flavor, aromatic fragrance, and vibrant color, enjoyed fresh, in desserts, smoothies, jams, and as a garnish in various culinary creations.",
    Fact: "Strawberries are essential for promoting heart health and supporting immune function, thanks to their high content of vitamin C, antioxidants, and dietary fiber. With their versatile culinary applications, strawberries serve as a delicious and nutritious addition to breakfast bowls, desserts, salads, and beverages, adding a burst of sweetness and vibrant color to a wide range of recipes.",
  },
  {
    id: 206,
    name: "Papaya",
    url: require("../../Images/PlantSelling/Fruit Plants/Papaya/c32cbdd7bdfe5a718635cef5a7dfdabe.jpg"),
    images: [
      require("../../Images/PlantSelling/Fruit Plants/Papaya/c32cbdd7bdfe5a718635cef5a7dfdabe.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Papaya/product-jpeg.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Papaya/red-lady-papaya-plant.jpg"),
    ],
    he1: "Papaya",
    he2: "",
    Description:
      "Papaya, a tropical fruit with vibrant orange flesh and black seeds, is prized for its sweet flavor, creamy texture, and abundant health benefits, enjoyed fresh, blended into smoothies, or used in salads, salsas, and desserts.",
    Fact: "Papaya is essential for promoting digestion and supporting immune health, thanks to its rich content of digestive enzymes, vitamins, and antioxidants. With its versatile culinary applications, papaya serves as a delicious addition to fruit salads, salsa, desserts, and savory dishes, adding a tropical sweetness and vibrant color to various recipes.",
  },
  {
    id: 207,
    name: "Jamun",
    url: require("../../Images/PlantSelling/Fruit Plants/Jamun/420_25_600x600_auto_q85_kaala-jamun-jumbo-variety.jpg"),
    images: [
      require("../../Images/PlantSelling/Fruit Plants/Jamun/420_25_600x600_auto_q85_kaala-jamun-jumbo-variety.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Jamun/hq720.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Jamun/nurserylive-jamun-plant.jpg"),
    ],
    he1: "Jamun",
    he2: "",
    Description:
      "Jamun, also known as Java plum or black plum, is a nutrient-rich fruit prized for its tangy-sweet flavor, deep purple hue, and potential health benefits, enjoyed fresh, juiced, or used in jams, syrups, and desserts.",
    Fact: "Jamun is essential for promoting blood sugar regulation and supporting digestive health, thanks to its high content of antioxidants, fiber, and bioactive compounds. With its versatile culinary applications, jamun serves as a flavorful addition to fruit salads, smoothies, desserts, and beverages, offering both delicious taste and potential health benefits.",
  },
  {
    id: 208,
    name: "Guava",
    url: require("../../Images/PlantSelling/Fruit Plants/Guava/Understand-Guava-Plant4.jpg"),
    images: [
      require("../../Images/PlantSelling/Fruit Plants/Guava/Understand-Guava-Plant4.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Guava/Urban-plants-guava-fruit.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Guava/guava-nursery-plant.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Guava/taiwan-pink-guava-plant.jpg"),
    ],
    he1: "Guava",
    he2: "",
    Description:
      "Guava, a tropical fruit with fragrant aroma and sweet-tart flavor, is prized for its juicy flesh, abundance of seeds, and rich vitamin C content, enjoyed fresh, juiced, or used in jams, jellies, and desserts.",
    Fact: "Guava is essential for promoting immune health and supporting digestion, thanks to its high content of vitamin C, fiber, and antioxidants. With its versatile culinary applications, guava serves as a delicious addition to fruit salads, smoothies, desserts, and savory dishes, offering a unique tropical flavor and abundance of nutrients to various recipes.",
  },
  {
    id: 209,
    name: "Mud apple (Sabeda)",
    url: require("../../Images/PlantSelling/Fruit Plants/Mud Apple (Sabeda)/610p3wrPoiL._AC_UF1000,1000_QL80_.jpg"),
    images: [
      require("../../Images/PlantSelling/Fruit Plants/Mud Apple (Sabeda)/610p3wrPoiL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Fruit Plants/Mud Apple (Sabeda)/chiku-fruits-plants.jpeg"),
    ],
    he1: "Mud",
    he2: "apple",
    Description:
      "Mud apple, also known as sapodilla or chiku, is a tropical fruit prized for its sweet and caramel-like flavor, creamy texture, and potential health benefits, enjoyed fresh, in desserts, or used in jams and smoothies.",
    Fact: "Mud apple is essential for promoting digestion and supporting bone health, thanks to its high content of dietary fiber, vitamins, and minerals. With its versatile culinary applications, mud apple serves as a delicious addition to fruit salads, desserts, juices, and ice creams, offering both delightful taste and potential health benefits.",
  },
];
const WoodTreeandPlants = [
  {
    id: 220,
    name: "Palm ",
    url: require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Palm/GettyImages-1394626321-31714a4267db4ca4aae9d9aadd058fb6.jpg"),
    images: [
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Palm/GettyImages-1394626321-31714a4267db4ca4aae9d9aadd058fb6.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Palm/a387784579d0b6e9541aacf33b8ff260.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Palm/rsz_1280px-palm_tree_alley.jpg"),
    ],
    he1: "Palm",
    he2: "",
    Description:
      "Palm fruit, also known as toddy palm or palmyra fruit, is a tropical delicacy prized for its sweet taste, jelly-like texture, and potential health benefits, enjoyed fresh, in desserts, or processed into jaggery, syrup, and alcoholic beverages.",
    Fact: "Palm fruit is essential for promoting hydration and supporting overall well-being, thanks to its high water content and nutrient-rich composition. With its versatile culinary applications, palm fruit serves as a refreshing addition to fruit salads, desserts, beverages, and traditional dishes, offering a unique tropical flavor and abundance of nutrients to various recipes.",
  },
  {
    id: 221,
    name: "Coconut",
    url: require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Coconut/61KDpCxnHoL.jpg"),
    images: [
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Coconut/61KDpCxnHoL.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Coconut/orange-dwarf-3.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Coconut/product-jpeg-500x500.jpg"),
    ],
    he1: "Coconut",
    he2: "",
    Description:
      "Coconut, a tropical fruit with a hard shell and white flesh, is prized for its sweet taste, creamy texture, and versatile culinary uses, enjoyed fresh, shredded, or as coconut milk, oil, water, and flour in a variety of dishes and desserts.",
    Fact: "Coconut is essential for promoting hydration and supporting overall health, thanks to its high electrolyte content, including potassium and magnesium. With its versatile culinary applications, coconut serves as a nutritious addition to smoothies, curries, baked goods, desserts, and savory dishes, offering a rich flavor and texture to various recipes while providing essential nutrients and healthy fats.",
  },
  {
    id: 222,
    name: "Sehgun",
    url: require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Sehgun/71JQqoPZpNL._AC_UF1000,1000_QL80_.jpg"),
    images: [
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Sehgun/71JQqoPZpNL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Sehgun/Teak-Plant-1.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Sehgun/segun-teak-plants.jpg"),
      ,
    ],
    he1: "Sehgun",
    he2: "",
    Description:
      "Sehgun, also known as mahua or butter tree, is a tropical tree prized for its edible flowers, sweet-scented seeds, and potential medicinal properties, used in traditional cuisines, beverages, and herbal remedies.",
    Fact: "Sehgun is essential for promoting respiratory health and supporting skincare, thanks to its antibacterial, anti-inflammatory, and moisturizing properties. With its versatile applications, sehgun flowers and seeds are used in culinary dishes, beverages, and herbal remedies, offering both flavor and potential health benefits to various preparations.",
  },
  {
    id: 223,
    name: "Mehgun",
    url: require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Mehgun/200galSwietenia-mahagoniIMG_0673.jpg"),
    images: [
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Mehgun/200galSwietenia-mahagoniIMG_0673.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Mehgun/Mahogany-1.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Mehgun/maxresdefault.jpg"),
    ],
    he1: "Mehgun",
    he2: "",
    Description:
      "Mehgun, also known as mahua or Indian butter tree, is a tropical tree valued for its edible flowers, seeds, and potential medicinal properties, utilized in traditional cuisines, beverages, and herbal remedies.",
    Fact: "Mehgun is essential for promoting respiratory health and supporting skincare, owing to its antibacterial, anti-inflammatory, and moisturizing properties. With its versatile applications, mehgun flowers and seeds are used in culinary dishes, beverages, and herbal remedies, providing both flavor and potential health benefits to various preparations.",
  },
  {
    id: 224,
    name: "Lambu",
    url: require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Lambu/new-product-500x500.webp"),
    images: [
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Lambu/new-product-500x500.webp"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Lambu/mahogany-plants-500x500.webp"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Lambu/product-jpeg-500x500.webp"),
    ],
    he1: "Lambu",
    he2: "",
    Description:
      "Lambu, also known as bamboo shoot, is a versatile and nutritious edible shoot derived from bamboo plants, prized for its tender texture, earthy flavor, and culinary versatility in various cuisines worldwide.",
    Fact: "Lambu is essential for promoting digestive health and supporting immunity, thanks to its high content of fiber, vitamins, and antioxidants. With its versatile culinary applications, lambu is used in soups, stir-fries, curries, and salads, offering a unique texture and flavor to dishes while providing essential nutrients and potential health benefits.",
  },
  {
    id: 225,
    name: "Sal",
    url: require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Sal/360_F_380310528_3cKxOSt7SNkJpZK7zepvP6Uqxplp7Zc1.jpg"),
    images: [
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Sal/360_F_380310528_3cKxOSt7SNkJpZK7zepvP6Uqxplp7Zc1.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Sal/71vEpTE7QyL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Sal/sal-tree.jpg"),
    ],
    he1: "Sal",
    he2: "",
    Description:
      "Sal, also known as Indian mahogany, is a tropical hardwood tree prized for its durable timber, resin, and medicinal properties, utilized in furniture making, construction, and traditional Ayurvedic remedies.",
    Fact: "Sal is essential for sustainable forestry and ecosystem conservation, serving as a valuable source of timber for furniture, construction, and paper production. With its versatile applications, sal resin and extracts are used in traditional medicine for treating various ailments, while its timber provides a durable and valuable resource for building materials and artisanal crafts.",
  },
  {
    id: 226,
    name: "Debdaru",
    url: require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Debdaru/no-annual-yes-live-debdaru-plant-withoutpot-0017-ashok-1-plastic-original-imagnznxgfhspcgy.webp"),
    images: [
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Debdaru/no-annual-yes-live-debdaru-plant-withoutpot-0017-ashok-1-plastic-original-imagnznxgfhspcgy.webp"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Debdaru/prod-20200909-0435137057453365811420798-jpg-500x500.webp"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Debdaru/product-jpeg-500x500.webp"),
    ],
    he1: "Debdaru",
    he2: "",
    Description:
      "Debdaru, also known as Himalayan cedar or deodar, is a coniferous tree prized for its aromatic wood, durable timber, and potential medicinal properties, used in construction, furniture making, and traditional Ayurvedic remedies.",
    Fact: "Debdaru is essential for sustainable forestry and ecosystem preservation, providing valuable timber for construction, furniture, and handicrafts. With its aromatic properties, debdaru wood is also used in traditional medicine and aromatherapy, offering potential health benefits and promoting relaxation and well-being.",
  },
  {
    id: 227,
    name: "Ashwatha",
    url: require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Ashathya/51vAqPBSasL._AC_UF1000,1000_QL80_.jpg"),
    images: [
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Ashathya/51vAqPBSasL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Ashathya/Copy-Image-74.webp"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Ashathya/Pipal-Tree-From-Home.webp"),
    ],
    he1: "Ashwatha",
    he2: "",
    Description:
      "The Ashwatha tree known as the Peepal tree or Ficus religiosa, is a species of fig tree native to the Indian subcontinent and parts of Southeast Asia. It holds immense significance in various cultures and religions, especially in Hinduism, Buddhism, and Jainism.",
    Fact: "The Ashwatha tree is revered for its longevity and is often planted near temples and in sacred spaces. Its heart-shaped leaves are believed to have medicinal properties and are used in traditional medicine. The tree is also considered a symbol of fertility and prosperity.",
  },
  {
    id: 228,
    name: "Banyan",
    url: require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Banyan (Bot)/614S1kSkEEL._AC_UF1000,1000_QL80_.jpg"),
    images: [
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Banyan (Bot)/614S1kSkEEL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Banyan (Bot)/Banyan-tree-bonsai.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Banyan (Bot)/Variegated-Bot-Live-Plant4.webp"),
    ],
    he1: "Banyan",
    he2: "",
    Description:
      "The banyan tree has great cultural and religious significance in many cultures, especially in India. It is considered sacred in Hinduism, Buddhism, and Jainism, and is often associated with the divine. In Hindu mythology, the banyan tree is believed to be the abode of the god Krishna, and it is often referred to as the wish-fulfilling tree",
    Fact: "Overall, the banyan tree is a symbol of longevity, resilience, and interconnectedness in many cultures, and it continues to be an important part of the natural and cultural landscape in many tropical regions.",
  },
  {
    id: 229,
    name: "Krishnacura",
    url: require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Krisnachura/51YyEc8DZEL.jpg"),
    images: [
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Krisnachura/51YyEc8DZEL.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Krisnachura/A1GgHSqTasL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Krisnachura/Delonix_regia_02_Fl_main_web_2015_fullsize.jpg"),
    ],
    he1: "Krishnacura",
    he2: "",
    Description:
      "The Krishnacura tree is culturally significant in many regions where it grows. In India, the tree is associated with various festivals and traditions. The flowers are used in traditional medicine for their medicinal properties, and the tree itself is considered sacred in Hinduism",
    Fact: "The tree is also valued for its timber, which is used in woodworking and furniture making. Additionally, the tree has some ecological importance as it provides food and habitat for various birds and insects.",
  },
  {
    id: 230,
    name: "Polash",
    url: require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Polash/Palash-Tree.jpg"),
    images: [
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Polash/Palash-Tree.jpg"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Polash/Untitleddesign_90_f25f602c-626c-467a-9c49-363f31c82193.webp"),
      require("../../Images/PlantSelling/Wood Tree & Shaded Plants/Polash/palash-tree-compressed.webp"),
    ],
    he1: "Polash",
    he2: "",
    Description:
      "The Polash tree, scientifically known as Butea monosperma, is a species of flowering tree native to the Indian subcontinent, Southeast Asia, and China. It is also known by various other names, including Flame of the Forest, Bastard Teak, and Palash.",
    Fact: "The tree is known for its vibrant orange to red flowers, which bloom in clusters and are a sight to behold, especially during the spring season. These flowers are not only visually striking but also hold cultural significance in many regions where the tree is found.",
  },
];
const LandscapingPlants = [
  {
    id: 238,
    name: 'Agave',
    url: require("../../Images/PlantSelling/Landscaping plants/agave/3.jpg"),
    images: [
      require("../../Images/PlantSelling/Landscaping plants/agave/3.jpg"),
      require("../../Images/PlantSelling/Landscaping plants/agave/2.jpg"),
      require("../../Images/PlantSelling/Landscaping plants/agave/1.jpg"),
    ],
    he1: 'Agave',
    he2: '',
    Description: 'Agave plants, known for their striking architectural form and drought-tolerant nature, are prized for their sculptural appeal in xeriscapes, succulent gardens, and arid landscapes.',
    Fact: 'Agave plants are essential for landscaping in arid regions, as they require minimal water and maintenance while providing dramatic visual impact with their bold shapes and textures. Their versatility makes them suitable for various design styles, from modern to desert-themed gardens, adding interest and character to outdoor spaces.',
  },
  {
    id: 239,
    name: 'Creeping Juniper',
      url: require("../../Images/PlantSelling/Landscaping plants/juniper/2.jpg"),
      images: [
        require("../../Images/PlantSelling/Landscaping plants/juniper/2.jpg"),
        require("../../Images/PlantSelling/Landscaping plants/juniper/1.jpg"),
        require("../../Images/PlantSelling/Landscaping plants/juniper/3.jpg"),
        
      ],
      he1: 'Creeping',
      he2: 'Juniper',
      Description: "Creeping juniper, with its low-growing habit and evergreen foliage, serves as an excellent ground cover option for slopes, rocky areas, and spaces needing erosion control, while also offering year-round greenery and texture to landscapes.  ",
      Fact: 'Creeping juniper is invaluable for stabilizing soil on slopes, preventing erosion, and suppressing weed growth, making it an ideal choice for landscaping projects focused on sustainability and low-maintenance solutions. Its dense, spreading habit also adds visual interest to gardens, borders, and rockeries, creating a lush carpet of greenery.',
  },
  {
    id: 240,
    name: 'Hydrangea ',
      url: require("../../Images/PlantSelling/Landscaping plants/hydra/2.jpg"),
      images: [
        require("../../Images/PlantSelling/Landscaping plants/hydra/2.jpg"),
        require("../../Images/PlantSelling/Landscaping plants/hydra/1.jpg"),
        require("../../Images/PlantSelling/Landscaping plants/hydra/3.webp"),
      ],
      he1: 'Hydrangea',
      he2: '',
      Description: 'Hydrangeas, beloved for their voluminous blooms and lush foliage, are versatile plants that bring charm and elegance to gardens, borders, and containers, offering a stunning display of colors ranging from soft pastels to vibrant hues.  ',
      Fact: 'Hydrangeas are sought after for their ability to thrive in various lighting conditions and soil types, making them suitable for a wide range of landscapes, from shaded garden corners to sunny borders. Their long-lasting, showy blooms also make them popular choices for adding texture and color to flower arrangements and bouquets, perfect for enhancing the aesthetic appeal of any outdoor space.',
  },
  {
    id: 241,
    name: "Hibiscus",
    url: require("../../Images/PlantSelling/Summer Flowers/Hibiscus/hibiscus_rosasinensis.webp"),
    images: [
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/hibiscus_rosasinensis.webp"),
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/c84762391b6278096cd37d9586e8a3de.jpg"),  
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/hibiscus-orange.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/hibiscus-yellow-seed2plant-2.webp"),
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/Hibiscus-Blue-3.jpg"),
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/nurserylive-g-hibiscus-gudhal-flower-orange-color-plant-705397.webp"),
      require("../../Images/PlantSelling/Summer Flowers/Hibiscus/h2vuurdcbb2a1.jpg"),
    ],
    he1: "Hibiscus",
    he2: "",
    Description:
      "Hibiscus is a flowering plant prized for its vibrant and showy blooms, adding a touch of tropical elegance to gardens and landscapes.",
    Fact: "Hibiscus is a popular flowering plant known for its vibrant blooms and ornamental foliage, making it a favorite choice for adding color and beauty to gardens, landscapes, and indoor spaces. With its wide range of varieties and adaptable growing conditions, hibiscus is versatile in landscaping, used as hedge plants, specimen shrubs, or container plants, and valued for its ability to attract pollinators and create stunning focal points in outdoor settings.",
  },
];

const OtherPlants = [
  {
    id: 250,
    name: "Bonsai",
    url: require("../../Images/PlantSelling/Other Plants/Bonsai/GettyImages-598322873-409b66c4895d492eb28fde9e752d98ad.jpg"),
    images: [
      require("../../Images/PlantSelling/Other Plants/Bonsai/GettyImages-598322873-409b66c4895d492eb28fde9e752d98ad.jpg"),
      require("../../Images/PlantSelling/Other Plants/Bonsai/IMG_20210426_142721_2-scaled.jpg"),
      require("../../Images/PlantSelling/Other Plants/Bonsai/ML_Grow_Bonsai-8-2000-54d3d1a7424b4359b44e52383f40b834.jpg"),
      require("../../Images/PlantSelling/Other Plants/Bonsai/how_to_take_care_of_bonsai_trees_header_4_1280x.webp"),
    ],
    he1: "Bonsai",
    he2: "",
    Description:
      "Bonsai, a traditional Japanese art form, involves cultivating miniature trees in pots, meticulously pruned and shaped to mimic the appearance of full-sized trees, prized for their beauty, symbolism, and meditative practice.",
    Fact: "Bonsai is essential for promoting mindfulness and cultivating patience and artistic expression through the meticulous care and shaping of miniature trees. With their aesthetic appeal and symbolic significance, bonsai trees are used for ornamental purposes, meditation, and as a focal point in indoor and outdoor settings, adding beauty and tranquility to living spaces and gardens.",
  },
  {
    id: 251,
    name: "Cactus",
    url: require("../../Images/PlantSelling/Other Plants/Cactus/1523049927299.jpeg"),
    images: [
      require("../../Images/PlantSelling/Other Plants/Cactus/1523049927299.jpeg"),
      require("../../Images/PlantSelling/Other Plants/Cactus/61f2ooaR8FL._AC_UF1000,1000_QL80_.jpg"),
      require("../../Images/PlantSelling/Other Plants/Cactus/81ahL6ZW_YL-566693_1024x1024.webp"),
      require("../../Images/PlantSelling/Other Plants/Cactus/How-to-Care-for-Flowering-Cactus-Varieties.jpg"),
    ],
    he1: "Cactus",
    he2: "",
    Description:
      "Cactus, a succulent plant known for its fleshy stems and spines, thrives in arid environments and is prized for its low maintenance, unique appearance, and potential medicinal properties, used in ornamental gardens, landscaping, and as indoor houseplants.",
    Fact: "Cactus varieties are essential for drought-resistant landscaping and adding texture and visual interest to gardens and landscapes, particularly in arid regions. With their low water requirements and adaptability to various climates, cacti are popular ornamental plants for xeriscaping, container gardening, and indoor cultivation, offering both aesthetic appeal and practicality in diverse settings.",
  },
  {
    id: 252,
    name: "Orchids",
    url: require("../../Images/PlantSelling/Other Plants/Orchid/moth-orchids-plant-01-1.jpg"),
    images: [
      require("../../Images/PlantSelling/Other Plants/Orchid/moth-orchids-plant-01-1.jpg"),
      require("../../Images/PlantSelling/Other Plants/Orchid/orchid-plant-1616826423-5769746.jpeg"),
      require("../../Images/PlantSelling/Other Plants/Orchid/perennial-no-yes-orcdflp-ssb-cb10-mar93-1-pot-sssb-live-plant-original-imagh2utvyhaam6y.webp"),
    ],
    he1: "Orchids",
    he2: "",
    Description:
      "Orchid, a diverse and exquisite flowering plant family, is prized for its stunning blooms, elegant appearance, and cultural significance, cultivated for ornamental purposes, floral arrangements, and as a symbol of luxury and refinement.",
    Fact: "Orchids are essential for enhancing indoor and outdoor aesthetics, thanks to their diverse colors, shapes, and sizes, which add elegance and beauty to living spaces and gardens. With their longevity and symbolism of love, luxury, and sophistication, orchids are popular choices for gifts, decorations, and special occasions, creating memorable experiences and enhancing ambiance.",
  },
  {
    id: 253,
    name: "Moss",
    url: require("../../Images/PlantSelling/Other Plants/Moss/Money-Plant-With-Moss-Stick-New-2.webp"),
    images: [
      require("../../Images/PlantSelling/Other Plants/Moss/Money-Plant-With-Moss-Stick-New-2.webp"),
      require("../../Images/PlantSelling/Other Plants/Moss/moss-stick-500x500.webp"),
      require("../../Images/PlantSelling/Other Plants/Moss/s-l1600.jpg"),
      require("../../Images/PlantSelling/Other Plants/Moss/shutterstock_712617145.jpg"),
    ],
    he1: "Moss",
    he2: "",
    Description:
      "Moss, a small, non-vascular plant, thrives in damp environments and is prized for its lush greenery, soft texture, and ability to create natural, serene landscapes, used in gardens, terrariums, and floral arrangements.",
    Fact: "Moss is essential for soil erosion control, moisture retention, and ecological balance in ecosystems, making it a valuable component of landscaping, gardening, and restoration projects. With its ability to thrive in various conditions and add natural beauty to outdoor and indoor spaces, moss is used for ground cover, accentuating rock gardens, and creating tranquil, moss-covered landscapes.",
  },
  {
    id: 254,
    name: "Succulent",
    url: require("../../Images/PlantSelling/Other Plants/Succulent/91lIlSsL8PL.jpg"),
    images: [
      require("../../Images/PlantSelling/Other Plants/Succulent/91lIlSsL8PL.jpg"),
      require("../../Images/PlantSelling/Other Plants/Succulent/succulent-plant-500x500.webp"),
      require("../../Images/PlantSelling/Other Plants/Succulent/succulents-mix-4-600x600-466057.jpg"),
    ],
    he1: "Succulent",
    he2: "",
    Description:
      "Succulents, water-storing plants with fleshy leaves or stems, are prized for their unique shapes, vibrant colors, and low-maintenance care requirements, used in gardens, terrariums, and as houseplants for their ornamental value and resilience.",
    Fact: "Succulents are essential for drought-tolerant landscaping and xeriscaping, thriving in arid climates and requiring minimal watering and maintenance. With their diverse shapes and colors, succulents are popular choices for ornamental gardens, container arrangements, and indoor decor, adding visual interest and texture to living spaces while conserving water and promoting sustainability.",
  },
];

export default {
  SummerFlowers,
  WinterFlowers,
  IndoorPlants,
  OutdoorPlants,
  MedicinalPlants,
  VegetablePlants,
  FruitPlants,
  WoodTreeandPlants,
  LandscapingPlants,
  OtherPlants,
};
