import React, { useEffect } from 'react'
import Navbar2 from '../Navbar2';
import { motion } from "framer-motion";
import Connect from '../Components/Connect';
import { useContext, useState } from 'react'
import AnimateContext from '../Components/useContext'
import Images from '../Images';

function Certificates() {
    const animate = useContext(AnimateContext)
    return (
        <motion.div initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}>
            <Navbar2 />
            <Connect />
            <div className='tw-bg-cover tw-h-80 tw-flex tw-justify-center tw-items-center tw-bg-service' >
                <h1 className='tw-font-Montserrat tw-text-3xl tw-font-bold tw-text-emerald-50'>CERTIFICATES</h1>
            </div>
            <div className=' tw-mx-[5%]'>
                <h1
                    style={{ fontWeight: "600" }}
                    className="tw-text-[24px] md:tw-text-3xl my-5 pt-5 text-center tw-font-Montserrat">
                    Our{" "}
                    {
                        <span className="c-green">
                            <span className={` ${animate ? "logo is-animetion" : ""}`}>
                                <span>C</span>
                                <span>e</span>
                                <span>r</span>
                                <span>t</span>
                                <span>i</span>
                                <span>f</span>
                                <span>i</span>
                                <span>c</span>
                                <span>a</span>
                                <span>t</span>
                                <span>e</span>
                                <span>s</span>
                            </span>
                        </span>
                    }
                </h1>


                <div class="tw-flex tw-flex-col tw-gap-10 ">
                    <div className=' tw-flex sm:tw-flex-row tw-flex-col tw-gap-4 tw-justify-evenly tw-items-center'>
                        <div>
                            <img class="tw-h-auto tw-max-w-[300px] tw-rounded-lg" src={Images.Images.c1} alt="" />
                        </div>
                        <div>
                            <img class="tw-h-auto tw-max-w-[300px] tw-rounded-lg"  src={Images.Images.c2} alt="" />
                        </div>
                        <div>
                            <img class="tw-h-auto tw-max-w-[300px] tw-rounded-lg"  src={Images.Images.c3} alt="" />
                        </div>
                    </div>
                    <div className=' tw-flex sm:tw-flex-row tw-flex-col tw-gap-4 tw-justify-evenly tw-items-center'>
                        <div>
                            <img class="tw-h-auto tw-max-w-[300px] tw-rounded-lg"  src={Images.Images.c4} alt="" />
                        </div>
                        <div>
                            <img class="tw-h-auto tw-max-w-[300px] tw-rounded-lg"  src={Images.Images.c5} alt="" />
                        </div>
                        <div>
                            <img class="tw-h-auto tw-max-w-[300px] tw-rounded-lg"  src={Images.Images.c6} alt="" />
                        </div>
                    </div>



                </div>

            </div>
        </motion.div>
    );
}

export default Certificates;
