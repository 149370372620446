import React, { useEffect } from 'react'
import backgroundTeam from '../Images/Team/teams.png'
import { useContext } from 'react'
import AnimateContext from '../Components/useContext'
import images from '../Images/index'
import Carousel from "react-elastic-carousel";
import { motion } from 'framer-motion'
import Connect from '../Components/Connect'
import Navbar2 from '../Navbar2';
import { Slide } from '@chakra-ui/react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactGA from "react-ga4";



function Teams() {
  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Teams" })
},[])
  const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];
  const breakPoints2 = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 8 },

  ];

  const NextArrow = (props) => (

    <div {...props} className='next'>

    </div>
  );

  const PrevArrow = (props) => (
    <div {...props}>

    </div>
  );

  const settings = {
    infinite: true,
    dots:true,
    speed: 6000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    slidesToShow: 6,
    slidesToScroll: 3,
    rows: 2,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 2,
          dots: false,
        },
      },
    ],

  }
  const animate = useContext(AnimateContext)
  return (
    <motion.div initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }} className='tw-font-Montserrat tw-overflow-hidden tw-mb-[10%]'>
      <Navbar2 />
      <Connect />
      <div className='tw-bg-cover tw-h-80 tw-flex tw-justify-center tw-items-center ' style={{ backgroundImage: `url(${backgroundTeam})` }}>
      </div>
      <div className='tw-text-center tw-mx-[5%] '>
        <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { duration: 0.5 } }} className='tw-my-20'>
          <h1
            // style={{ fontWeight: "600", fontSize: "xx-large" }}
            className="tw-mb-5 sm:tw-text-[34px] tw-text-[24px] tw-font-bold tw-font-Montserrat">

            Meet Our{" "}
            {
              <span className="c-green">
                <span className={` ${animate ? "logo is-animetion" : ""}`}>
                  <span>T</span>
                  <span>e</span>
                  <span>a</span>
                  <span>m</span>
                </span>
              </span>
            }
          </h1>

          <p className='tw-mx-[4%] sm:tw-text-[14px] tw-text-sm  tw-text-[#707070] tw-font-semibold'>Think of our team as a finely tuned orchestra   – each member playing their part to create a beautiful symphony of success.</p>
        </motion.div>
        <motion.div initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
            scale: {
              type: "spring",
              damping: 6,
              stiffness: 50,
              restDelta: 0.01
            }
          }}>
          <div className="tw-flex tw-flex-wrap tw-justify-center tw-items-center">
            <img src={images.Images.founder} alt="clients" className=" tw-flex tw-max-w-[300px] tw tw-max-h-[350px] tw-rounded-2xl tw-mx-20 " />
          </div>
          <div className=' tw-mt-10'><h1 className='tw-font-semibold tw-text-xl'>Mithun Chowdhary</h1>
          <p className='tw-text-lg tw-mt-5'>Founder of TARAaang Landscape</p></div>

        </motion.div>
      </div>
      <motion.div initial={{ y: -100 }} animate={{ y: 0 }}


        transition={{ type: "spring", stiffness: 100, damping: 7 }} className='tw-text-center tw-mx-[2.5%] sm:tw-mt-[10%] tw-mt-[25%]'>
        <div className=''>

          <motion.h1
          
            className="sm:tw-mb-20 tw-mb-[10%] sm:tw-text-[34px] tw-text-[24px] tw-font-bold tw-font-Montserrat">
            Our Valuable HOD's
          </motion.h1>

        </div>
        <div className=" tw-flex tw-flex-wrap tw-justify-center">
          {
            images.Images.HODs.map((image, index) => (
              <div className=' sm:tw-mx-8 tw-mx-2'>
                <div key={index} className="tw-flex tw-flex-row tw-justify-center">
                  <img src={image} alt="hods" className="tw-gap-0 tw-flex sm:tw-w-[250px] tw-w-[130px] sm:tw-h-[300px] tw-h-[175px] sm:tw-max-h-[300px] tw-rounded-[10px] tw-shadow-gray-400 tw-shadow-xl  tw-mt-8 sm:tw-mt-0" />
                </div>
                {/* <div className=' tw-ml-[-30px] tw-mt-6'>
                  <h1 className='tw-font-semibold tw-text-lg tw-py-0 sm:tw-py-3'>Alex Rodriguez</h1>
                  <p className='tw-text-sm sm:tw-pb-3 tw-pb-10'>Founder of TARaang Landscape</p>
                </div> */}
              </div>
            ))
          }
        </div>

      </motion.div>



      <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }} className='tw-text-center tw-mx-[5%]'>
        <div className='sm:tw-mt-[10%] tw-mt-[25%]'>

          <h1
            // style={{ fontWeight: "600", fontSize: "xx-large" }}
            className="sm:tw-mb-[1%] tw-mb-[10%] sm:tw-text-[34px] tw-text-[24px] tw-font-bold tw-font-Montserrat">
            Our Valuable Assistant HODs
          </h1>

        </div>
        <Carousel breakPoints={breakPoints} className='sm:tw-p-8 tw-mt-10 tw-p-1'>

          {
            images.Images.Incharge.map((image, index) => (
              <div>
                <div key={index} className="tw-flex tw-flex-row tw-mx-6 ">
                  <img src={image} alt="hods" className=" tw-flex tw-max-w-full sm:tw-w-[300px] tw-w-[200px] tw-h-[175px] sm:tw-h-[320px] tw-rounded-2xl tw-shadow-gray-400 sm:tw-shadow-xl tw-shadow-md  tw-mb-[34px]  tw-mt-3 tw-mx-2" />
                </div>

              </div>
            ))
          }

        </Carousel>
      </motion.div>


      <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }} className='tw-text-center tw-mx-[5%]'>
        <div className='sm:tw-mt-[5%] tw-mt-[25%]'>

          <h1
            // style={{ fontWeight: "600", fontSize: "xx-large" }}
            className="sm:tw-mb-[1%] tw-mb-[10%] sm:tw-text-[34px] tw-text-[24px] tw-font-bold tw-font-Montserrat">
            Our Valuable Management
          </h1>

        </div>
        <Carousel breakPoints={breakPoints} className='sm:tw-p-8 tw-mt-10 tw-p-1'>

          {
            images.Images.Management.map((image, index) => (
              <div>
                <div key={index} className="tw-flex tw-flex-row tw-mx-6">
                  <img src={image} alt="hods" className="tw-flex tw-max-w-[90%] sm:tw-w-[300px] tw-w-[200px] tw-h-[175px] sm:tw-h-[320px] tw-rounded-2xl tw-shadow-gray-400 sm:tw-shadow-xl tw-shadow-md tw-mb-[34px]  tw-mt-3 tw-mx-2" />
                </div>

              </div>
            ))
          }

        </Carousel>
      </motion.div>
   

      <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }} className='tw-text-center tw-mx-[5%]'>
        <div className='sm:tw-mt-[5%] tw-mt-[25%]'>

          <h1
            // style={{ fontWeight: "600", fontSize: "xx-large" }}
            className="sm:tw-mb-[1%] tw-mb-[10%] sm:tw-text-[34px] tw-text-[24px] tw-font-bold tw-font-Montserrat">
            Our Valuable Executives
          </h1>

        </div>
        <Carousel breakPoints={breakPoints} className='sm:tw-p-8 tw-mt-10 tw-p-1'>

          {
            images.Images.Executives.map((image, index) => (
              <div>
                <div key={index} className="tw-flex tw-flex-row tw-mx-6">
                  <img src={image} alt="hods" className="tw-flex tw-max-w-[90%] sm:tw-max-w-full sm:tw-w-[300px] tw-w-[200px] tw-h-[175px] sm:tw-h-[320px] tw-rounded-2xl tw-shadow-gray-400 sm:tw-shadow-xl tw-shadow-md tw-mb-[34px]  tw-mt-3 tw-mx-2" />
                </div>

              </div>
            ))
          }

        </Carousel>
      </motion.div>

     

      <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }} className='tw-text-center tw-mx-[5%]'>
        <div className='sm:tw-mt-[5%] tw-mt-[25%]'>

          <h1
            // style={{ fontWeight: "600", fontSize: "xx-large" }}
            className="sm:tw-mb-[1%] tw-mb-[10%] sm:tw-text-[34px] tw-text-[24px] tw-font-bold tw-font-Montserrat">
            Our Valuable Site Incharges
          </h1>

        </div>
        <Carousel breakPoints={breakPoints} className='sm:tw-p-8 tw-mt-10 tw-p-1'>

          {
            images.Images.SiteIncharge.map((image, index) => (
              <div>
                <div key={index} className="tw-flex tw-flex-row tw-mx-6 ">
                  <img src={image} alt="hods" className="tw-flex tw-max-w-full sm:tw-w-[300px] tw-w-[200px] tw-h-[175px] sm:tw-h-[320px] tw-rounded-2xl tw-shadow-gray-400 sm:tw-shadow-xl tw-shadow-md tw-mb-[34px]  tw-mt-3 tw-mx-2" />
                </div>

              </div>
            ))
          }

        </Carousel>
      </motion.div>

     

      <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }} className='tw-text-center tw-mx-[5%]'>
        <div className='sm:tw-mt-[5%] tw-mt-[25%]'>

          <h1
            // style={{ fontWeight: "600", fontSize: "xx-large" }}
            className="sm:tw-mb-[1%] tw-mb-[10%] sm:tw-text-[34px] tw-text-[24px] tw-font-bold tw-font-Montserrat">
            Our Valuable Site Supervisors
          </h1>

        </div>
        <Carousel breakPoints={breakPoints} className='sm:tw-p-8 tw-mt-10 tw-p-1'>

          {
            images.Images.Sitesup.map((image, index) => (
              <div>
                <div key={index} className="tw-flex tw-flex-row tw-mx-6">
                  <img src={image} alt="hods" className="tw-flex tw-max-w-full sm:tw-w-[300px] tw-w-[200px] tw-h-[175px] sm:tw-h-[320px] tw-rounded-2xl tw-shadow-gray-400 sm:tw-shadow-xl tw-shadow-md  tw-mb-[34px]  tw-mt-3 tw-mx-2" />
                </div>

              </div>
            ))
          }

        </Carousel>
      </motion.div>

      

      <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }} className='tw-text-center tw-mx-[5%]'>
        <div className='sm:tw-mt-[5%] tw-mt-[25%]'>

          <h1
            // style={{ fontWeight: "600", fontSize: "xx-large" }}
            className="sm:tw-mb-[1%] tw-mb-[10%] sm:tw-text-[34px] tw-text-[24px] tw-font-bold tw-font-Montserrat">
            Our Valuable Employees
          </h1>

        </div>

        <div className="tw-flex tw-flex-wrap tw-justify-center tw-items-center tw-mb-5 sm:tw-mb-[4%]">
          {/* <Carousel breakPoints={breakPoints} itemsPerRow={2}  className='sm:tw-p-20 tw-p-1'> */}
          <Slider {...settings} className='tw-w-5/6'>
            {
              images.Employees.map((image, index) => (
                <div>
                  <div key={index} className="tw-flex tw-flex-row sm:tw-mx-3 tw-mx-1">
                    <img src={image} alt="hods" className=" tw-flex tw-w-[100%] tw-h-[150px] tw-rounded sm:tw-mt-3 tw-mt-0" />
                  </div>

                </div>
              ))
            }
          </Slider>
          {/* </Carousel> */}
        </div>

      </motion.div>


    </motion.div>

  )
}

export default Teams