

const TypeOfLandscape = [
  {
    id: 0,
    name: 'Roof Top LandScaping',
    url: require("../../Images/LandScaping/Landscape/Landscapes/Rooftop Landscaping_/1.jpg"),
    images: [
      require("../../Images/LandScaping/Landscape/Landscapes/Rooftop Landscaping_/1.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Rooftop Landscaping_/3.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Rooftop Landscaping_/2.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Rooftop Landscaping_/4.png"),
      require("../../Images/LandScaping/Landscape/Landscapes/Rooftop Landscaping_/5.jpg"),
    ],
    he1: 'Roof Top',
    he2: 'Landscaping',
    Description: 'Elevate your urban oasis with our rooftop landscaping services, transforming unused rooftop spaces into vibrant gardens and relaxing retreats, adding greenery and beauty while reducing heat island effect and enhancing environmental sustainability.',
    Fact: 'Maximize your rooftop space with our rooftop landscaping services, creating green havens that provide insulation, improve air quality, and reduce energy costs while offering residents and tenants a serene outdoor escape amidst the urban landscape.',
  },
  {
    id: 1,
    name: 'Corner Landscaping',
    url: require("../../Images/LandScaping/Landscape/Landscapes/Corner Landscaping_/3.jpg"),
    images: [
      require("../../Images/LandScaping/Landscape/Landscapes/Corner Landscaping_/3.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Corner Landscaping_/1.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Corner Landscaping_/2.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Corner Landscaping_/4.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Corner Landscaping_/5.jpg"),
    ],
    he1: 'Corner',
    he2: 'Landscaping',
    Description: 'Transform neglected corners into focal points of beauty and charm with our corner landscaping services, expertly designed to maximize space, enhance curb appeal, and add visual interest to every corner of your property.',
    Fact: 'Make the most of every inch of your outdoor space with our corner landscaping services, strategically designed to optimize aesthetics and functionality, creating inviting nooks for relaxation, accentuating architectural features, and maximizing the overall beauty and value of your property.',

  },
  {
    id: 2,
    name: 'Open Landscaping',
    url: require("../../Images/LandScaping/Landscape/Landscapes/Open Landscaping_/6.jpg"),
    images: [
      require("../../Images/LandScaping/Landscape/Landscapes/Open Landscaping_/6.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Open Landscaping_/4.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Open Landscaping_/5.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Open Landscaping_/3.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Open Landscaping_/2.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Open Landscaping_/1.png"),
    ],
    he1: 'Open',
    he2: 'Landscaping',
    Description: 'Unleash the full potential of your open spaces with our open landscaping services, tailored to enhance natural beauty, promote biodiversity, and create versatile outdoor environments for recreation, relaxation, and enjoyment year-round.',
    Fact: 'Open landscaping is essential for maximizing the potential of large outdoor areas, offering opportunities for diverse planting schemes, recreational activities, and environmental benefits such as soil erosion control and habitat creation, creating inviting and functional spaces for communities to gather, connect with nature, and enjoy outdoor activities.',

  },
  {
    id: 3,
    name: 'Shaded LandScaping',
    url: require("../../Images/LandScaping/Landscape/Landscapes/Shaded Landscaping/3.jpg"),
    images: [
      require("../../Images/LandScaping/Landscape/Landscapes/Shaded Landscaping/3.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Shaded Landscaping/4.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Shaded Landscaping/2.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Shaded Landscaping/1.jpg"),
    ],
    he1: 'Shaded',
    he2: 'LandScaping',
    Description: 'Transform shaded areas into lush retreats with our shaded landscaping services, expertly designed to thrive in low-light conditions and create serene, verdant spaces for relaxation and enjoyment.',
    Fact: 'Shaded landscaping is essential for enhancing the beauty and functionality of shaded areas, providing opportunities to cultivate shade-loving plants, create cool and comfortable outdoor living spaces, and mitigate the effects of heat and glare, ensuring year-round enjoyment and maximizing the potential of every corner of your property.',
  },
  {
    id: 4,
    name: 'Garden Pathway',
    url: require("../../Images/LandScaping/Landscape/Landscapes/Garden Pathways/1.jpg"),
    images: [
      require("../../Images/LandScaping/Landscape/Landscapes/Garden Pathways/1.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Garden Pathways/5.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Garden Pathways/2.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Garden Pathways/4.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Garden Pathways/3.jpg"),
    ],
    he1: 'Garden',
    he2: 'Pathway',
    Description: 'Guide your journey through nature with our garden pathway design services, crafting enchanting routes that wind through lush landscapes, connecting different areas of your garden while adding charm, functionality, and a sense of discovery to your outdoor space.',
    Fact: "Constructing a garden pathway enhances accessibility, improves navigation, and adds visual interest to your outdoor space, creating a welcoming atmosphere and encouraging exploration and enjoyment of your garden's beauty and tranquility. Additionally, pathways help protect delicate plants by providing designated walking areas, minimizing foot traffic on sensitive vegetation.",
  },
  {
    id: 5,
    name: 'Lawn',
    url: require("../../Images/LandScaping/Landscape/Landscapes/Lawn/2.jpg"),
    images: [
      require("../../Images/LandScaping/Landscape/Landscapes/Lawn/2.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Lawn/1.jpeg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Lawn/3.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Lawn/4.jpeg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Lawn/5.jpg"),
    ],
    he1: 'Lawn',
    he2: '',
    Description: 'Create a lush carpet of green with our lawn installation and maintenance services, transforming your outdoor space into a vibrant oasis for relaxation, recreation, and enjoying the beauty of nature.',
    Fact: "Transform your backyard into a luxurious retreat with our premium lawn installation services. Experience the unparalleled beauty and comfort of a lush, green lawn, perfect for outdoor entertaining, family gatherings, and everyday relaxation. Boost your home's curb appeal and elevate your outdoor living experience with a professionally installed lawn from our expert team.",
  },
  {
    id: 6,
    name: 'Water Lily Pool',
    url: require("../../Images/LandScaping/Landscape/Landscapes/Water Lily Pool/3.jpg"),
    images: [
      require("../../Images/LandScaping/Landscape/Landscapes/Water Lily Pool/3.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Water Lily Pool/2.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Water Lily Pool/1.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Water Lily Pool/5.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Water Lily Pool/4.jpg"),
    ],
    he1: 'Water Lily',
    he2: 'Pool',
    Description: 'Transform your outdoor space into a serene oasis with our enchanting Water Lily Pool. Immerse yourself in the tranquility of gently floating blooms, adding elegance and natural beauty to your garden or patio.',
    Fact: 'Constructing a Water Lily Pool adds a touch of elegance and tranquility to your outdoor space, creating a captivating focal point that enhances relaxation and promotes a sense of serenity. With their stunning blooms and graceful presence, water lilies provide visual interest, attract beneficial wildlife, and help maintain a healthy aquatic ecosystem in your garden or landscape design.',
  },
  {
    id: 7,
    name: 'Pebbles & Stone Work',
    url: require("../../Images/LandScaping/Landscape/Landscapes/Pebbles & Stone Work/6.jpg"),
    images: [
      require("../../Images/LandScaping/Landscape/Landscapes/Pebbles & Stone Work/6.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Pebbles & Stone Work/5.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Pebbles & Stone Work/3.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Pebbles & Stone Work/4.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Pebbles & Stone Work/1.jpg"),
      require("../../Images/LandScaping/Landscape/Landscapes/Pebbles & Stone Work/2.jpg"),
    ],
    he1: 'Pebbles &',
    he2: ' Stone Work',
    Description: 'Elevate your landscape with our exquisite pebbles and stone work, adding texture, depth, and natural beauty to your outdoor spaces with expertly crafted pathways, borders, and accents.',
    Fact: 'Pebbles and stone work add a touch of sophistication and natural charm to your landscape, enhancing visual appeal and creating a harmonious balance between hardscape and softscape elements. Additionally, their durable and low-maintenance qualities make them an ideal choice for pathways, borders, and accents, ensuring long-lasting beauty and functionality in your outdoor space.',
  },

],
  TypeOfPlantCaringHouse = [
    {
      id: 20,
      name: 'Green House',
      url: require("../../Images/LandScaping/Landscape/Plant Caring Houses/Green House/2.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Green House/2.jpg"),
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Green House/1.jpg"),
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Green House/3.png"),
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Green House/4.jpg"),
      ],
      he1: 'Green',
      he2: 'House',
      Description: 'Create the perfect environment for year-round gardening with our state-of-the-art Green House structures, providing optimal conditions for cultivating a wide variety of plants and extending your growing season.',
      Fact: 'A Green House offers controlled conditions for gardening, allowing you to grow plants year-round regardless of external weather conditions, maximizing productivity and extending your growing season. Additionally, it provides protection from pests and diseases, ensuring healthier and more bountiful harvests for your garden.',
    },
    {
      id: 21,
      name: 'Polly House',
      url: require("../../Images/LandScaping/Landscape/Plant Caring Houses/Poly House/2.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Poly House/2.jpg"),
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Poly House/3.jpeg"),
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Poly House/1.jpg"),
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Poly House/4.jpg"),
      ],
      he1: 'Polly',
      he2: 'House',
      Description: 'Transform your gardening experience with our innovative Polly House structures, providing a cost-effective and efficient solution for extending your growing season and protecting delicate plants from harsh weather conditions.',
      Fact: 'A Polly House offers a versatile and customizable environment for gardening, allowing you to control temperature, humidity, and light levels to optimize plant growth and yield. With its lightweight and durable design, it provides protection from pests, diseases, and adverse weather, ensuring a successful and fruitful gardening experience year-round.',
    },
    {
      id: 22,
      name: 'Green Shades Grafting',
      url: require("../../Images/LandScaping/Landscape/Plant Caring Houses/Green Shaded Grafting/3.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Green Shaded Grafting/3.jpg"),
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Green Shaded Grafting/2.jpeg"),
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Green Shaded Grafting/1.jpg"),
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Green Shaded Grafting/4.jpg"),
      ],
      he1: 'Green Shades',
      he2: 'Grafting',
      Description: "Enhance your outdoor space with our Green Shades Grafting services, expertly crafting natural shade solutions that blend seamlessly with your landscape while providing relief from the sun's harsh rays.",
      Fact: 'Green Shades Grafting offers an eco-friendly and aesthetically pleasing solution for creating comfortable outdoor spaces, reducing heat and glare while enhancing the beauty and functionality of your landscape. By incorporating natural shade structures, you can enjoy cooler temperatures, protect sensitive plants, and create inviting areas for relaxation and entertainment in your garden or outdoor living space.',
    },
    {
      id: 23,
      name: 'Compost Pit',
      url: require("../../Images/LandScaping/Landscape/Plant Caring Houses/Compost Pit/3.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Compost Pit/3.jpg"),
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Compost Pit/1.jpg"),
        require("../../Images/LandScaping/Landscape/Plant Caring Houses/Compost Pit/2.jpg"),
      ],
      he1: 'Compost',
      he2: 'Pit',
      Description: 'Transform your organic waste into nutrient-rich compost with our convenient Compost Pit, providing an eco-friendly solution for recycling kitchen scraps, yard trimmings, and other biodegradable materials into valuable soil amendments for your garden.',
      Fact: 'A Compost Pit is an essential tool for sustainable gardening, allowing you to reduce waste, enrich your soil, and promote healthier plant growth without the need for chemical fertilizers. By recycling organic materials into nutrient-rich compost, you can improve soil structure, retain moisture, and foster a thriving ecosystem in your garden, resulting in more abundant harvests and vibrant, resilient plants.',
    },
  ],
  TypeOfPlayGroundLandscape = [
    {
      id: 30,
      name: 'Children Playing Zone',
      url:require('../../Images/LandScaping/Landscape/Landscapes/Children playing Zone/kids-play-areas-landscaping-new-jersey-2.jpg'),
      images: [
        require('../../Images/LandScaping/Landscape/Landscapes/Children playing Zone/kids-play-areas-landscaping-new-jersey-2.jpg'),
        require("../../Images/LandScaping/children play zone.png"),
        require('../../Images/LandScaping/Landscape/Landscapes/Children playing Zone/MREC_2017_CA_GrapeDay_0153-Edit.jpg'),
        require('../../Images/LandScaping/Landscape/Landscapes/Children playing Zone/image.jpg'),
       
      ],
      he1: 'Children',
      he2: 'Playing Zone',
      Description: "Create endless fun and memories with our Children's Playing Zone, a safe and engaging outdoor space designed to spark imagination, promote physical activity, and provide hours of entertainment for kids of all ages.",
      Fact: "Building a Children's Playing Zone provides a dedicated space for kids to play and explore, fostering physical fitness, social interaction, and cognitive development in a safe and supervised environment. By encouraging outdoor play, you promote healthy lifestyles and create opportunities for family bonding and memorable experiences that last a lifetime.",
    },
    {
      id: 31,
      name: 'Badminton Court',
      url: require("../../Images/LandScaping/badmiton court.png"),
      images: [
        require("../../Images/LandScaping/badmiton court.png"),
        require('../../Images/LandScaping/Landscape/Landscapes/Badminton court/barac-badminton-open-9.jpg'),
        require('../../Images/LandScaping/Landscape/Landscapes/Badminton court/unnamed (1).jpg'),
        require('../../Images/LandScaping/Landscape/Landscapes/Badminton court/unnamed.jpg'),
      ],
      he1: 'Badminton',
      he2: 'Court',
      Description: 'Elevate your outdoor recreation with our professional-grade Badminton Court, offering a dedicated space for players of all skill levels to enjoy exhilarating matches and friendly competition in the comfort of their own backyard.',
      Fact: 'A Badminton Court provides a versatile and accessible space for individuals and families to engage in physical activity, improve coordination, and enhance cardiovascular health through the sport of badminton. Whether for leisurely play or competitive matches, having a dedicated court ensures a safe and enjoyable experience, promoting active lifestyles and fostering a sense of camaraderie among players.',
    },
    {
      id: 32,
      name: 'Basket Ball Court',
      url: require("../../Images/LandScaping/basket ball court.png"),
      images: [
        require("../../Images/LandScaping/basket ball court.png"),
        require('../../Images/LandScaping/Landscape/Landscapes/Basket ball court/Backyard-Basketball-Court-Sport-Court-2-e1537544284822-960x476.jpg'),
        require('../../Images/LandScaping/Landscape/Landscapes/Basket ball court/Basketball.jpg'),
        require('../../Images/LandScaping/Landscape/Landscapes/Basket ball court/fd7497ccb2eff08d57458884c1183663.jpg'),
      ],
      he1: 'Basket Ball',
      he2: 'Court',
      Description: 'Experience the thrill of the game with our premium Basketball Court, offering a professionally designed space for players to shoot hoops, practice their skills, and enjoy spirited games with friends and family.',
      Fact: 'A Basketball Court provides a designated area for players to engage in the sport of basketball, promoting physical fitness, teamwork, and friendly competition. Whether for recreational play or organized games, having a dedicated court allows individuals of all ages to hone their skills, stay active, and enjoy the exhilarating experience of shooting hoops and making slam dunks.',
    },
    {
      id: 33,
      name: 'Cricket Pitch',
      url: require('../../Images/LandScaping/Landscape/Landscapes/Criket Pitch/IMG_1362-1.jpg'),
      images: [
        require('../../Images/LandScaping/Landscape/Landscapes/Criket Pitch/IMG_1362-1.jpg'),
        require('../../Images/LandScaping/Landscape/Landscapes/Criket Pitch/CRICKET-SYNTHETIC-TURF-e1618189569375.jpg'),
        require("../../Images/LandScaping/criket pitch.png"),
        require('../../Images/LandScaping/Landscape/Landscapes/Criket Pitch/fitflex-outdoor-synthetic-cricket-pitch-500x500.webp'),
      ],
      he1: 'Cricket',
      he2: 'Pitch',
      Description: 'Elevate your cricketing experience with our meticulously crafted Cricket Pitch, offering a professional-grade playing surface for enthusiasts to enjoy thrilling matches, practice their skills, and immerse themselves in the excitement of the game.',
      Fact: 'A Cricket Pitch provides a dedicated playing area for cricket enthusiasts to engage in the beloved sport, allowing players to practice batting, bowling, and fielding techniques while enjoying competitive matches and friendly games with friends and teammates. With its carefully maintained surface and regulation dimensions, a Cricket Pitch ensures an authentic and enjoyable cricketing experience, fostering camaraderie, skill development, and a love for the game.',
    },
  ],
  TypeOfLandscapesBorder = [
    {
      id: 40,
      name: 'Hedge Border',
      url: require("../../Images/LandScaping/Landscape/Landscape_s Borders/Hedge Border/4.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Hedge Border/4.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Hedge Border/3.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Hedge Border/1.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Hedge Border/2.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Hedge Border/5.jpg"),
      ],
      he1: 'Hedge',
      he2: 'Border',
      Description: 'Border hedges create beautiful lines and curves in the home landscape. They define an area and add an upscale look. Border hedges can be sculpted to your liking. They can be rounded or angled, whatever fits your personality and landscape best.',
      Fact: 'With the help of plants, live hedges can be formed and used as a fence or a green wall. It serves to screen a particular site or building or hiding of unwanted places. They help to partition the garden into several parts. It provides a natural background to a garden, like a frame to a picture.',
    },
    {
      id: 41,
      name: 'Shape Border',
      url: require("../../Images/LandScaping/Landscape/Landscape_s Borders/Shrub Border/3.png"),
      images: [
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Shrub Border/3.png"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Shrub Border/5.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Shrub Border/4.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Shrub Border/1.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Shrub Border/2.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Shrub Border/6.jpg"),
      ],
      he1: 'Shape',
      he2: 'Border',
      Description: "A shape border is a decorative planting scheme featuring a diverse array of shapes arranged along a garden's perimeter or as a backdrop, adding structure, texture, and seasonal interest to the landscape while providing privacy and habitat for wildlife.",
      Fact: 'A shape border is essential for gardeners seeking to create a visually appealing and low-maintenance landscape, as it offers year-round interest with its varied foliage, flowers, and seasonal changes. With its dense growth habit and versatility, a shape border serves as a natural boundary, windbreak, and habitat for birds and beneficial insects, enhancing the beauty and ecological value of the garden while providing privacy and structure to outdoor spaces.',
    },
    {
      id: 42,
      name: 'Herbaceous Border',
      url: require("../../Images/LandScaping/Landscape/Landscape_s Borders/Herbaceous Border/2.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Herbaceous Border/2.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Herbaceous Border/3.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Herbaceous Border/4.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Herbaceous Border/1.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Herbaceous Border/5.jpg"),
      ],
      he1: 'Herbaceous',
      he2: 'Border',
      Description: "Enhance your garden's beauty and diversity with our Herbaceous Border, a vibrant and dynamic planting scheme featuring a stunning array of flowering perennials, foliage plants, and herbs that provide year-round interest and color.",
      Fact: 'A Herbaceous Border serves as a striking focal point in gardens, offering a rich tapestry of colors, textures, and fragrances that attract pollinators, enhance biodiversity, and create visual interest throughout the seasons. By incorporating a diverse range of plants, including flowering perennials, ornamental grasses, and culinary herbs, Herbaceous Borders provide beauty, structure, and sustainability to garden landscapes, while also providing valuable habitat for beneficial insects and wildlife.',
    },
    {
      id: 43,
      name: 'Rose Bed',
      url: require("../../Images/LandScaping/Landscape/Landscape_s Borders/Rose Bed/3.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Rose Bed/3.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Rose Bed/4.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Rose Bed/1.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Rose Bed/2.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Rose Bed/5.jpg"),
      ],
      he1: 'Rose',
      he2: 'Bed',
      Description: 'Transform your garden into a fragrant paradise with our exquisite Rose Bed, showcasing a captivating display of colorful and aromatic rose varieties that add elegance and charm to any landscape.',
      Fact: 'A Rose Bed serves as a romantic and timeless feature in gardens, providing a focal point of beauty and fragrance while offering a haven for pollinators and beneficial insects. With its diverse selection of rose varieties, it offers year-round interest, enhancing the aesthetics of the landscape and creating a peaceful retreat for relaxation and enjoyment.',
    },
    {
      id: 44,
      name: 'Flowering Bed',
      url: require("../../Images/LandScaping/Landscape/Landscape_s Borders/Flowering Bed/3.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Flowering Bed/3.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Flowering Bed/4.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Flowering Bed/1.jpg"),
        require("../../Images/LandScaping/Landscape/Landscape_s Borders/Flowering Bed/2.jpg"),
      ],
      he1: 'Flowering',
      he2: 'Bed',
      Description: 'A flowering bed is a designated area within a garden or landscape where an assortment of flowering plants are cultivated to create a vibrant and visually striking display of color and texture throughout the growing season.',
      Fact: 'A flowering bed is essential for gardeners seeking to add beauty and interest to their landscape, providing a focal point and enhancing the aesthetic appeal of outdoor spaces. With its diverse selection of flowering plants, a well-designed flowering bed offers continuous blooms, attracting pollinators and creating a dynamic and inviting environment for relaxation and enjoyment.',
    },

  ],
  TypeOfGardeningLandscape = [
    {
      id: 50,
      name: 'Pot Gardening',
      url: require("../../Images/LandScaping/Landscape/Gardening Landscapes/Pot Gardening_/3.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Pot Gardening_/3.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Pot Gardening_/2.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Pot Gardening_/4.jpeg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Pot Gardening_/6.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Pot Gardening_/1.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Pot Gardening_/5.jpg"),
      ],
      he1: 'Pot',
      he2: 'Gardening',
      Description: 'Elevate your gardening experience with Pot Gardening, a versatile and space-saving method that allows you to cultivate a beautiful and diverse array of plants in containers, adding color, texture, and greenery to any outdoor or indoor space.',
      Fact: 'Pot Gardening offers flexibility and convenience for gardeners with limited space or mobility, allowing them to grow plants on balconies, patios, or even indoors. By using containers of various sizes and styles, Pot Gardening enables individuals to create customized garden displays, experiment with different plant combinations, and easily move plants to optimize sunlight and growing conditions, enhancing the beauty and enjoyment of their living environment.',
    },
    {
      id: 51,
      name: 'Balcony Gardening',
      url: require("../../Images/LandScaping/Landscape/Gardening Landscapes/Balcony Gardening_/4.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Balcony Gardening_/4.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Balcony Gardening_/1.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Balcony Gardening_/2.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Balcony Gardening_/3.jpg"),
      ],
      he1: 'Balcony',
      he2: 'Gardening',
      Description: "Transform your balcony into a lush oasis with Balcony Gardening, a creative and space-efficient way to cultivate plants, flowers, and herbs, bringing nature's beauty and tranquility to urban living spaces.",
      Fact: 'Balcony Gardening offers city dwellers a green escape from urban life, providing opportunities to grow plants, herbs, and vegetables in limited outdoor spaces. By maximizing vertical and horizontal space with containers and hanging baskets, Balcony Gardening allows individuals to enjoy gardening, connect with nature, and create personalized retreats right outside their doorstep.',
    },
    {
      id: 52,
      name: 'Rock Gardening',
      url: require("../../Images/LandScaping/Landscape/Gardening Landscapes/Rock Gardening_/4.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Rock Gardening_/4.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Rock Gardening_/3.jpeg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Rock Gardening_/1.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Rock Gardening_/2.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Rock Gardening_/5.jpg"),
      ],
      he1: 'Rock',
      he2: 'Gardening',
      Description: 'Elevate your landscape with Rock Gardening, a striking and low-maintenance landscaping technique that incorporates rocks and alpine plants to create visually appealing and naturalistic garden features.',
      Fact: 'Rock Gardening is ideal for gardens with poor soil or challenging terrain, offering a solution to grow plants in rocky or sloped areas where traditional landscaping may be difficult. By utilizing rocks and drought-tolerant plants, Rock Gardening adds texture, structure, and visual interest to the landscape while requiring minimal maintenance, making it perfect for water-wise and low-maintenance garden designs.',
    },
    {
      id: 53,
      name: 'Kitchen Garden',
      url: require("../../Images/LandScaping/Landscape/Gardening Landscapes/Kitchen Gardening_/2.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Kitchen Gardening_/2.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Kitchen Gardening_/4.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Kitchen Gardening_/3.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Kitchen Gardening_/1.jpg"),

      ],
      he1: 'Kitchen',
      he2: 'Garden',
      Description: 'Cultivate fresh flavors and ingredients right at home with a Kitchen Garden, a productive and convenient way to grow herbs, vegetables, and fruits for culinary use, ensuring a constant supply of organic and flavorful produce just steps away from the kitchen.',
      Fact: 'A Kitchen Garden provides a sustainable and cost-effective solution for individuals seeking to incorporate fresh, healthy ingredients into their daily cooking while reducing reliance on store-bought produce. By growing herbs, vegetables, and fruits in close proximity to the kitchen, it offers convenience, accessibility, and the satisfaction of harvesting homegrown produce for delicious and nutritious meals year-round.',
    },
    {
      id: 54,
      name: 'Rooftop Gardening',
      url: require("../../Images/LandScaping/Landscape/Gardening Landscapes/Rooftop Gardening_/4.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Rooftop Gardening_/4.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Rooftop Gardening_/2.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Rooftop Gardening_/3.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Rooftop Gardening_/1.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Rooftop Gardening_/5.jpg"),
      ],
      he1: 'Rooftop',
      he2: 'Gardening',
      Description: 'Transform your rooftop into a green oasis with Rooftop Gardening, a space-efficient and sustainable method to create vibrant gardens, enhance urban landscapes, and mitigate environmental impact while maximizing the use of underutilized space.',
      Fact: 'Rooftop Gardening offers city dwellers an opportunity to reconnect with nature, improve air quality, and reduce urban heat island effects by incorporating greenery into densely populated areas. It provides a versatile solution for cultivating plants, vegetables, and even small trees, creating inviting outdoor spaces, promoting biodiversity, and enhancing the overall quality of urban living environments.',
    },
    {
      id: 55,
      name: 'Boundary Gardening',
      url: require("../../Images/LandScaping/Landscape/Gardening Landscapes/Boundary Gardening_/1.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Boundary Gardening_/1.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Boundary Gardening_/2.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Boundary Gardening_/4.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Boundary Gardening_/5.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Boundary Gardening_/3.jpg"),
      ],
      he1: 'Boundary',
      he2: 'Gardening',
      Description: 'Define and beautify your property with Boundary Gardening, a landscaping practice focused on creating attractive borders using a variety of plants, shrubs, and trees to enhance privacy, security, and curb appeal.',
      Fact: 'Boundary Gardening serves as a natural barrier, delineating property lines while adding aesthetic value and enhancing the overall landscape. By incorporating a mix of foliage, flowering plants, and evergreens, it creates a visually pleasing transition between neighboring properties, promotes biodiversity, and provides habitat for wildlife, contributing to a harmonious and inviting outdoor environment.',
    },
    {
      id: 56,
      name: 'Artificial Turfing',
      url: require("../../Images/LandScaping/Landscape/Gardening Landscapes/Artificial Turfing_/4.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Artificial Turfing_/4.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Artificial Turfing_/1.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Artificial Turfing_/2.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Artificial Turfing_/3.jpg"),
      ],
      he1: 'Artificial',
      he2: 'Turfing',
      Description: 'Upgrade your outdoor space with Artificial Turfing, a low-maintenance and eco-friendly alternative to natural grass, offering year-round greenery, durability, and hassle-free landscaping solutions for residential and commercial properties.',
      Fact: 'Artificial Turfing provides a practical solution for areas with challenging growing conditions or high foot traffic, ensuring a lush and green lawn without the need for mowing, watering, or chemical treatments. Ideal for urban landscapes, sports fields, and pet-friendly environments, it offers a durable and aesthetic surface that remains vibrant and inviting throughout the seasons, enhancing outdoor spaces and reducing maintenance costs and water usage.',
    },
    {
      id: 57,
      name: 'Vertical Gardening',
      url: require("../../Images/LandScaping/Landscape/Gardening Landscapes/Vertical Gardening_/2.jpg"),
      images: [
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Vertical Gardening_/2.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Vertical Gardening_/5.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Vertical Gardening_/1.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Vertical Gardening_/3.jpg"),
        require("../../Images/LandScaping/Landscape/Gardening Landscapes/Vertical Gardening_/4.jpg"),
      ],
      he1: 'Vertical',
      he2: 'Gardening',
      Description: 'Elevate your gardening experience with Vertical Gardening, a space-saving and innovative approach to cultivate plants, herbs, and flowers vertically on walls, fences, or structures, maximizing growing space and adding visual interest to any indoor or outdoor environment.',
      Fact: 'Vertical Gardening is essential for urban environments and small gardens, providing an efficient solution to grow plants in limited spaces, balconies, or rooftops. By utilizing vertical surfaces, it optimizes growing area, improves air quality, and adds greenery to urban landscapes, fostering biodiversity, and enhancing aesthetic appeal while promoting sustainability and resource conservation.',
    },
  ]


export default { TypeOfLandscape, TypeOfPlantCaringHouse, TypeOfPlayGroundLandscape, TypeOfLandscapesBorder, TypeOfGardeningLandscape };
