import React, { useEffect, useRef, useState } from "react";
import tree from "../Images/tree1.png";
import shadow from "../Images/shadow.png";
import backgroundImage from "../Images/Rectangle_6.png";
import backgroundTeam from "../Images/Team/teams.png";
import plant from "../Images/potted-plant.png";
import cardimg1 from "../Images/card-img-1.png";
import cardimg2 from "../Images/card-img-2.png";
import cardimg3 from "../Images/card-img-3.png";
import Frame1 from "../Components/frame1";
import Frame2 from "../Components/frame2";
import Frame3 from "../Components/frame3";
import Frame4 from "../Components/frame4";
import Frame5 from "../Components/frame5";
import Frame6 from "../Components/frame6";
import Frame7 from "../Components/frame7";
import Image1 from "../Components/image1";
import Image2 from "../Components/image2";
import Image3 from "../Components/image3";
import Image4 from "../Components/image4";
import Image5 from "../Components/image5";
import Image6 from "../Components/image6";
import Image7 from "../Components/image7";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image } from "react-bootstrap";
import reviewpic1 from "../Images/review_pic1.png";
import reviewpic2 from "../Images/review_pic2.png";
import reviewpic3 from "../Images/review_pic3.png";
import reviewpic4 from "../Images/review_pic4.png";
import star from "../Images/star.png";
import Carousel from "react-elastic-carousel";
import arrow_r from "../Images/arrow_r.png";
import partner1 from "../Images/partners/partner1.png";
import partner2 from "../Images/partners/partner2.png";
import partner3 from "../Images/partners/partner3.png";
import partner4 from "../Images/partners/partner4.png";
import partner5 from "../Images/partners/partner5.png";
import partner6 from "../Images/partners/partner6.png";
import partner8 from "../Images/partners/partner7.png";
import partner9 from "../Images/partners/partner9.png";
import partner10 from "../Images/partners/partner10.png";
import partner11 from "../Images/partners/partner11.png";
import partner12 from "../Images/partners/partner12.png";
import partner13 from "../Images/partners/partner13.png";
import partner14 from "../Images/partners/partner14.png";
import partner15 from "../Images/partners/partner15.png";
import partner16 from "../Images/partners/partner16.png";
import partner17 from "../Images/partners/partner17.png";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import Connect from "../Components/Connect";
import Navbar from "../Navbar";
import { useNavigate, Link } from "react-router-dom";
import Navbar2 from "../Navbar2";
import ReactGA from "react-ga4";

const partners = [
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  partner6,
  partner1,
  partner3,
  partner5,
  partner3,
  partner8,
  partner9,
  partner10,
  partner11,
  partner12,
  partner13,
  partner14,
  partner15,
  partner16,
  partner17,
];

const reviewsData = [
  {
    id: 1,
    initials: "B",
    name: "Bibek Roy",
    image: reviewpic4,
    date: "February 15, 2024",
    text: "Best landscape services in Kolkata & their team is extraordinary they do in cheap price, best of luck to team taraaang.realy they are best,my loved to taraaang.also love to owner Mr Mithun chowdhary.",
    rating: 5,
    color: "#04dbd4",
  },
  {
    id: 2,
    initials: "J",
    name:`Joyjeet Sarkar`,
    image: reviewpic4,
    date: "a month ago",
    text: `Best landscapes ever
    Low cost maintanance wow
    Word of mouth is really impressive`,
    rating: 5,
    color: "gray",
  },
  {
    id: 3,
    initials: "P",
    name:`Piyas Biswas`,
    image: reviewpic4,
    date: "a month ago",
    text: `Best landscapes ever
    Low cost maintanance wow
    Word of mouth is really impressive`,
    rating: 5,
    color: "brown",
  },
  {
    id: 4,
    initials: "L",
    name: "Lob Biswas",
    image: reviewpic2,
    date: "4 months ago",
    text: "I just completed landscape work at my home, best service I ever I seen. Taraaang team work is extraordinary and owner behavior is remarkable.They doing service in reasonable price.Best of luck to team Taraaang",
    rating: 5,
    color: "#0feb07",
  },
  {
    id: 5,
    initials: "M",
    name: `Mukesh Choudhary`,
    image: reviewpic4,
    date: "5 months ago",
    text: `Taraaang team r extraordinary... they doing their good service to all...I also received good service from them... their home delivery services is great.i like taraaang team & love to owner mr Mithun chowdhary who providing good service to all`,
    rating: 5,
    color: "purple",
  },
  {
    id: 6,
    initials: "K",
    name: "Keya Acharjee",
    image: reviewpic1,
    date: "a year ago",
    text: "Visited here after looking for nurseries in Google map. Wide range of indoor and outdoor plants in a reasonable price. Also the behaviour of the Shopkeeper Didi was really polite. Though I live some away but will love to visit again.",
    rating: 5,
    color: "#8d0eed",
  },
  {
    id: 7,
    initials: "S",
    name:`Shaily Pandey`,
    image: reviewpic4,
    date: "a years ago",
    text: `The quality of plants and pots were good and delivered at door steps. Would like to purchase more from the Taraaang Nursery in future.`,
    rating: 5,
    color: "black",
  },
  {
    id: 8,
    initials: "J",
    name: "Jaki Balmiki",
    image: reviewpic3,
    date: "2 years ago",
    text: "Very affordable team at Kolkata, they are providing good landscape gardening, their expert gardener's team are awesome, best of luck to the owner mr. Mithun Chowdhury & their team management.their commitments is remarkable. ",
    rating: 5,
    color: "#071aeb",
  },

  {
    id: 9,
    initials: "A",
    name: "Aman Kumar",
    image: reviewpic4,
    date: "2 years ago",
    text: `Good quality product and services provided by good staff and team appreciated.
    Very polite and supporting team who delivered my plants effective and efficiently 
       Thanks once again Taarang Landscape.`,
    rating: 5,
    color: "orange",
  },
  {
    id: 10,
    initials: "J",
    name: `Jaki Balmiki`,
    image: reviewpic4,
    date: "2 years ago",
    text: "Very affordable team at Kolkata, they are providing good landscape gardening, their expert gardener's team are awesome, they are providing maintenance services at reasonable prices best of luck to the owner mr. Mithun Chowdhury & their team management.",
    rating: 5,
    color: "lightgray",
  },
  {
    id: 11,
    initials: "C",
    name: `Chaitali Mullick`,
    image: reviewpic4,
    date: "3 years ago",
    text: "Plants are like my child. And I am very serious about their maintainance. I am very impressed with Taraaang & the quality they have provided. Service is also mindblowing. I had ordered some plants & i got all of them at my doorstep just next day.",
    rating: 5,
    color: "lightgreen",
  },
  {
    id: 12,
    initials: "A",
    name: "Avijit Saha",
    image: reviewpic4,
    date: "3 years ago",
    text: "Very good service and their commitment is absolutely perfect. I am totally  satisfied with their service. Recently they completed our rooftop garden and it is really appreciable. Hope they will keep their commitment in future also.",
    rating: 5,
    color: "#eb075b",
  },
  {
    id: 13,
    initials: "A",
    name: `Aparupa Chakraborty`,
    image: reviewpic4,
    date: "3 years ago",
    text: `This organization is extremely cooperating!
    Whenever I ask for any help they guide us unconditionally!
    They provide good quality of plants! 🌱
    I'll highly recommend this Organization!`,
    rating: 5,
    color: "deeppink",
  },

  {
    id: 14,
    initials: "S",
    name:`Sonu Balmiki`,
    image: reviewpic4,
    date: "3 years ago",
    text: `Best garden service company in West bengal.they give service in lalgola.there team is full experience.concept is mind blowing.home delivery and digital payment accept.`,
    rating: 5,
    color: "red",
  },
  {
    id: 15,
    initials: "S",
    name:`Sumitra Mandal`,
    image: reviewpic4,
    date: "3 years ago",
    text: `I m purchased first time from Taraaang Landscape Nursery and my experience was beyond my expectation. Their service was too good. Highly recommend.`,
    rating: 5,
    color: "skyblue",
  },
  {
    id: 16,
    initials: "K",
    name:`Kalyan Upadhaya`,
    image: reviewpic4,
    date: "4 years ago",
    text: `Best nursery at Barrackpore , good behaviour of the owner of nursery... good service provide here.. experienced gardener available....`,
    rating: 5,
    color: "green",
  },

  // Add more reviews as needed
];
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];
const breakPointsPartner = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 6 },
  { width: 1280, itemsToShow: 6 },
];
const components = [
  <Frame1 key="frame1" />,
  <Frame2 key="frame2" />,
  <Frame3 key="frame3" />,
  <Frame4 key="frame4" />,
  <Frame5 key="frame5" />,
  <Frame6 key="frame6" />,
  <Frame7 key="Frame7" />,
];
const imageComponents = [
  <Image1 />,
  <Image2 />,
  <Image3 />,
  <Image4 />,
  <Image5 />,
  <Image6 />,
  <Image7 />,
];

const StarRating = ({ rating }) => {
  const starArray = Array.from({ length: rating }, (_, index) => index + 1);

  return (
    <div className="tw-flex">
      {starArray.map((index) => (
        <Image
          key={index}
          src={star}
          alt={`Star ${index}`}
          style={{ margin: "5px" }}
        />
      ))}
    </div>
  );
};

const CarouselIndicater = ({ index, onClick }) => {
  return (
    <span className="d-flex">
      {Array.from({ length: 7 }).map((_, i) => (
        <div
        // className="mx-1"
        // key={i}
        // style={{
        //   height: "7px",
        //   borderRadius: "10px",
        //   backgroundColor: i === index ? "#86241D" : "lightgray",
        //   width: i === index ? "30px" : "7px",
        //   transition: "all 0.5s",
        // }}
        // onClick={() => onClick(i)}
        ></div>
      ))}
    </span>
  );
};

function Home() {
  const [index, setIndex] = useState(0);
  const [animate, setAnimate] = useState(true);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  const navigate = useNavigate();
  const handleclick2 = () => {
    navigate("/ourWorks");
  };
  const imageSetting = {
    beforeChange: (_, next) => {
      setIndex(next);
    },
    dots: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div
        className=" tw-relative mb-[200px] tw-mt-[1950%] sm:tw-mt-[10vh] sm:tw-mx-[25vw]"
        style={{
          height: "7px",
          width: "20px",
          borderRadius: "10px",
          backgroundColor: i === index ? "#86241D" : "lightgray",
          // width: i === index ? "35px" : "7px",
          transition: "all 0.5s",
        }}
      ></div>
    ),
  };
  const CarouselIndicaterHandler = (clickedIndex) => {
    setIndex(clickedIndex);
  };
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Landing Page",
    });
    const intervalId = setInterval(() => {
      setAnimate((prevAnimate) => !prevAnimate);
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <motion.div
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
      className="tw-overflow-x-hidden tw-mb-[10%]"
    >
      <Navbar />
      <div className=" tw-block md:tw-hidden">
        <Navbar2 />
      </div>
      <Connect />

      <div style={{}} className="md:tw-mt-20 marquee-div tw-mt-[8rem]">
        <Marquee
          className="marguee text-light text-center mt-3 font-Montserrat"
          width="100%"
          direction="left"
          height="20px"
        >
          <span className="mx-5">TARAaang Landscape </span>
          <span className="mx-5">Graden Contract and Maintenance </span>
          <span className="mx-5">
            Address : Mohanpur, Barrackpore, West Bengal, Kol - 700122
          </span>
          <span className="mx-5">GST Certified </span>
          <span className="mx-5"> ISO Certified : ISO 9001 : 2015</span>
          <span className="mx-5"> Toll Free : 1800 121 2441</span>
        </Marquee>

        {/* ===================================================================== Home =================================== */}

        <div className="d-flex align-items-center justify-content-center tw-min-w-[80%] tw-flex tw-max-w-[100%] mx-5 tw-mt-[5%] tw-flex-wrap lg:tw-flex-nowrap  home-div tw-h-fit">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
            className="col-lg-7 col-md-10 col-sm-12 mb-5 pb-5 tw-text-wrap"
          >
            <img
              className=" tw-w-auto lg:tw-w-0 tw-mx-[-5%] lg:tw-invisible"
              src={tree}
              alt="tree"
            ></img>
            <img
              className=" tw-w-auto tw-mb-[-20%] tw-mx-[-5%] lg:tw-mb-0 lg:tw-w-0 lg:tw-invisible"
              style={{
                bottom: "92px",
                zIndex: "-1",
                position: "relative",
                // marginBottom:"-20%"
              }}
              src={shadow}
              alt="shadow"
            ></img>
            <Slider
              className="home-slider"
              style={{ maxWidth: "60rem" }}
              {...settings}
            >
              <div>
                <div className="tw-font-Montserrat tw-text-4xl tw-font-bold">
                  <h1 className="font-w-600">
                    Bring<span className="c-green font-w-600"> Plants,</span>{" "}
                    Make Your
                  </h1>
                  <h1 className="font-w-600">
                    Home<span className="c-green"> Green</span> and Breath
                  </h1>
                  <h1 className="font-w-600">
                    <span className="c-green">Healthy</span>
                  </h1>
                </div>
                <div
                  className="my-5 py-2 col-lg-8 font-w-400 c-gray tw-flex-wrap"
                  // style={{ maxWidth: "760px" }}
                  style={{}}
                >
                  Stress less and relax more, Immerse yourself in the calming
                  vibes of nature. Greenery reduces stress, lowers blood
                  pressure, and creates a tranquil haven for your mind and soul.
                  <div className="text-white">
                    {" "}
                    control, leaving you with a thriving oasis and time to
                    simply enjoy.
                  </div>
                </div>
              </div>
              <div>
                <div className="tw-font-Montserrat tw-text-4xl tw-font-bold">
                  <h1 className="font-w-600">
                    A Complete <span className="c-green">Gardening</span>
                  </h1>
                  <h1 className="font-w-600">
                    Solution With<span className="c-green"> Care</span>
                  </h1>
                </div>
                <div className="my-5 py-2 col-lg-8 font-w-400 c-gray font-Montserrat">
                  From seed to bloom, we've got you covered. Forget the
                  guesswork, let our experts plan, build, and nurture your dream
                  garden. We handle everything, from soil testing to pest
                  control, leaving you with a thriving oasis and time to simply
                  enjoy.
                </div>
              </div>
            </Slider>
            <div className="sm:tw-flex tw-flex sm:tw-mx-0 tw-mx-[7%]">
              <Link to="/products">
                <button
                  className="font-Montserrat me-4 slides"
                  style={{
                    backgroundColor: "#66A400",
                    color: "white",
                    borderRadius: "30px",
                    padding: "10px 28px",
                    border: "none",
                    width: "200px",
                    height: "53px",
                    transition: "box-shadow 0.5s ease",
                  }}
                >
                  Discover more
                </button>
              </Link>
              <Link to="/ourWorks">
                <button
                  className=" font-Montserrat slides tw-flex tw-items-center "
                  style={{
                    color: "#66A400",
                    borderRadius: "30px",
                    backgroundColor: "white",
                    padding: "10px 28px",
                    border: "1px solid #66A400",
                    width: "200px",
                    height: "53px",
                    transition: "box-shadow 0.5s ease",
                  }}
                >
                  <img className="tw-pe-4" alt="plant" src={plant}></img>
                  Our Works
                </button>
              </Link>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}
            className="tw-invisible tw-h-0 lg:tw-visible"
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "38px",
            }}
          >
            <img style={{ maxWidth: "29rem" }} src={tree} alt="tree"></img>
            <img
              style={{
                bottom: "92px",
                zIndex: "-1",
                position: "relative",
              }}
              src={shadow}
              alt="shadow"
            ></img>
          </motion.div>
        </div>

        {/* =============================================================Symphony of Services,=============================== */}

        <div className="lg: tw-flex tw-flex-col  tw-mt-[130%] sm:tw-mt-[0%] tw-text-center">
          <div className=" tw-text-[20px] tw-font-bold tw-mx-[3%] md:tw-mb-5 md:tw-pb-5 md:tw-text-center font-Montserrat md:tw-text-3xl">
            A{" "}
            {
              <span className="c-green">
                <span className={` ${animate ? "logo is-animetion" : ""}`}>
                  <span>S</span>
                  <span>y</span>
                  <span>m</span>
                  <span>p</span>
                  <span>h</span>
                  <span>o</span>
                  <span>n</span>
                  <span>y</span>
                  <span>&nbsp;</span>
                  <span>o</span>
                  <span>f</span>
                  <span>&nbsp;</span>
                  <span>S</span>
                  <span>e</span>
                  <span>r</span>
                  <span>v</span>
                  <span>i</span>
                  <span>c</span>
                  <span>e</span>
                  <span>s</span>
                </span>
                ,{" "}
              </span>
            }
            Orchestrated for - YOU!
          </div>
          <div className="d-flex justify-content-evenly tw-flex-wrap tw-mt-[15%] sm:tw-mt-[5%]">
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { duration: 0.4, delay: 0 },
              }}
            >
              <Link className=" hover:tw-text-black" to="/plantSelling">
                {" "}
                <div className="card d-flex justify-content-around py-3 px-3">
                  <div className="d-flex flex-column">
                    <h4 className="text-center mb-4 mt-4 tw-text-lg  tw-font-Montserrat tw-font-semibold">
                      Plant Selling
                    </h4>
                    <p className="text-start px-2 pt-3 tw-text-[#707070] tw-font-Montserrat">
                      We offer a diverse variety of plants to suit any taste or
                      budget, from lush ferns and vibrant flowers to
                      low-maintenance succulents and air-purifying cacti.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img src={cardimg1}></img>
                    </div>
                  </div>
                </div>
              </Link>
              <div className="text-center my-5 ">
                <Link to="/plantSelling">
                  <button
                    className="font-Montserrat slides "
                    style={{
                      fontSize: "small",
                      backgroundColor: "#66A400",
                      color: "white",
                      borderRadius: "10px",
                      padding: "10px 45px",
                      border: "none",
                      transition: "box-shadow 0.5s ease",
                    }}
                  >
                    {"Explore more "}
                    <i className="bi bi-chevron-right "></i>
                  </button>
                </Link>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { duration: 0.4, delay: 0.1 },
              }}
            >
              <Link className=" hover:tw-text-black" to="/landscaping">
                {" "}
                <div className="card d-flex justify-content-around py-3 px-3">
                  <div className=" d-flex flex-column">
                    <h4 className="text-center mb-4 mt-4 tw-text-lg tw-font-Montserrat tw-font-semibold  ">
                      Landscaping
                    </h4>
                    <p className="text-start px-3 pt-3 tw-text-[#707070] tw-font-Montserrat">
                      Elevate your outdoor space with our expert landscaping
                      service, offering tailored designs and meticulous care.
                      Transform your property's curb appeal today.
                    </p>
                    <div
                      className="tw-pt-[7%] tw-mt-[18px]"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <img src={cardimg2}></img>
                    </div>
                  </div>
                </div>
              </Link>
              <div className="text-center my-5 ">
                <Link to="/landscaping">
                  <button
                    className="font-Montserrat slides"
                    style={{
                      fontSize: "small",
                      backgroundColor: "#66A400",
                      color: "white",
                      borderRadius: "10px",
                      padding: "10px 45px",
                      border: "none",
                      transition: "box-shadow 0.5s ease",
                    }}
                  >
                    {"Explore more "}
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </Link>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: -200 }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: { duration: 0.4, delay: 0.2 },
              }}
            >
              <Link className=" hover:tw-text-black" to="/maintenance">
                {" "}
                <div className="card d-flex justify-content-around py-3 px-3">
                  <div className=" d-flex flex-column">
                    <h4 className="text-center mb-4 mt-4 tw-text-lg tw-font-Montserrat tw-font-semibold">
                      Maintenance
                    </h4>
                    <p className="text-start px-2 pt-3 tw-text-[#707070] tw-font-Montserrat">
                      Keep your property pristine with our reliable landscaping
                      maintenance service. From regular lawn care to seasonal
                      cleanups, ensuring a vibrant landscape year-round.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "21px",
                      }}
                    >
                      <img
                        style={{ width: "230px", height: "180px" }}
                        src={cardimg3}
                        alt="plant"
                      ></img>
                    </div>
                  </div>
                </div>
              </Link>
              <div className="text-center my-5 ">
                <Link className="" to="/maintenance">
                  {" "}
                  <button
                    className="font-Montserrat slides"
                    style={{
                      fontSize: "small",
                      backgroundColor: "#66A400",
                      color: "white",
                      borderRadius: "10px",
                      padding: "10px 45px",
                      border: "none",
                      transition: "box-shadow 0.5s ease",
                    }}
                  >
                    {"Explore more "}
                    <i class="bi bi-chevron-right"></i>
                  </button>
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
        {/* =============================================================Our Reasons To Shine=================================== */}
        <div className="d-flex flex-column justify-content-center align-items-center pb-5 tw-mt-[-25%] sm:tw-mt-[0%] tw-h-[100%]">
          <h1
            style={{
              fontWeight: "600",
              lineHeight: "54px",
              display: "flex",
            }}
            className="sm:tw-my-[5%] tw-my-[35%] tw-mb-[10%] tw-text-[24px] text-center font-Montserrat md:tw-text-3xl"
          >
            Our Reasons To &nbsp;{" "}
            <span className="c-green">
              {
                <span className="c-green">
                  <span className={` ${animate ? "logo is-animetion" : ""}`}>
                    <span>S</span>
                    <span>h</span>
                    <span>i</span>
                    <span>n</span>
                    <span>e</span>
                  </span>
                </span>
              }
            </span>
          </h1>
          <div className="tw-flex tw-justify-center tw-w-[90%] tw-h-[100%] tw-flex-col sm:tw-flex-row tw-items-center  tw-mb-[45%] sm:tw-mb-[1%]  shine-slider-div">
            <Slider
              className=" shine-slider sm:tw-w-[50%]"
              asNavFor={nav2}
              vertical
              ref={(slider1) => setNav1(slider1)}
              {...imageSetting}
            >
              {imageComponents.map((img, index) => {
                return (
                  <div className="slider-img tw-h-[404px]" key={index}>
                    {img}
                  </div>
                );
              })}
            </Slider>
            <Slider
              className=" tw-mx-[3%] sm:px-4 tw-px-[5px] tw-w-[100%] sm:tw-w-[50%] text-slider "
              asNavFor={nav1}
              ref={(slider2) => setNav2(slider2)}
              slidesToShow={1}
              autoplay
              autoplaySpeed={6000}
              vertical
              speed={500}
            >
              {components.map((text, index) => {
                return (
                  <div
                    className="text-slider-nest tw-text-xs tw-leading-[17px]sm:tw-text-xs lg:tw-text-lg  tw-h-[360px] sm:tw-h-[300px] lg:tw-h-[400px]"
                    key={index}
                  >
                    {text}
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="tw-relative">
            {/* <CarouselIndicater
              index={index}
              onClick={CarouselIndicaterHandler}
            ></CarouselIndicater> */}
          </div>
        </div>

        {/* ========================================================Installation is a just a beginning=============================== */}

        <div
          className=" installation-div tw-bg-cover tw-h-80 tw-flex tw-justify-evenly sm:tw-justify-center tw-flex-col tw-items-center tw-mt-[10%]"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className="installation-font">
            {" "}
            <h4
              style={{ fontWeight: 600, color: "white" }}
              className=" tw-font-Montserrat tw-mt-[2rem] sm:tw-mt-[2rem] tw-text-center sm:tw-text-[26px] tw-text-[24px]"
            >
              Installation is a just a beginning
            </h4>
          </div>
          <div>
            {" "}
            <p
              style={{
                fontWeight: 400,
                color: "white",
                textAlign: "center",
                maxWidth: "1106px",
              }}
              className="sm:tw-m-5 tw-mt-3 sm:tw-p-5 tw-m-5 d-flex font-Montserrat installation-font"
            >
              Once you hire us, yard work becomes a breeze! We draw strength
              from our customers' faith, setting us apart. We're with you beyond
              installation. Maintenance or not, our experts are always a call
              away. Ask us anything, anytime. Rely on us for the best
              after-sales care, ensuring your landscape thrives. Ready to ditch
              yard chores? We're here to help!
            </p>
          </div>
        </div>

        {/* ===================================================Dont't Believe Us,.....Section===================================== */}

        {/* .......................................................titas section ...................................................*/}

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          className="tw-mt-[10%] pb-5"
        >
          <div
            className="justify-content-center"
            style={{ alignItems: "center" }}
          >
            <h1
              style={{
                fontWeight: "600",
                lineHeight: "54px",
                display: "flex",
              }}
              className=" tw-text-[24px] text-center font-Montserrat tw-flex-wrap justify-content-center align-items-center md:tw-text-3xl"
            >
              Don't &nbsp;
              <span className="c-green">
                <span className={` ${animate ? "logo is-animetion" : ""}`}>
                  <span>B</span>
                  <span>e</span>
                  <span>l</span>
                  <span>i</span>
                  <span>e</span>
                  <span>v</span>
                  <span>e</span>
                </span>
              </span>{" "}
              &nbsp;Us, Check Client Words
            </h1>
            <p
              className="font-Montserrat text-center  tw-mx-[5%] sm:tw-mx-0 justify-content-center align-items-center"
              style={{
                color: "#909090",
                fontSize: "2.2vh",
                maxLines: 2,
                marginTop: "40px",
                display: "flex ",
              }}
            >
              Our valuable customers and industries, companies are satisfied to
              out work. I hope we can provide you <br /> our best services.{" "}
            </p>
          </div>
          <div style={{ marginTop: "55px" }}>
            <h2
              style={{
                fontWeight: "600",
                lineHeight: "24.38px",
                display: "flex",
                color: "#B0B0B0",
              }}
              className="text-center font-Montserrat justify-content-center align-items-center sm:tw-text-[20px] tw-text-[1.7vh]"
            >
              <span style={{ color: "#86241D" }}>Google&nbsp;</span> rating
              score &nbsp;<span style={{ color: "#86241D" }}>4.7&nbsp;</span>of
              5, based on &nbsp;<span style={{ color: "#186DEE" }}>G</span>
              <span style={{ color: "#DC4E3A" }}>o</span>
              <span style={{ color: "#FFBB06" }}>o</span>
              <span style={{ color: "#1368EA" }}>g</span>
              <span style={{ color: "#009B57" }}>l</span>
              <span style={{ color: "#DC4E3A" }}>e</span>
            </h2>
          </div>
          <div
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginTop: "65px",
            }}
          >
            <div style={{ width: "1270px" }}>
              <Carousel breakPoints={breakPoints}>
                {reviewsData.map((review) => (
                  <div
                    key={review.id}
                    className="text-center d-flex flex-column mx-4 sm:tw-p-3 tw-p-10 bg-light tw-h-[400px] sm:tw-h-auto"
                    style={{ borderRadius: "7px" }}
                  >
                    <div className=" d-flex flex-row tw-items-center tw-h-[50px]">
                      <div
                        className=" tw-w-[50px] tw-h-[50px] tw-rounded-full tw-justify-center tw-flex tw-items-center"
                        style={{ backgroundColor: review.color }}
                      >
                        <div className=" tw-w-[50px] tw-h-[50px] tw-flex tw-items-center tw-justify-center  tw-font-Montserrat tw-font-bold tw-text-2xl tw-text-white">
                          {review.initials}
                        </div>
                      </div>
                      <div className=" font-Montserrat tw-px-[5%] tw-text-left">
                        <span
                          className="sm:tw-text-[16px] tw-text-[15px]"
                          style={{ fontWeight: "bolder" }}
                        >
                          {review.name}
                        </span>
                        <p
                          className="sm:tw-text-[10px] tw-text-[9px] "
                          style={{ fontWeight: "500", textAlign: "left" }}
                        >
                          {review.date}
                        </p>
                      </div>
                    </div>
                    <div className=" mt-4 font-Montserrat font-w-400 ">
                      <p
                        style={{
                          fontSize: "12px",
                          textAlign: "left",
                          height: "150px",
                        }}
                      >
                        {review.text}
                      </p>
                    </div>

                    <div className="tw-relative tw-top-24  sm:tw-top-1">
                      {" "}
                      <StarRating rating={review.rating} />
                    </div>
                  </div>
                ))}
              </Carousel>
              <div
                className=" d-flex align-items-center justify-content-center"
                style={{ marginTop: "67px" }}
              >
                <div class="slide-background" style={{}}>
                  <a
                    className="button hover:tw-cursor-pointer"
                    href="https://g.page/r/CVPGw6DlsBVqEBE/review"
                    target="__blank"
                    style={{
                      backgroundColor: "#186DEE",
                      border: "none",
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      width: "200px",
                      height: "53px",
                      position: "relative",
                      zIndex: "1",
                      overflow: "hidden",
                      transition: "background-color 0.3s ease-in-out",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      className=" font-Montserrat"
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "white",
                      }}
                    >
                      <div className="slide"></div>Leave a review
                    </div>
                    <img src={arrow_r} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        {/* .......................................................Trusted partners ...................................................*/}
        <div className=" tw-mt-[10%]">
          <div>
            <h1
              style={{
                fontWeight: "600",
                lineHeight: "54px",
                display: "flex",
              }}
              className=" tw-text-[24px] md:tw-text-3xl text-center font-Montserrat justify-content-center align-items-center "
            >
              Our&nbsp;
              <span className="c-green">
                <span className={` ${animate ? "logo is-animetion" : ""}`}>
                  <span>T</span>
                  <span>r</span>
                  <span>u</span>
                  <span>s</span>
                  <span>t</span>
                  <span>e</span>
                  <span>d</span>
                </span>
              </span>
              &nbsp;Partners
            </h1>
          </div>
          <div style={{ marginTop: "80px" }}>
            {/* <Carousel
              breakPoints={breakPointsPartner}
              showArrows={false}
              enableAutoPlay={true}
              autoplaySpeed={4000}
              isRTL={false}
              pagination={false}
              onNextEnd={({ index }) => {
                clearTimeout(resetTimeout);
                resetTimeout = setTimeout(() => {
                  carouselRef?.current?.goTo(0);
                }); // same time
              }}
            >
              {partners.map((partner, index) => (
                <div key={index}>
                  <img src={partner} alt={` ${index + 1}`} />
                  
                </div>
              ))}
            </Carousel> */}
            <Marquee
              className="text-center "
              width="100%"
              direction="right"
              height="100px"
            >
              {partners.map((partner, index) => {
                return (
                  <img
                    className="px-5 marguee-imgs tw-max-w-[300px] tw-max-h-[100px]"
                    alt="partners-logo"
                    src={partner}
                  ></img>
                );
              })}
            </Marquee>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Home;
