import React, { useEffect, useState } from "react";
import axios from "axios";

const Chatbot = () => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const dfMessenger = document.querySelector("df-messenger");

    const handleResponseReceived = (event) => {
      const intentDisplayName =
        event.detail.response.queryResult.intent.displayName;
      if (intentDisplayName === "phone number") {
        const phoneNumber =
          event.detail.response.queryResult.parameters["phone-number"];
        console.log("Phone number:", phoneNumber);
        setUserData((prevUserData) => ({
          ...prevUserData,
          mobileNumber: phoneNumber,
        }));
        if (userData.name) {
          sendEmail(userData);
        }
      } else if (intentDisplayName === "name intent") {
        const personName =
          event.detail.response.queryResult.parameters["person"];
        console.log("Person Name:", personName[0].name);
        setUserData((prevUserData) => ({
          ...prevUserData,
          name: personName[0].name,
        }));
        if (userData.mobileNumber && userData.name) {
          sendEmail(userData);
        }
      }
    };

    const sendEmail = async (userData) => {
      const data = {
        header: "Green Assistant",
        subject: "User Details from Chatbot",
        text: `
            I hope this email finds you well. I am writing to provide you with the details of a user who has interacted with our chatbot:
    
            Name: ${userData.name}
            Phone Number: ${userData.mobileNumber}
            `,
        to: "taraangmithun@gmail.com",
      };
      try {
        const response = await axios.post(
          "https://sicu-aura-mailer.vercel.app/sendEmail",
          data
        );
        console.log("Email sent:", response.data);
        setUserData({});
      } catch (error) {
        console.error("Error sending email:", error);
      }
    };

    dfMessenger.addEventListener(
      "df-response-received",
      handleResponseReceived
    );

    // Remove event listener on component unmount
    return () => {
      dfMessenger.removeEventListener(
        "df-response-received",
        handleResponseReceived
      );
    };
  }, [userData]);

  return (
    <div>
      {/* <df-messenger chat-title="GreenAgent" agent-id="0de03eff-fd09-493e-9408-3c7fee8a8daa"
                language-code="en"></df-messenger> */}
    </div>
  );
};

export default Chatbot;
