import React, { useEffect } from 'react'
import background from '../Images/client-back.png'
import { useContext, useState } from 'react'
import AnimateContext from '../Components/useContext'
import Images from '../Images/index'
import { motion } from "framer-motion";
import Connect from '../Components/Connect'
import Navbar2 from '../Navbar2';
import clientData from '../Components/clientData'
import ReactGA from "react-ga4";


function Clients() {
  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Clients" })
},[])
  const animate = useContext(AnimateContext)
  const categories = ['All', 'Industry', 'Hotel', 'Resort', 'Residence', 'School', 'University', 'Hospital','Limited Company'];

  const [selectedCategory, setSelectedCategory] = useState('All');

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const filteredImages =
    selectedCategory === 'All'
      ? clientData
      : clientData.filter((image) => image.category === selectedCategory);
  return (

    <motion.div initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}>
      <Navbar2 />
      <Connect />





      <div className='tw-bg-cover tw-h-80 tw-flex tw-justify-center tw-items-center' style={{ backgroundImage: `url(${background})` }}>
        <h1 className='tw-font-Montserrat tw-text-3xl tw-font-bold tw-text-emerald-50'>OUR CLIENTS</h1>
      </div>
      <div>
        <h1
          style={{ fontWeight: "600" }}
          className="tw-text-[24px] md:tw-text-3xl my-5 pt-5 text-center tw-font-Montserrat">
          Our Top{" "}
          {
            <span className="c-green">
              <span className={` ${animate ? "logo is-animetion" : ""}`}>
                <span>C</span>
                <span>l</span>
                <span>i</span>
                <span>e</span>
                <span>n</span>
                <span>t</span>
                <span>s</span>
              </span>
            </span>
          }
        </h1>
        <div className="tw-flex tw-flex-wrap tw-justify-center tw-items-center">
          {
            Images.Images.clients.map((image, index) => (
              <div key={index} className="tw-flex tw-flex-row">
                <img src={image} alt="clients" className=" tw-flex tw-h-auto sm:tw-mx-20 tw-mx-8 tw-mb-[3rem] sm:tw-mb-20 tw-mt-3 sm:tw-max-w-full tw-max-w-[120px]" />
              </div>
            ))
          }
        </div>
      </div>
      <div>
        <h1
          style={{ fontWeight: "600",  }}
          className="tw-text-[24px] md:tw-text-3xl my-5  text-center tw-font-Montserrat">
          Our&nbsp;
          {
            <span className="c-green">
              <span className={` ${animate ? "logo is-animetion" : ""}`}>
                <span>C</span>
                <span>l</span>
                <span>i</span>
                <span>e</span>
                <span>n</span>
                <span>t</span>
                <span>s</span>
              </span>
            </span>
          }
        </h1>
        <div className=' tw-mt-16 tw-flex tw-flex-col tw-justify-center tw-items-center '>
          <div className='  tw-flex tw-flex-wrap sm:tw-justify-start tw-mx-[3%] tw-items-start' >
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => handleCategoryClick(category)}
                style={{
                  color: selectedCategory === category ? 'white' : '#66A400',
                  backgroundColor:selectedCategory === category ? '#66A400' : 'white',
                }}
                className=' sm:tw-mx-6 tw-mx-3 tw-my-2 tw-border-[1px] tw-border-[#66A400] tw-px-[10px] tw-py-[4px] tw-font-Montserrat tw-font-[500] tw-text-[#66A400] tw-text-[16px] tw-rounded-[5px] tw-transition-all  '
              >
                {category}
              </button>
            ))}
          </div>
          <div className=' tw-flex tw-flex-wrap tw-mt-16 sm:tw-justify-start tw-justify-center tw-items-start'>
            {filteredImages.map((image) => (
              <motion.img
                initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1,delay:image.id*0.1 } }} 
                className=' sm:tw-mx-16 tw-mx-8 tw-my-3 sm:tw-max-w-[150px] tw-max-w-[100px] sm:tw-max-h-[150px] '
                key={image.id}
                src={image.img}
                alt={`category-${image.category}`}  
              />
            ))}
          </div>
        </div>
      </div>



      {/* <div>
        <h1
          style={{ fontWeight: "600", fontSize: "xx-large" }}
          className="my-5 pt-5 text-center font-Montserrat">
          {
            <span className="c-green">
              <span className={` ${animate ? "logo is-animetion" : ""}`}>
                <span>N</span>
                <span>e</span>
                <span>w</span>
                <span>e</span>
                <span>s</span>
                <span>t</span>
              </span>
            </span>
          }
          {" "}
          Clients
        </h1>
        <div className="tw-flex tw-flex-wrap tw-justify-center tw-items-center">
          {
            Images.clients.map((image, index) => (
              <div key={index} className="tw-flex tw-flex-row">
                <img src={image} alt="clients" className=" tw-flex tw-max-w-full tw-h-auto tw-mx-20 tw-mb-20 tw-mt-3" />
              </div>
            ))
          }
        </div>
      </div>
      <div>
        <h1
          style={{ fontWeight: "600", fontSize: "xx-large" }}
          className="my-5 pt-5 text-center font-Montserrat">
          Oldest Clients{" "}
          {
            <span className="c-green">
              <span className={` ${animate ? "logo is-animetion" : ""}`}>
                <span>S</span>
                <span>i</span>
                <span>n</span>
                <span>c</span>
                <span>e</span>
                <span>&nbsp;</span>
                <span>2</span>
                <span>0</span>
                <span>2</span>
                <span>4</span>
              </span>
            </span>
          }
        </h1>
        <div className="tw-flex tw-flex-wrap tw-justify-center tw-items-center">
          {
            Images.clients.map((image, index) => (
              <div key={index} className="tw-flex tw-flex-row">
                <img src={image} alt="clients" className=" tw-flex tw-max-w-full tw-h-auto tw-mx-20 tw-mb-20 tw-mt-3" />
              </div>
            ))
          }
        </div>
      </div> */}

    </motion.div>



  )
}

export default Clients