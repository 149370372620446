import React from "react";
function frame5() {
  return (
    <div className=" d-flex flex-column c-gray font-Montserrat slider-text">
      <h5
        className="text-center tw-mb-5"
        style={{ fontWeight: "bolder", color: "black", fontSize: "24px" }}
      >
        Garden's Sculpture
      </h5>
      <p>
        Garden sculptures can add a touch of whimsy, elegance, or intrigue to
        any outdoor space. They can provide a focal point, draw the eye, and
        create a sense of place. Sculptures can also reflect the personality of
        the homeowner or designer, and add a unique touch to any landscape.With
        careful planning, garden sculptures can be a valuable addition to any
        outdoor space. They can add beauty, interest, and function to any
        landscape.
      </p>
    </div>
  );
}

export default frame5;
