const ProductsImages = [

      {
            id: 0,
            name: "Gazebo",
            url: require('../../Images/Products/Products/Gazebo/2.jpg'),
            images: [
                  require('../../Images/Products/Products/Gazebo/2.jpg'),
                  require('../../Images/Products/Products/Gazebo/3.jpg'),
                  require('../../Images/Products/Products/Gazebo/1.jpeg'),

            ],
            he1: 'Gazebo',
            he2: '',
            Description: 'A gazebo is a charming outdoor structure, often with open sides and a solid roof, designed to provide shelter and a focal point in gardens or parks. With its inviting ambiance and versatility, a gazebo offers an ideal space for relaxation, outdoor dining, or social gatherings. Whether nestled amidst lush greenery or overlooking scenic vistas, a gazebo adds both aesthetic appeal and functional value to any outdoor setting.',
            Fact: 'Gazebos offer a serene retreat from the hustle and bustle of everyday life, providing a peaceful space for relaxation, meditation, or outdoor dining. Their versatile design makes them perfect for hosting gatherings, such as garden parties or weddings, adding charm and elegance to any event. With their ability to blend seamlessly into various landscapes, gazebos fulfill both aesthetic and functional needs, becoming a focal point of outdoor enjoyment.',
            qty:0,
      },
      {
            id: 1,
            name: "Pergola",
            url: require('../../Images/Products/Products/Pergola/3.jpg'),
            image1: require('../../Images/Products/Balcony Gardening.png'),
            image2: '',
            image3: '',
            image4: '',
            images: [
                  require('../../Images/Products/Products/Pergola/3.jpg'),
                  require('../../Images/Products/Products/Pergola/2.jpg'),
                  require('../../Images/Products/Products/Pergola/1.jpg'),
            ],
            he1: 'Pergola',
            he2: '',
            Description: 'A pergola is an outdoor structure featuring vertical posts supporting crossbeams and an open lattice roof, providing partial shade and defining an outdoor space. It offers a perfect balance of sunlight and shade, creating an inviting atmosphere for outdoor lounging, dining, or gardening. With its versatile design, a pergola adds architectural interest to gardens, patios, or backyard landscapes, enhancing both aesthetics and functionality.',
            Fact: 'Pergolas serve as elegant structures that provide partial shade while defining outdoor spaces, ideal for creating intimate seating areas or enhancing garden aesthetics. Their versatility makes them perfect for hosting outdoor gatherings, offering a charming setting for dining, entertaining, or relaxing in the comfort of dappled sunlight.',
            qty:0,
      },
      {
            id: 2,
            name: "Bamboo Hut",
            url: require('../../Images/Products/Products/Bamboo Hut/3.jpg'),
            image1: '',
            image2: '',
            image3: '',
            image4: '',
            images: [
                  require('../../Images/Products/Products/Bamboo Hut/3.jpg'),
                  require('../../Images/Products/Products/Bamboo Hut/2.jpg'),
                  require('../../Images/Products/Products/Bamboo Hut/1.jpg'),
            ],
            he1: 'Bamboo',
            he2: 'Hut',
            Description: 'A bamboo hut is a traditional, eco-friendly shelter constructed primarily from bamboo poles and thatched roofs, offering a rustic yet sustainable living space. With its natural materials and simple design, a bamboo hut provides a tranquil retreat, blending harmoniously with the surrounding environment.',
            Fact: 'Bamboo huts fulfill the need for sustainable and environmentally friendly housing solutions, particularly in rural or tropical regions where bamboo is abundant. These versatile structures serve as cozy dwellings, retreats, or eco-friendly accommodations, providing a unique blend of natural beauty and functional shelter.',
            qty:0,
      },
      {
            id: 3,
            name: "Pergola Entrance Gate",
            url: require('../../Images/Products/Products/Pergola Entrance Gate/2.jpg'),
            image1: '',
            image2: '',
            image3: '',
            image4: '',
            images: [
                  require('../../Images/Products/Products/Pergola Entrance Gate/2.jpg'),
                  require('../../Images/Products/Products/Pergola Entrance Gate/1.jpg'),
                  require('../../Images/Products/Products/Pergola Entrance Gate/3.jpg'),

            ],
            he1: 'Pergola',
            he2: 'Entrance Gate',
            Description: 'A pergola entrance gate is a decorative and functional structure featuring a pergola-style archway, typically adorned with climbing plants or vines, creating an inviting entryway to a garden or outdoor space. With its charming design and architectural appeal, a pergola entrance gate adds elegance and character while delineating the transition between outdoor areas.',
            Fact: 'A pergola entrance gate offers a picturesque and welcoming entry point, enhancing the aesthetics of a garden or outdoor space while providing structure and definition to the entrance.',
            qty:0,
      },
      {
            id: 4,
            name: "Fountain FRP",
            url: require('../../Images/Products/Products/Fountain FRP/1.webp'),
            image1: '',
            image2: '',
            image3: '',
            image4: '',
            images: [
                  require('../../Images/Products/Products/Fountain FRP/1.webp'),
                  require('../../Images/Products/Products/Fountain FRP/2.webp'),
                  require('../../Images/Products/Products/Fountain FRP/3.webp'),
            ],
            he1: 'Fountain',
            he2: 'FRP',
            Description: 'A fountain is a decorative water feature that adds tranquility and elegance to outdoor spaces, creating a focal point and soothing ambiance with its flowing water.  ',
            Fact: 'Fountains provide a refreshing and visually appealing element to gardens or public spaces, serving as focal points while offering auditory relaxation and attracting wildlife.',
            qty:0,
      },
      {
            id: 5,
            name: "Fountain Waterfall",
            url: require('../../Images/Products/Products/Fountain Waterfall/1.jpeg'),
            image1: '',
            image2: '',
            image3: '',
            image4: '',
            images: [
                  require('../../Images/Products/Products/Fountain Waterfall/1.jpeg'),
                  require('../../Images/Products/Products/Fountain Waterfall/2.webp'),
                  require('../../Images/Products/Products/Fountain Waterfall/3.jpeg'),
            ],
            he1: 'Fountain',
            he2: 'Waterfall',
            Description: 'A fountain waterfall combines the elegance of a traditional fountain with the natural beauty of cascading water, creating a mesmerizing and tranquil ambiance in any outdoor setting. With its soothing sounds and dynamic visual appeal, a fountain waterfall adds a touch of serenity and sophistication to gardens, patios, or landscape designs.',
            Fact: 'A fountain waterfall adds a sense of serenity and visual interest to outdoor spaces, providing a calming atmosphere and enhancing the aesthetic appeal of gardens, parks, or residential landscapes.',
            qty:0,
      },
      {
            id: 6,
            name: "Bird Bath",
            url: require('../../Images/Products/Products/Bird Bath/308862-2116x1411-robins-on-birdbath.jpg'),
            image1: '',
            image2: '',
            image3: '',
            image4: '',
            images: [
                  require('../../Images/Products/Products/Bird Bath/308862-2116x1411-robins-on-birdbath.jpg'),
                  require('../../Images/Products/Products/Bird Bath/1.jpg'),    
                  require('../../Images/Products/Products/Bird Bath/3.jpg'),
            ],
            he1: 'Bird',
            he2: 'Bath',
            Description: 'A bird bath is a shallow basin filled with water, designed to provide a source of hydration and bathing for birds, while also serving as a charming decorative element in gardens or outdoor spaces.',
            Fact: 'A bird bath offers a vital water source for birds, encouraging biodiversity and providing opportunities for observation and enjoyment in gardens or urban environments. Its presence enhances the ecological balance while adding aesthetic appeal to outdoor spaces, attracting a variety of bird species for bathing and drinking.',
            qty:0,
      },
      {
            id: 7,
            name: "Garden Statue",
            url: require('../../Images/Products/Products/Garden Statue/1641_1200x1200.webp'),
            images: [
                  require('../../Images/Products/Products/Garden Statue/1641_1200x1200.webp'),
                  require('../../Images/Products/Products/Garden Statue/point-in-displaying-garden-statues-symmetry.jpg'),
                  require('../../Images/Products/Products/Garden Statue/shutterstock_560305741.jpg'),
            ],
            he1: 'Garden',
            he2: 'Statue',
            Description: 'A garden statue is a decorative sculpture placed in outdoor spaces, adding artistic flair and personality while enhancing the overall ambiance and aesthetic appeal of gardens or landscapes. ',
            Fact: 'Garden statues serve as focal points that inject character and charm into outdoor spaces, elevating the visual interest and creating a sense of style in gardens or landscapes. They provide a unique opportunity for self-expression and artistic expression, transforming ordinary spaces into enchanting outdoor sanctuaries.',
            qty:0,
      },
      {
            id: 8,
            name: "Pot Stand Culture",
            url: require('../../Images/Products/Products/Pot Stand Culture/71Aeg6Ih6LS._AC_UF1000,1000_QL80_.jpg'),
            images: [
                  require('../../Images/Products/Products/Pot Stand Culture/71Aeg6Ih6LS._AC_UF1000,1000_QL80_.jpg'),
                  require('../../Images/Products/Products/Pot Stand Culture/Garfans-7-Tier-Plant-Stand-Wheels-Wood-Shelf-Indoor-Flower-Pot-Corner-Display-Rack-Outdoor-Patio-Living-Room-Balcony-Garden_9f510a4e-87d2-40eb-aba1-9e0322b7ef.webp'),
                  require('../../Images/Products/Products/Pot Stand Culture/wechat-image-20200624205830.jpg'), ,
            ],
            he1: 'Pot Stand',
            he2: 'Culture',
            Description: 'Pot Stand Culture refers to the practice of elevating potted plants using decorative stands or holders, adding dimension and style to indoor and outdoor spaces. It allows for creative arrangement of plants, optimizing space usage and enhancing visual appeal while promoting better air circulation and drainage for plant health.',
            Fact: 'Pot Stand Culture offers a practical solution for displaying plants, optimizing space usage and adding decorative flair to indoor and outdoor environments. By elevating potted plants, it facilitates better airflow, sunlight exposure, and drainage, promoting healthier plant growth while enhancing the aesthetic appeal of any space.',
            qty:0,
      },
      {
            id: 9,
            name: "Pebbles & Stones",
            url: require('../../Images/Products/Products/Pebbles & Stone Work/1.jpg'),
            images: [
                  require('../../Images/Products/Products/Pebbles & Stone Work/1.jpg'),
                  require('../../Images/Products/Products/Pebbles & Stone Work/2.jpg'),
                  require('../../Images/Products/Products/Pebbles & Stone Work/3.jpg'),
                  require('../../Images/Products/Products/Pebbles & Stone Work/5.jpg'),
                  require('../../Images/Products/Products/Pebbles & Stone Work/6.jpg'),

            ],
            he1: 'Pebbles',
            he2: '& Stones Works',
            Description: 'Pebbles and stones are natural elements used in landscaping to add texture, visual interest, and contrast, enhancing the aesthetic appeal and functionality of outdoor spaces.',
            Fact: 'Pebbles and stones serve as versatile materials for landscaping, providing ground cover, erosion control, and decorative accents in gardens, pathways, and water features. Their natural beauty and durability make them essential for creating low-maintenance and visually appealing outdoor environments.',
            qty:0,
      },
      {
            id: 10,
            name: "Spot Light",
            url: require('../../Images/Products/Products/Spot Light/14868-05-30 sf1.webp'),
            images: [
                  require('../../Images/Products/Products/Spot Light/14868-05-30 sf1.webp'),
                  require('../../Images/Products/Products/Spot Light/5164293-1004009-2-3-600.jpg'),
                  require('../../Images/Products/Products/Spot Light/garden-spike-spot-light.png'),
            ],
            he1: 'Spot',
            he2: 'Light',
            Description: 'Spotlights are focused outdoor lighting fixtures designed to highlight specific architectural features, landscape elements, or focal points, adding drama and ambiance to outdoor spaces while enhancing visibility and security.',
            Fact: 'Spotlights are essential for illuminating key features in outdoor spaces, such as trees, statues, or architectural details, enhancing their visibility and creating a captivating nighttime ambiance. They also improve security by illuminating dark areas, deterring intruders, and providing safety for nighttime activities.',
            qty:0,
      },
      {
            id: 11,
            name: "Bollard Light",
            url: require('../../Images/Products/Products/Bollard Light/RGBW-1  Web Horizontal-2D2A7763_600x400_0.jpg'),
            images: [
                  require('../../Images/Products/Products/Bollard Light/RGBW-1  Web Horizontal-2D2A7763_600x400_0.jpg'),
                  require('../../Images/Products/Products/Bollard Light/Radium-LED-Outdoor-Bollard-Light-India_5000x.webp'),
                  require('../../Images/Products/Products/Bollard Light/bollard-light-1587466469-5383434.jpeg'),
            ],
            he1: 'Bollard',
            he2: 'Light',
            Description: 'Bollard lights are low-level outdoor lighting fixtures typically installed along pathways, driveways, or garden borders, providing visibility, safety, and aesthetic enhancement while delineating and guiding movement in outdoor spaces.',
            Fact: 'Bollard lights offer essential pathway illumination, enhancing safety and navigation in outdoor areas while adding visual appeal to landscaping designs. Their durable construction and versatile designs make them ideal for residential, commercial, and public spaces, providing reliable lighting solutions that blend seamlessly with the surrounding environment.',
            qty:0,
      },
      {
            id: 12,
            name: "Organic Pesticide",
            url: require('../../Images/Products/Products/Organic Pesticide/natural-insecticide.jpg'),
            images: [
                  require('../../Images/Products/Products/Organic Pesticide/natural-insecticide.jpg'),
                  require('../../Images/Products/Products/Organic Pesticide/Types-of-Organic-Pesticides-or-Bio-Pesticides.jpg'),
                  require('../../Images/Products/Products/Organic Pesticide/organic-pesticides-homemade.jpg'),
            ],
            he1: 'Organic',
            he2: 'Pesticide',
            Description: 'Organic pesticides are natural and eco-friendly alternatives to chemical pesticides, derived from plant extracts, minerals, or beneficial microorganisms, effectively controlling pests while minimizing harm to the environment, beneficial insects, and human health.',
            Fact: 'Organic pesticides offer a safe and sustainable solution for controlling pests in gardens and agriculture, minimizing chemical residues and environmental damage while protecting beneficial insects and wildlife. Their natural ingredients provide effective pest management without compromising soil health or food safety, promoting organic farming practices and biodiversity conservation.',
            qty:0,
      },
      {
            id: 13,
            name: "Chemical Pesticide",
            url: require('../../Images/Products/Products/Chemical Pesticide/chemical-pesticides.jpeg'),
            images: [
                  require('../../Images/Products/Products/Chemical Pesticide/chemical-pesticides.jpeg'),
                  require('../../Images/Products/Products/Chemical Pesticide/images.jpg'),
                  require('../../Images/Products/Products/Chemical Pesticide/mos-insecticide-1-500x500.webp'),
            ],
            he1: 'Chemical',
            he2: 'Pesticide',
            Description: 'Chemical pesticides are synthetic compounds used to control pests and diseases in agriculture and gardening, offering effective and rapid pest eradication but raising concerns about environmental pollution and health risks due to their toxic residues.',
            Fact: 'Chemical pesticides are vital for protecting crops from destructive pests and diseases, ensuring high yields and food security in agriculture. Despite their efficacy, their use requires caution due to potential environmental contamination and health hazards, necessitating proper application and regulation to minimize risks.',
            qty:0,
      },
      {
            id: 14,
            name: "Organic Fertilizer",
            url: require('../../Images/Products/Products/Organic Fertilizer/compost.jpg'),
            images: [
                  require('../../Images/Products/Products/Organic Fertilizer/compost.jpg'),
                  require('../../Images/Products/Products/Organic Fertilizer/Types-of-Organic-Fertilizer-.jpg'),
                  require('../../Images/Products/Products/Organic Fertilizer/fertilizers.webp'),
            ],
            he1: 'Organic',
            he2: 'Fertilizer',
            Description: 'Organic fertilizers are natural substances derived from plant, animal, or mineral sources, enriching soil with essential nutrients while promoting soil health and sustainable gardening practices.',
            Fact: 'Organic fertilizers provide essential nutrients to plants while improving soil health and promoting sustainable agriculture practices. Their natural composition minimizes environmental impact, enhances soil fertility, and supports healthy plant growth, making them ideal for organic farming and gardening.',
            qty:0,
      },
      {
            id: 15,
            name: "Chemical Fertilizer",
            url: require('../../Images/Products/Products/Chemical Fertilizer/chemi1.jpg'),
            images: [
                  require('../../Images/Products/Products/Chemical Fertilizer/chemi1.jpg'),
                  require('../../Images/Products/Products/Chemical Fertilizer/chemical-fertilizers-500x500.webp'),
                  require('../../Images/Products/Products/Chemical Fertilizer/npk-fertilizer-500x500.webp'),
            ],
            he1: 'Chemical',
            he2: 'Fertilizer',
            Description: 'Chemical fertilizers are synthetic compounds containing concentrated nutrients to enhance plant growth, often used in conventional agriculture to boost crop yields quickly but may contribute to soil degradation and water pollution if overused.',
            Fact: 'Chemical fertilizers are essential for providing crops with specific nutrients needed for optimal growth, increasing agricultural productivity to meet global food demands. While they offer quick nutrient delivery and can address specific deficiencies, their overuse can lead to soil nutrient imbalance, environmental pollution, and long-term degradation of soil health.',
            qty:0,
      },
      {
            id: 16,
            name: "Garden Bench-Chair",
            url: require('../../Images/Products/Products/Garden Bench-Chair/IMG_1025.jpg'),
            images: [
                  require('../../Images/Products/Products/Garden Bench-Chair/IMG_1025.jpg'),
                  require('../../Images/Products/Products/Garden Bench-Chair/s-l1200.jpg'),
                  require('../../Images/Products/Products/Garden Bench-Chair/istockphoto-654647170-170667a.jpg'),
            ],
            he1: 'Garden',
            he2: 'Bench-chair',
            Description: 'A garden bench is a versatile outdoor seating solution, offering a comfortable and inviting spot to relax and enjoy the beauty of the surrounding landscape in gardens, parks, or outdoor spaces.',
            Fact: 'Garden benches provide a peaceful retreat for relaxation, contemplation, and socializing in outdoor environments, enhancing the usability and aesthetic appeal of gardens, parks, and public spaces. Their durable construction and weather-resistant materials make them ideal for year-round use, offering a comfortable seating option that encourages people to spend time outdoors.',
            qty:0,
      },
      {
            id: 17,
            name: "Artificial Turfing",
            url: require('../../Images/Products/Products/Artificial Turfing/FOOTBALL-ARTIFICIAL-GRASS1.webp'),
            images: [
                  require('../../Images/Products/Products/Artificial Turfing/FOOTBALL-ARTIFICIAL-GRASS1.webp'),
                  require('../../Images/Products/Products/Artificial Turfing/Pros-and-Cons-of-Artificial-Grass.png'),
                  require('../../Images/Products/Products/Artificial Turfing/artificial-grass-fake-grass-astro-turf-windsor-scaled-by-readylawn-nz.jpg'),
            ],
            he1: 'Artificial',
            he2: 'Turfing',
            Description: 'Artificial turfing involves installing synthetic grass as a low-maintenance alternative to natural grass, providing a lush and verdant surface for landscaping, sports fields, or residential lawns.',
            Fact: 'Artificial turfing offers a practical solution for maintaining green spaces in areas with challenging climates or limited water resources, providing a durable and aesthetically pleasing alternative to traditional grass. Its versatility makes it suitable for a wide range of applications, including residential landscaping, sports fields, and commercial spaces, offering year-round greenery without the need for constant maintenance.',
            qty:0,
      },
      {
            id: 18,
            name: "Fencing work",
            url: require('../../Images/Products/Products/Fencing Work/product-jpeg-500x500.webp'),
            images: [
                  require('../../Images/Products/Products/Fencing Work/product-jpeg-500x500.webp'),
                  require('../../Images/Products/Products/Fencing Work/chain-link-fencing-work.jpeg'),
                  require('../../Images/Products/Products/Fencing Work//Fencing.jpg'),
            ],
            he1: 'Fencing',
            he2: 'Work',
            Description: 'Fencing work involves installing barriers or enclosures around properties, gardens, or outdoor areas using various materials such as wood, metal, or vinyl, providing privacy, security, and aesthetic enhancement while defining property boundaries.',
            Fact: 'Fencing work is essential for enhancing security and privacy in residential, commercial, and agricultural settings, providing a physical barrier against trespassers, wildlife, and unwanted intrusions. Additionally, fences offer aesthetic value, defining property boundaries and adding architectural interest to outdoor spaces while also serving as windbreaks and noise barriers.',
            qty:0,
      },


];
const PotsImage = [
      {
            id: 37,
            name: "Plastic Pot",
            url: require('../../Images/Products/Products/Pots/Plastic Pot/40245916-4_1-leafy-tales-rectangular-plastic-pot-lightweight-durable-for-indooroutdoor-gardening-red.webp'),
            images: [
                  require('../../Images/Products/Products/Pots/Plastic Pot/40245916-4_1-leafy-tales-rectangular-plastic-pot-lightweight-durable-for-indooroutdoor-gardening-red.webp'),
                  require('../../Images/Products/Products/Pots/Plastic Pot/91TOc0ZQR2L._SL1500_.webp'),
                  require('../../Images/Products/Products/Pots/Plastic Pot/maxresdefault.jpg'),
            ],
            he1: 'Plastic',
            he2: 'Pot',
            Description: 'Plastic pots are lightweight and durable containers used for planting and displaying various types of plants, offering versatility and convenience for gardening enthusiasts in both indoor and outdoor settings.',
            Fact: 'Plastic pots provide an affordable and practical solution for cultivating plants, offering portability, durability, and versatility for gardening projects in various environments. Their lightweight design and water retention properties make them ideal for both indoor and outdoor use, allowing for easy transportation and efficient plant care.',
             qty:0,
      },
      {
            id: 38,
            name: "FRP Pot",
            url: require('../../Images/Products/Products/Pots/FRP Pot/91k2dDlXQ4L._AC_UF894,1000_QL80_.jpg'),
            images: [
                  require('../../Images/Products/Products/Pots/FRP Pot/91k2dDlXQ4L._AC_UF894,1000_QL80_.jpg'),
                  require('../../Images/Products/Products/Pots/FRP Pot/img317.jpg'),
                  require('../../Images/Products/Products/Pots/FRP Pot/outdoor-fiber-clay-planter-500x500.webp'),
            ],
            he1: 'FRP',
            he2: 'Pot',
            Description: 'FRP pots are lightweight and durable containers made from fiberglass reinforced plastic, offering a modern and stylish option for planting and showcasing plants in indoor and outdoor settings.',
            Fact: 'FRP pots provide a sleek and contemporary alternative to traditional plant containers, combining durability with aesthetic appeal for both residential and commercial landscaping projects. Their weather-resistant properties make them suitable for outdoor use, while their lightweight construction allows for easy transportation and versatility in design.',
             qty:0,
      },
      {
            id: 39,
            name: "Mud Pot",
            url: require('../../Images/Products/Products/Pots/Mud Pot/72d28fc7645b9310bf7a1c31539dc91f.jpg'),
            images: [
                  require('../../Images/Products/Products/Pots/Mud Pot/72d28fc7645b9310bf7a1c31539dc91f.jpg'),
                  require('../../Images/Products/Products/Pots/Mud Pot/6bxga49CXoKs8oHnTKjUaN.jpg'),
                  require('../../Images/Products/Products/Pots/Mud Pot/maxresdefault.jpg'),
            ],
            he1: 'Mud',
            he2: 'Pot',
            Description: 'Mud pots, crafted from natural clay or earthenware, offer a rustic and environmentally friendly option for planting and nurturing plants, providing a traditional and timeless charm to gardens and outdoor spaces.',
            Fact: 'Mud pots offer a natural and breathable environment for plant roots, promoting healthy growth and moisture retention while adding an earthy aesthetic to gardens and indoor spaces. Their porous nature allows for proper drainage, making them ideal for cultivating a variety of plants, herbs, and flowers in both traditional and contemporary garden designs.',
             qty:0,
      },
      {
            id: 40,
            name: "Cement Pot",
            url: require('../../Images/Products/Products/Pots/Cement Pot/concrete-pots-.jpg'),
            images: [
                  require('../../Images/Products/Products/Pots/Cement Pot/concrete-pots-.jpg'),
                  require('../../Images/Products/Products/Pots/Cement Pot/many-different-artificial-plants-flower-600nw-2330171389.webp'),
                  require('../../Images/Products/Products/Pots/Cement Pot/product-jpeg-500x500.webp'),
            ],
            he1: 'Cement',
            he2: 'Pot',
            Description: 'Cement pots are sturdy containers crafted from durable cement material, offering a modern and minimalist aesthetic for planting and showcasing greenery in both indoor and outdoor environments.',
            Fact: 'Cement pots are sturdy and versatile containers, providing a durable and modern option for planting and displaying greenery in indoor and outdoor settings. Their sleek design and weather-resistant properties make them ideal for adding a touch of contemporary elegance to gardens, patios, and interior spaces, while their robust construction ensures long-lasting durability.',
             qty:0,
      },
      {
            id: 41,
            name: "Ceramic Pot",
            url: require('../../Images/Products/Products/Pots/Ceramic Pot/6-6pc-handi-ceramic-pots-set-6pc-27-27-27cm-made-in-india-original-imag3kzupz26zzzz.webp'),
            images: [
                  require('../../Images/Products/Products/Pots/Ceramic Pot/6-6pc-handi-ceramic-pots-set-6pc-27-27-27cm-made-in-india-original-imag3kzupz26zzzz.webp'),
                  require('../../Images/Products/Products/Pots/Ceramic Pot/71WffRxu65L._AC_UF894,1000_QL80_.jpg'),
                  require('../../Images/Products/Products/Pots/Ceramic Pot/nurserylive-3-inch-handi-shape-round-ceramic-pots-pack-of-five_420x.webp'),
            ],
            he1: 'Ceramic',
            he2: 'Pot',
            Description: 'Ceramic pots are elegant and versatile containers made from fired clay, offering a stylish and decorative option for planting and displaying a variety of plants in indoor and outdoor settings.',
            Fact: 'Ceramic pots provide a sophisticated and visually appealing option for showcasing plants, adding decorative flair to both indoor and outdoor spaces. Their durable yet porous nature helps regulate soil moisture levels, promoting healthy plant growth while enhancing the aesthetic appeal of gardens, patios, and interior decor.',
             qty:0,
      },


];
const SoilImage = [
      {
            id: 52,
            name: "Loamy Soil",
            url: require('../../Images/Products/Products/Soils/Loamy Soil/GettyImages-1411589688.jpg'),
            images: [
                  require('../../Images/Products/Products/Soils/Loamy Soil/GettyImages-1411589688.jpg'),
                  require('../../Images/Products/Products/Soils/Loamy Soil/Loam-soil-featured-image.jpg'),
                  require('../../Images/Products/Products/Soils/Loamy Soil/eyJidWNrZXQiOiJjb250ZW50Lmhzd3N0YXRpYy5jb20iLCJrZXkiOiJnaWZcL3NodXR0ZXJzdG9jay0xODI5MTY5MDgwLmpwZyIsImVkaXRzIjp7InJlc2l6ZSI6eyJ3aWR0aCI6ODI4fX19.webp'),
            ],
            he1: 'Loamy',
            he2: 'Soil',
            Description: 'Loamy soil is a balanced mixture of sand, silt, and clay particles, offering optimal drainage, nutrient retention, and aeration for healthy plant growth in gardens and agricultural settings.',
            Fact: 'Loamy soil provides an ideal growing medium for a wide range of plants, offering excellent drainage while retaining essential nutrients and moisture for healthy root development. Its balanced texture and fertility make it suitable for various gardening and farming applications, promoting robust plant growth and high yields.',
             qty:0,
      },
      {
            id: 53,
            name: "Black Soil",
            url: require('../../Images/Products/Products/Soils/Black Soil/Black-Coated-Garden-Soil-for-Plants.jpg'),
            images: [
                  require('../../Images/Products/Products/Soils/Black Soil/Black-Coated-Garden-Soil-for-Plants.jpg'),
                  require('../../Images/Products/Products/Soils/Black Soil/Black_Garden_Soil_298d47d0ba.jpg'),
                  require('../../Images/Products/Products/Soils/Black Soil/Good-Organic-Soil.jpg.530x530.jpg'),
            ],
            he1: 'Black',
            he2: 'Soil',
            Description: 'Black soil, also known as "regur soil" or "black cotton soil," is a nutrient-rich soil type characterized by its dark color and high fertility, making it ideal for cultivating crops such as cotton, cereals, and pulses in agricultural regions around the world.',
            Fact: 'Black soil is highly prized for its fertility and moisture-retaining properties, making it essential for cultivating a variety of crops, including cotton, soybeans, and wheat, in regions with hot and dry climates. Its rich organic content and excellent tilth promote healthy root development and high yields, contributing to the success of agriculture in areas where it is found.',
             qty:0,
      },
      {
            id: 54,
            name: "Clay Soil",
            url: require('../../Images/Products/Products/Soils/Clay Soil/1523893451317.jpeg'),
            images: [
                  require('../../Images/Products/Products/Soils/Clay Soil/1523893451317.jpeg'),
                  require('../../Images/Products/Products/Soils/Clay Soil/clay-soil.jpg'),
                  require('../../Images/Products/Products/Soils/Clay Soil/is-clay-soil-good-for-plant-growth.jpg'),
            ],
            he1: 'Clay',
            he2: 'Soil',
            Description: 'Clay soil is a heavy and dense soil type composed of fine particles, offering high nutrient content but poor drainage, requiring careful management for successful plant growth in gardens and agricultural fields.',
            Fact: 'Clay soil provides excellent nutrient retention, making it suitable for growing crops that benefit from steady moisture and fertility, such as rice, corn, and certain vegetables. However, its poor drainage can pose challenges, requiring soil amendments and careful irrigation practices to optimize plant health and productivity.',
             qty:0,
      },



];
const GardenTool = [
      {
            id: 64,
            name: "Khurpi",
            url: require('../../Images/Products/Products/Gardening Tools/Khutpi/51vSaDLooiL._SL1500_.jpg'),
            images: [
                  require('../../Images/Products/Products/Gardening Tools/Khutpi/51vSaDLooiL._SL1500_.jpg'),
                  require('../../Images/Products/Products/Gardening Tools/Khutpi/617ODddV-kL.jpg'),
                  require('../../Images/Products/Products/Gardening Tools/Khutpi/khurpigardeningtool1.webp'),
            ],
            he1: 'Khurpi',
            he2: '',
            Description: 'A khurpi is a traditional gardening tool with a small, flat metal blade attached to a wooden or metal handle, commonly used for weeding, digging, and cultivating soil in small-scale gardening and farming operations.',
            Fact: 'Khurpi is indispensable for gardeners and farmers for precise weeding, loosening soil, and transplanting seedlings in tight spaces where larger tools are impractical. Its compact design and versatility make it ideal for maintaining vegetable patches, flower beds, and container gardens with ease and efficiency.',
             qty:0,
      },
      {
            id: 65,
            name: "Akra",
            url: require('../../Images/Products/Products/Gardening Tools/Akra/735323.webp'),
            images: [
                  require('../../Images/Products/Products/Gardening Tools/Akra/735323.webp'),
                  require('../../Images/Products/Products/Gardening Tools/Akra/product-jpeg-500x500.webp'),
                  require('../../Images/Products/Products/Gardening Tools/Akra/s-steel-garden-tools-a-b-c-d.jpeg'),
            ],
            he1: 'Akra',
            he2: '',
            Description: 'Akra is a traditional Indian gardening tool featuring a long wooden handle with a narrow, curved metal blade, commonly used for digging trenches, breaking up soil, and transplanting seedlings in agricultural and gardening activities.',
            Fact: 'Akra is essential for tasks like digging furrows, removing weeds, and aerating soil in traditional farming and gardening practices, particularly in regions with hard or compacted soil. Its ergonomic design and sturdy construction make it well-suited for labor-intensive tasks, promoting efficient cultivation and soil management for optimal plant growth.',
             qty:0,
      },
      {
            id: 66,
            name: "Nirani",
            url: require('../../Images/Products/Products/Gardening Tools/Nirani/1000026979.jpg'),
            images: [
                  require('../../Images/Products/Products/Gardening Tools/Nirani/1000026979.jpg'),
                  require('../../Images/Products/Products/Gardening Tools/Nirani/6_91__163221385461499b5e89a63_grande.webp'),
                  require('../../Images/Products/Products/Gardening Tools/Nirani/Bengal-blade-PVM.jpg'),
            ],
            he1: 'Nirani',
            he2: '',
            Description: 'Nirani is a traditional gardening tool originating from South India, featuring a sharp, curved blade attached to a long wooden handle, used for weeding, cutting roots, and clearing vegetation in agricultural and horticultural activities.',
            Fact: 'Nirani is indispensable for gardeners and farmers for precise weeding, cutting roots, and clearing vegetation in tight spaces where larger tools are impractical. Its sharp blade and ergonomic design make it ideal for maintaining vegetable patches, flower beds, and orchards with ease and efficiency.',
             qty:0,
      },
      {
            id: 67,
            name: "Cutter",
            url: require('../../Images/Products/Products/Gardening Tools/Cutter/hedge-cutter-800x800.jpg'),
            images: [
                  require('../../Images/Products/Products/Gardening Tools/Cutter/hedge-cutter-800x800.jpg'),
                  require('../../Images/Products/Products/Gardening Tools/Cutter/garden-flower-cutter.jpg'),
                  require('../../Images/Products/Products/Gardening Tools/Cutter/novo3d-heavy-duty-pruning-shear-alloy-steel-blade-gardening-tool-set-flower-cutter-grass-cutter-500x500.webp'),
            ],
            he1: 'Cutter',
            he2: '',
            Description: 'A cutter is a versatile gardening tool with a sharp blade, used for trimming, pruning, and shaping plants in both indoor and outdoor settings, promoting healthy growth and aesthetic appeal.',
            Fact: 'Cutter is indispensable for gardeners and landscapers for precise trimming, pruning, and shaping of plants, promoting healthy growth and aesthetic appeal in gardens and landscapes. Its sharp blade and ergonomic design make it suitable for tasks like cutting branches, shaping hedges, and removing dead foliage with ease and efficiency.',
             qty:0,
      },
      {
            id: 68,
            name: "Scissors",
            url: require('../../Images/Products/Products/Gardening Tools/Scissor (Kanchi)/46560370.jpg'),
            images: [
                  require('../../Images/Products/Products/Gardening Tools/Scissor (Kanchi)/46560370.jpg'),
                  require('../../Images/Products/Products/Gardening Tools/Scissor (Kanchi)/BGT206p1_grande.jpg'),
                  require('../../Images/Products/Products/Gardening Tools/Scissor (Kanchi)/stainless-steel-garden-scissors-500x500.webp'),
            ],
            he1: 'Scissors',
            he2: '',
            Description: 'Scissors are versatile cutting tools with two blades hinged together, commonly used for trimming, pruning, and grooming plants, as well as various other household and crafting tasks.',
            Fact: 'Scissors are essential for precise cutting and trimming of foliage, deadheading flowers, and performing delicate pruning tasks in gardening and horticultural activities. Their compact size and sharp blades make them ideal for a wide range of indoor and outdoor gardening tasks, promoting healthy plant growth and meticulous grooming with ease.',
             qty:0,
      },
      {
            id: 69,
            name: "Rose Cutter",
            url: require('../../Images/Products/Products/Gardening Tools/Rose Cutter/51BFunuFw5L.jpg'),
            images: [
                  require('../../Images/Products/Products/Gardening Tools/Rose Cutter/51BFunuFw5L.jpg'),
                  require('../../Images/Products/Products/Gardening Tools/Rose Cutter/garden-professional-gardening-pruning-shears-scissor-cutter-original-imag5ty9bg9snhye.webp'),
                  require('../../Images/Products/Products/Gardening Tools/Rose Cutter/rose-cutter-sharp-durable-easy-to-pruning-secateurs-garden-tool-original-imaf36qh3krkuyab.webp'),
            ],
            he1: 'Rose',
            he2: 'Cutter',
            Description: 'A rose cutter is a specialized gardening tool with sharp, curved blades designed specifically for pruning roses, facilitating precise and clean cuts to promote healthy growth and abundant flowering.',
            Fact: 'A rose cutter is essential for gardeners for precise pruning of roses, removing dead or diseased branches, shaping bushes, and encouraging vigorous growth and blooming. Its sharp blades and ergonomic design make it ideal for delicate pruning tasks, ensuring healthy and beautiful roses in gardens and landscapes.',
             qty:0,
      },
      {
            id: 70,
            name: "Sprayer",
            url: require('../../Images/Products/Products/Gardening Tools/Sprayer/classy-plastic-multipurpose-home-garden-water-spray-bottle-gardening-tool-by-nationbloom.jpg'),
            images: [
                  require('../../Images/Products/Products/Gardening Tools/Sprayer/classy-plastic-multipurpose-home-garden-water-spray-bottle-gardening-tool-by-nationbloom.jpg'),
                  require('../../Images/Products/Products/Gardening Tools/Sprayer/nurserylive-gardening-tools-pressure-sprayer-1-5-ltr-gardening-tool-16969224683660.jpg'),
                  require('../../Images/Products/Products/Gardening Tools/Sprayer/KASS-Sprayer-5L-garden-tools-balcony-planters-garden-tools-Horticult-1.jpeg'),
            ],
            he1: 'Sprayer',
            he2: '',
            Description: 'A spray is a liquid mixture containing pesticides, fertilizers, or other solutions, applied using a specialized spraying device to control pests, diseases, or nourish plants in agricultural, horticultural, or gardening activities.',
            Fact: 'Sprays are indispensable for gardeners and farmers to apply pesticides, fertilizers, or foliar nutrients efficiently and evenly, ensuring optimal plant health and productivity. Their versatility allows for targeted application, controlling pests and diseases while promoting robust growth and flowering in crops and ornamental plants alike.',
             qty:0,
      },


];
const GardenMachine = [
      {
            id: 80,
            name: "Brush Cutter",
            url: require('../../Images/Products/Products/Gardening Machines/Brush Cutter/1_-_Copy_(1)3.jpg'),
            images: [
                  require('../../Images/Products/Products/Gardening Machines/Brush Cutter/1_-_Copy_(1)3.jpg'),
                  require('../../Images/Products/Products/Gardening Machines/Brush Cutter/Durable-Use-2-Stroke-Brush-Cutter-Grass-Trimmer-for-Garden.webp'),
            ],
            he1: 'Brush',
            he2: 'Cutter',
            Description: 'A brush cutter is a powerful outdoor tool equipped with a rotating blade or cutting head, used for clearing dense vegetation, grass, weeds, and small bushes in landscaping, agricultural, and forestry applications.',
            Fact: 'A brush cutter is essential for tackling overgrown areas, clearing dense vegetation, and maintaining rough terrain where traditional mowers cannot reach, making it indispensable for landscaping and land management tasks. Its robust design and cutting power make it ideal for clearing brush, weeds, and small trees, ensuring efficient vegetation control and maintaining tidy landscapes in residential, commercial, and agricultural settings.',
             qty:0,
      },
      {
            id: 81,
            name: "Lawn Mower",
            url: require('../../Images/Products/Products/Gardening Machines/Lawn Mower/6350cdf3ed3679135234b22c-yard-machines-140cc-21-inch-3-in-1-push.jpg'),
            images: [
                  require('../../Images/Products/Products/Gardening Machines/Lawn Mower/6350cdf3ed3679135234b22c-yard-machines-140cc-21-inch-3-in-1-push.jpg'),
                  require('../../Images/Products/Products/Gardening Machines/Lawn Mower/64de76406fbfbe506f7db108-fdw-lawn-mower-grass-cutter-machine.jpg'),
                  require('../../Images/Products/Products/Gardening Machines/Lawn Mower/LM001J.jpg'),
            ],
            he1: 'Lawn',
            he2: 'Mower',
            Description: 'A lawn mower is a motorized or manual device equipped with rotating blades, used for cutting grass to a uniform height in residential, commercial, and public lawns, promoting neatness and aesthetic appeal.',
            Fact: 'Lawn mowers are essential for maintaining well-groomed lawns, trimming grass to a uniform height for aesthetic appeal and promoting healthy growth. Whether for residential yards, parks, or sports fields, lawn mowers ensure efficient grass cutting, enhancing the overall appearance and usability of outdoor spaces.',
             qty:0,
      },
      {
            id: 82,
            name: "Hedge Trimmer",
            url: require('../../Images/Products/Products/Gardening Machines/Hedge Trimmer/BM-SP-20231821-1.webp'),
            images: [
                  require('../../Images/Products/Products/Gardening Machines/Hedge Trimmer/BM-SP-20231821-1.webp'),
                  require('../../Images/Products/Products/Gardening Machines/Hedge Trimmer/maxresdefault.jpg'),
                  require('../../Images/Products/Products/Gardening Machines/Hedge Trimmer/71xWpO2cC+L.jpg'),
            ],
            he1: 'Hedge',
            he2: 'Trimmer',
            Description: 'A hedge trimmer is a gardening tool equipped with dual blades or reciprocating blades, used for trimming and shaping hedges, shrubs, and bushes to maintain tidy and manicured landscapes.',
            Fact: 'Hedge trimmers are essential for maintaining neat and well-defined hedges, promoting healthy growth and enhancing the aesthetic appeal of landscapes. Their precision cutting ability allows for efficient shaping and trimming of hedges, ensuring uniformity and tidiness in residential gardens, parks, and commercial landscapes.',
             qty:0,
      },
      {
            id: 83,
            name: "Auger Machine",
            url: require('../../Images/Products/Products/Gardening Machines/Auger Machine/1.jpg'),
            images: [
                  require('../../Images/Products/Products/Gardening Machines/Auger Machine/1.jpg'),
                  require('../../Images/Products/Products/Gardening Machines/Auger Machine/giant_130954.jpg'),
                  require('../../Images/Products/Products/Gardening Machines/Auger Machine/soil-augers-500x500.webp'),
            ],
            he1: 'Auger',
            he2: 'Machine',
            Description: 'An auger machine is a powerful drilling tool equipped with a rotating helical screw blade, used for boring holes in the ground for planting trees, installing posts, or digging fence posts and foundations in landscaping and construction projects.',
            Fact: 'Auger machines are indispensable for landscaping and construction tasks, providing efficient and precise drilling capabilities for planting trees, installing fences, and setting posts in various soil types. Their versatility and power make them ideal for digging holes of various sizes and depths, facilitating quick and accurate groundwork in residential, commercial, and agricultural projects.',
             qty:0,
      },
      {
            id: 84,
            name: "Tiller Machine",
            url: require('../../Images/Products/Products/Gardening Machines/Tiller Machine/power-tiller-machine.jpg'),
            images: [
                  require('../../Images/Products/Products/Gardening Machines/Tiller Machine/power-tiller-machine.jpg'),
                  require('../../Images/Products/Products/Gardening Machines/Tiller Machine/f300-honda-mini-power-tiller.jpg'),
                  require('../../Images/Products/Products/Gardening Machines/Tiller Machine/tiller_76e441de-2535-4c38-bef0-bbbe661a8285.webp'),
            ],
            he1: 'Tiller',
            he2: 'Machine',
            Description: 'A tiller machine is a motorized gardening tool equipped with rotating tines or blades, used for breaking up and aerating soil, preparing seedbeds, and cultivating garden plots in landscaping and agricultural activities.',
            Fact: 'Tiller machines are essential for preparing soil for planting by breaking up compacted earth, removing weeds, and incorporating amendments, promoting healthy root growth and optimal seed germination. Their versatility and efficiency make them invaluable for gardeners and farmers in establishing and maintaining productive gardens, vegetable patches, and agricultural fields.',
             qty:0,
      },
      {
            id: 85,
            name: "Chainsaw",
            url: require('../../Images/Products/Products/Gardening Machines/Chainsaw/chain-saw-machine.jpg'),
            images: [
                  require('../../Images/Products/Products/Gardening Machines/Chainsaw/chain-saw-machine.jpg'),
                  require('../../Images/Products/Products/Gardening Machines/Chainsaw/AG175_Chainsaw_2.jpg'),
                  require('../../Images/Products/Products/Gardening Machines/Chainsaw/Husqvarna_390_XP_Chainsaw_Machine,_24_Inches2.jpg'),
            ],
            he1: 'Chainsaw',
            he2: '',
            Description: 'A chainsaw is a portable power tool equipped with a rotating chain of sharp teeth, used for cutting through wood, trees, and branches in forestry, landscaping, and construction applications.',
            Fact: 'Chainsaws are essential for efficiently cutting through trees, branches, and lumber, making them indispensable for forestry, land clearing, and firewood processing tasks. Their powerful cutting ability and portability make them versatile tools for professionals and homeowners alike, facilitating quick and precise tree felling, pruning, and woodcutting operations.',
             qty:0,
      },


];



export default { ProductsImages, PotsImage, SoilImage, GardenTool, GardenMachine };