import { React, useContext, useState, useEffect } from 'react';
import Navbar2 from '../Navbar2';
import { motion, } from 'framer-motion';
import AnimateContext from './useContext';
import Connect from './Connect';
import ReactGA from "react-ga4";

const ImageComponent = ({ imageItems }) => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "OurWorks" })
    }, [])
    const [windowSize, setWindowSize] = useState(
        window.innerWidth);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const handleclick = () => {
        setVisible(true);
    }
    const mappedImageItems = imageItems.map(item => {
        const width = windowSize > 425 ? item.width : item.width2;
        return { ...item, width };
    });

    return (
        <div className=" tw-flex tw-flex-wrap sm:tw-gap-4 tw-gap-[10px] tw-justify-between tw-mt-4 ">
            {mappedImageItems.map(item => (
                <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1, delay: item.id * 0.21 } }} viewport={{ once: true }} key={item.id} className="tw-flex tw-justify-center tw-cursor-pointer tw-overflow-hidden ">
                    <img src={item.url} alt={`Image ${item.id}`} className=" workimg tw-h-[100%] hover:tw-scale-110 tw-transition-all tw-ease-in tw-duration-300" style={{ width: `${item.width}px` }} />
                </motion.div>
            ))}
        </div>
    );
};

function OurWorks() {
    
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "OurWorks" })
    }, [])
    const [windowSize, setWindowSize] = useState(
        window.innerWidth);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const handleclick = () => {
        setVisible(true);
    }
    const imageItems = [
        { id: 1, url: require('../Images/works/1.webp'), width: (windowSize * 0.76) / 3, width2: (windowSize * 0.8) / 3 },
        { id: 2, url: require('../Images/works/2.webp'), width: (windowSize * 0.76) / 3, width2: (windowSize * 0.8) / 3 },
        { id: 3, url: require('../Images/works/3.webp'), width: (windowSize * 0.76) / 3, width2: (windowSize * 0.8) / 3 },
        { id: 4, url: require('../Images/works/4.webp'), width: (windowSize * 0.76) * (5 / 16), width2: (windowSize * 0.8) * (5 / 16) },
        { id: 5, url: require('../Images/works/5.webp'), width: (windowSize * 0.76) * (7 / 16), width2: (windowSize * 0.8) * (7 / 16) },
        { id: 6, url: require('../Images/works/6.webp'), width: (windowSize * 0.76) * (4 / 16), width2: (windowSize * 0.8) * (4 / 16) },
        { id: 7, url: require('../Images/works/7.webp'), width: (windowSize * 0.76) * (3 / 16), width2: (windowSize * 0.8) * (3 / 16) },
        { id: 8, url: require('../Images/works/8.webp'), width: (windowSize * 0.76) * (5 / 16), width2: (windowSize * 0.8) * (5 / 16) },
        { id: 9, url: require('../Images/works/9.webp'), width: (windowSize * 0.76) * (5 / 16), width2: (windowSize * 0.8) * (5 / 16) },
        { id: 10, url: require('../Images/works/10.webp'), width: (windowSize * 0.76) * (3 / 16), width2: (windowSize * 0.8) * (3 / 16) },
        { id: 11, url: require('../Images/works/11.webp'), width: (windowSize * 0.76) * (4 / 16), width2: (windowSize * 0.8) * (4 / 16) },
        { id: 11, url: require('../Images/works/12.webp'), width: (windowSize * 0.76) * (4 / 16), width2: (windowSize * 0.8) * (4 / 16) },
        { id: 11, url: require('../Images/works/13.webp'), width: (windowSize * 0.76) * (4 / 16), width2: (windowSize * 0.8) * (4 / 16) },
        { id: 11, url: require('../Images/works/14.webp'), width: (windowSize * 0.76) * (4 / 16), width2: (windowSize * 0.8) * (4 / 16) },

    ];
    const imageItems2 = [
        { id: 4, url: require('../Images/works/15.webp'), width: (windowSize * 0.76) * (5 / 16), width2: (windowSize * 0.8) * (5 / 16) },
        { id: 5, url: require('../Images/works/16.webp'), width: (windowSize * 0.76) * (7 / 16), width2: (windowSize * 0.8) * (7 / 16) },
        { id: 6, url: require('../Images/works/17.webp'), width: (windowSize * 0.76) * (4 / 16), width2: (windowSize * 0.8) * (4 / 16) },
        { id: 7, url: require('../Images/works/18.webp'), width: (windowSize * 0.76) * (3 / 16), width2: (windowSize * 0.8) * (3 / 16) },
        { id: 8, url: require('../Images/works/19.webp'), width: (windowSize * 0.76) * (5 / 16), width2: (windowSize * 0.8) * (5 / 16) },
        { id: 9, url: require('../Images/works/20.webp'), width: (windowSize * 0.76) * (5 / 16), width2: (windowSize * 0.8) * (5 / 16) },
        { id: 10, url: require('../Images/works/21.webp'), width: (windowSize * 0.76) * (3 / 16), width2: (windowSize * 0.8) * (3 / 16) },
        { id: 11, url: require('../Images/works/22.webp'), width: (windowSize * 0.76) * (4 / 16), width2: (windowSize * 0.8) * (4 / 16) },
        { id: 11, url: require('../Images/works/23.webp'), width: (windowSize * 0.76) * (4 / 16), width2: (windowSize * 0.8) * (4 / 16) },
        { id: 11, url: require('../Images/works/24.webp'), width: (windowSize * 0.76) * (4 / 16), width2: (windowSize * 0.8) * (4 / 16) },
        { id: 11, url: require('../Images/works/25.webp'), width: (windowSize * 0.76) * (4 / 16), width2: (windowSize * 0.8) * (4 / 16) },

    ];
    const animate = useContext(AnimateContext)
    return (
        <motion.div initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }} className='tw-mb-[10%]'>
            <Navbar2 />
            <Connect />
            <div className=' tw-w-full tw-bg-ourwork tw-h-[60vh] tw-bg-no-repeat tw-bg-cover tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center'>
                <div className=' tw-font-Montserrat tw-text-white sm:tw-text-[36px] tw-text-2xl tw-font-bold tw-justify-center tw-items-center tw-text-center sm:tw-max-w-[60%] tw-max-w-[90%] sm:tw-leading-[60px] tw-mt-6'>Creating And Maintaining Beautiful Environment with Experiences Since 2013</div>
            </div>
            <div className=' tw-flex tw-justify-center tw-items-center tw-mt-130 '>
                <div className=' tw-flex tw-flex-col tw-justify-center sm:tw-min-w-[80%] sm:tw-max-w-[80%] tw-min-w-[90%] tw-max-w-[90%]'>
                    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { duration: 1, } }} viewport={{ once: true }} className=' sm:tw-text-4xl tw-text-2xl tw-text-center tw-text-[#707070] tw-font-semibold'>We Turn Ideas Into A Reality</motion.div>
                    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1, transition: { duration: 1, } }} viewport={{ once: true }} className='tw-text-sm tw-text-center tw-text-[#707070]  tw-font-normal tw-mt-8'>We transform your outdoor living spaces into functional and beautiful extensions of your property. We create unique landscapes that meet <p> your needs and exceed your expectations.</p> </motion.div>
                    <div className=' tw-mt-32'>
                        
                            {/* {imageItems.map((item) => (

                                <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1, delay: item.id * 0.21 } }} viewport={{ once: true }} key={item.id} className="tw-flex tw-justify-center tw-cursor-pointer tw-overflow-hidden ">
                                    <img src={item.url} alt={`Image ${item.id}`} className=" workimg tw-h-[100%] hover:tw-scale-110 tw-transition-all tw-ease-in tw-duration-300" style={{ width: `${item.width}px` }} />
                                </motion.div>
                            ))} */}
                            <ImageComponent imageItems={imageItems}/>
                        
                        {visible && <ImageComponent imageItems={imageItems2}/>}
                            {/* {imageItems.map((item) => (

                                <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1, delay: item.id * 0.21 } }} viewport={{ once: true }} key={item.id} className="tw-flex tw-justify-center tw-cursor-pointer tw-overflow-hidden ">
                                    <img src={item.url} alt={`Image ${item.id}`} className=" workimg tw-h-[100%] hover:tw-scale-110 tw-transition-all tw-ease-in tw-duration-300" style={{ width: `${item.width}px` }} />
                                </motion.div>
                            ))} */}
                            
                        
                        {!visible && <div className='tw-flex tw-justify-center tw-mt-[10px]  '><button className='tw-min-w-[180px] tw-min-h-[50px] tw-rounded-[30px] tw-text-[16px] tw-font-semibold tw-mt-[8%] tw-text-white tw-bg-[#8BCD20] slides tw-transition-shadow tw-ease-in' onClick={handleclick}>See more</button></div>}

                    </div>
                </div>
            </div>
        </motion.div>



    );
}

export default OurWorks;
