import React from "react";
function frame2() {
  return (
    <div className=" d-flex flex-column c-gray font-Montserrat slider-text ">
      <h5
        className="text-center tw-mb-5"
        style={{ fontWeight: "bolder", color: "black",fontSize: "24px"  }}
      >
        Hedge Border
      </h5>
      <p>
        A Hedge Border adds structure and elegance to any landscape, providing
        privacy, defining boundaries, and enhancing aesthetics. With a variety
        of species available, including evergreen and flowering options, you can
        customize the look to suit your preferences. Beyond their visual appeal,
        hedge borders also serve as natural windbreaks and noise barriers,
        creating a tranquil environment. Proper maintenance, including pruning
        and shaping, ensures a tidy appearance and promotes healthy growth.
        {/* Whether framing a garden or lining a pathway, a well-maintained hedge
        border adds charm and character to outdoor spaces. */}
      </p>
    </div>
  );
}

export default frame2;
