import React from 'react';
import '../Components/component.css'
import logowb from '../Images/logowb.png'
import underline from '../Images/underline.png'
import facebook from '../Images/facebook.png'
import whatsapp from '../Images/whatsapp.png'
import google from '../Images/google.png'
import instagram from '../Images/instagram.png'
import yt from '../Images/yt.png'
import { Link } from 'react-router-dom';

const divStyle = {
    color: 'white',
    fontFamily: "'Montserrat', sans-serif",
};

const h1Style = {
    color: 'white',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '27px',
    letterSpacing: '0em',
    textAlign: 'left',
};

const pStyle = {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0em',
    textAlign: 'left',
};
const ulStyle = {
    textDecoration: 'none',
}

function Footer() {

    return (
        <div class="ft tw-overflow-hidden tw-font-Montserrat" style={{ marginTop: '50px' }}>
            <div class='overlay' style={divStyle}>

                <div class=" tw-mx-[5%] sm:tw-mx-[2.5%]" >
                    <footer class="py-5">
                        <div class=" tw-grid sm:tw-grid-cols-5 tw-grid-cols-2 tw-gap-8 sm:tw-gap-0  tw-flex-row tw-justify-between ">
                            <div class="">

                                <div className=' flex-column'>
                                    <div>
                                        <img src={logowb} alt="" />
                                    </div>
                                    <div className='mt-3'>
                                        <h1 className='' style={{ fontSize: '18px', lineHeight: '21px', maxWidth: '180px', fontWeight: '600' }}>Compete Gardening Solution With Care </h1>
                                    </div>
                                    <div className=' tw-mt-6' style={{ maxWidth: '180px' }}>
                                        <p style={pStyle}>
                                            Once you hire us, yard work becomes a breeze! We draw strength from our customers' faith, setting us apart.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class=" tw-flex tw-flex-col ">
                                <div className=' tw-flex tw-flex-col tw-relative tw-font-Montserrat'>

                                    <h1 className=' tw-flex' style={h1Style}>Quick Links</h1>
                                    {/* <img className=' tw-flex tw-absolute tw-top-8' src={underline} alt='underline' style={{}} /> */}
                                </div>
                                <ul class=" tw-flex tw-flex-col tw-mt-4 tw-font-Montserrat " style={ulStyle}>
                                    <Link to='/ '><li class=" tw-text-[12px]  mb-2 p-0 tw-flex tw-text-white tw-font-Montserrat "><a className=' hover:tw-text-[#86241D]' href="#" >Home</a></li></Link>
                                    <Link to='/products'><li class=" tw-text-[12px] mb-2 p-0 tw-flex tw-text-white tw-font-Montserrat"><a href="#" >Products</a></li> </Link>
                                    <Link to='/ourServices'><li class=" tw-text-[12px]  mb-2 p-0 tw-flex tw-text-white tw-font-Montserrat"><a href="#" >Services</a></li>  </Link>
                                    <Link to='/'><li class=" tw-text-[12px]  mb-2 p-0 tw-flex tw-text-white tw-font-Montserrat"><a href="#" >Certificates</a></li>  </Link>
                                    <Link to='/clients'><li class=" tw-text-[12px]  mb-2 p-0 tw-flex tw-text-white tw-font-Montserrat"><a href="#" >Our Clients</a></li>  </Link>
                                    <Link to='/teams'><li class=" tw-text-[12px] mb-2 p-0 tw-flex tw-text-white tw-font-Montserrat"><a href="#" >Our Teams</a></li> </Link>
                                    <Link to='/about'><li class=" tw-text-[12px]  mb-2 p-0 tw-flex tw-text-white tw-font-Montserrat"><a href="#" >About Us</a></li>  </Link>
                                </ul>
                            </div>
                            <div class=" tw-flex tw-flex-col tw-justify-between">
                                <div className=' tw-flex tw-flex-col'>
                                    <div className=' tw-flex tw-flex-col tw-relative'>

                                        <h1 className=' tw-flex' style={h1Style}>Address</h1>
                                        {/* <img className='  tw-flex tw-absolute tw-top-8 ' src={underline} alt='underline' /> */}
                                    </div>
                                    <p className=' tw-flex tw-mt-3' style={pStyle}>Mohanpur, Barrackpore <br />Kolkata - 700121</p>
                                </div>
                                <div className=' tw-flex tw-flex-col tw-mt-8 sm:tw-mt-0'>
                                    <div className=' tw-flex tw-flex-col tw-relative'>

                                        <h1 className='tw-font-Montserrat' style={h1Style}>Services</h1>
                                        {/* <img className='  tw-flex tw-absolute tw-top-8 ' src={underline} alt='underline' style={{}} /> */}
                                    </div>
                                    <ul class=" tw-flex tw-flex-col tw-mt-4 " style={ulStyle}>
                                        <li class="tw-text-[12px] tw-font-Montserrat  mb-2 p-0 tw-flex tw-text-white "><Link className=' hover:tw-text-[#86241D]' to='/plantSelling' >Plant Selling</Link></li>
                                        <li class="tw-text-[12px] tw-font-Montserrat   mb-2 p-0 tw-flex tw-text-white"><Link to='/landscaping' >Landscaping</Link></li>
                                        <li class="tw-text-[12px] tw-font-Montserrat   mb-2 p-0 tw-flex tw-text-white"><Link to='/maintenance' >Maintenance</Link></li>
                                    </ul>
                                </div>
                            </div>

                            <div class=" tw-flex tw-flex-col tw-justify-between">
                                <div className=' tw-flex tw-flex-col'>
                                    <div className='tw-flex tw-flex-col tw-relative'>

                                        <h1 className='tw-flex tw-flex-col' style={h1Style}>Legal Links</h1>
                                        {/* <img className=' tw-flex tw-absolute tw-top-8' src={underline} alt='underline' style={{}} /> */}
                                    </div>
                                    <ul class=" tw-flex tw-flex-col tw-mt-4 " style={ulStyle}>
                                        <li class="tw-text-[12px] tw-font-Montserrat  mb-2 p-0 tw-flex tw-text-white "><a className=' hover:tw-text-[#86241D]' href="#" >Terms and Conditions</a></li>
                                        <li class="tw-text-[12px] tw-font-Montserrat mb-2 p-0 tw-flex tw-text-white"><a href="#" >Privacy Policy</a></li>
                                        <li class="tw-text-[12px] tw-font-Montserrat  mb-2 p-0 tw-flex tw-text-white"><a href="#" >FAQS</a></li>
                                    </ul>
                                </div>
                                <div className=' tw-flex tw-flex-col tw-mt-[36px]'>
                                    <div className=' tw-flex tw-flex-col tw-relative'>

                                        <h1 style={h1Style}>Contact</h1>
                                        {/* <img className='  tw-flex tw-absolute tw-top-8 ' src={underline} alt='underline' style={{}} /> */}
                                    </div>
                                    <p className=' tw-flex sm:tw-flex-col tw-mt-3 tw-min-w-[200px] ' style={pStyle}>Toll Free No. : <br className='sm:tw-hidden tw-block' />1800-121-2441<br /><br className='sm:tw-hidden tw-block' />Email ID : <br className='sm:tw-hidden tw-block' /> taraangmithun@gmail.com</p>
                                </div>

                            </div>

                            <div class=" tw-flex tw-flex-col tw-justify-between">
                                <div className=' tw-flex tw-flex-col'>
                                    <form className=' tw-flex tw-flex-col'>
                                        <div className=' tw-flex tw-flex-col tw-relative'>

                                            <h1 className=' tw-flex tw-flex-col tw-max-w-[300px] tw-w-[260px]' style={h1Style}>Want to get more updates?</h1>
                                            {/* <img className=' tw-hidden sm:tw-flex tw-absolute sm:tw-top-8 tw-top-[3.8rem]' src={underline} alt='underline' style={{}} /><img className=' sm:tw-visible tw-hidden sm:tw-flex tw-absolute sm:tw-top-8 tw-top-[3.8rem] sm:tw-ml-[6.4rem] tw-ml-[6.4rem]' src={underline} alt='underline' style={{}} /> */}
                                        </div>

                                        <div class=" tw-font-Montserrat tw-flex tw-flex-row tw-mt-4 tw-h-[30px] sm:tw-w-auto tw-w-[250px] footer-input">
                                            <label for="newsletter1" class="visually-hidden"></label>
                                            <input id="newsletter1" type="text" class="form-control placeholder:tw-text-sm" style={{ borderRadius: '5px' }} placeholder="Put your email id"></input>
                                            <button class="btn btn-primary " type="button" style={{ backgroundColor: '#66A400', border: 'none', borderRadius: '0px 5px 5px 0px', marginLeft: '-5px' }}><div style={{  width: '65px', fontSize: '12px', fontWeight: 'lighter', textAlign: 'center' }}>Subscribe</div></button>
                                        </div>
                                    </form>
                                </div>
                                <div className=' tw-flex tw-flex-col tw-mt-8 sm:tw-mt-0'>
                                    <div className=' tw-flex tw-flex-col tw-relative'>

                                        <h1 className=' tw-flex tw-flex-col' style={h1Style}>Follow Us</h1>
                                        {/* <img className=' tw-flex tw-absolute tw-top-8 tw-hidden sm:tw-flex' src={underline} alt='underline' style={{}} /> */}
                                    </div>
                                    <div className=' tw-flex tw-flex-row tw-mt-4'>
                                        <a target='__blank' href="https://www.instagram.com/___taraaang_landscape___?igsh=MTlqOWp6MDl6ZjR5cg=="><img src={instagram} className=' sm:tw-px-2 socials tw-px-[5px] ' /></a>
                                        <a target='__blank' href="https://www.facebook.com/people/Taraaang-Landscape/100054639240614/?paipv=0&eav=Afbt2RFaOqR7LHPJe5c4HarCuj5q5wLVn2mRqV1u3Rr8hgOQXju9_IvfCCKOqUe8tmY&_rdr"><img src={facebook} className=' sm:tw-px-2 socials tw-px-[5px] ' /></a>
                                        <a href="http://https://wa.me/919339023422?text=Hello,+I+am+interested+in+knowing+more+about+TARAaang+Landscapes" target='__blank'><img src={whatsapp} className=' sm:tw-px-2 socials tw-px-[5px] ' /></a>
                                        <a href="https://www.google.com/localservices/prolist?g2lbs=AIQllVw95ja2Nygd5TpJ6T4oSdvHTH3XjRjBvIy--DedZSw-nDBf9Sp1Hu32vKoXppfYmMbLdCVzlHC3QDChNwOkrJXxPfDmMVP9R4jKbk5gdcCAahzAuoIiCuNIHQWalGk9AxD98IGJvaIkCHXeNvIsBrTzhYAWAg%3D%3D&hl=en-IN&gl=in&cs=1&ssta=1&q=best%20landscape%20in%20kolkata&oq=best%20landscape%20in%20kolkata&slp=MgA6HENoTUkzSVdPeUtPNWhBTVZqcU5tQWgwdnhRR2hSAggCYACSAbQCCg0vZy8xMW10bHloNGcwCg0vZy8xMWg0bGNyZHc2Cg0vZy8xMWY0MDA5c3BqCg0vZy8xMWdza2xoZ2szCg0vZy8xMWtsZjEwXzZ0CgwvZy8xaGMxdDg1ZnIKDS9nLzExYndkbTQ0YngKDS9nLzExYzU1MGx6MGYKDS9nLzExc3ZybjR3eGsKCy9nLzF2enFxcDY1Cg0vZy8xMWg3MWgzZDB4Cg0vZy8xMWdkbWN0bWprCg0vZy8xMWNzNGIyajBuCg0vZy8xMWhuNjI5Y2JyCg0vZy8xMWh6c2xkM2g1Cg0vZy8xMW5mdDU5cmQ0CgwvZy8xaGM1M210Z2YKDS9nLzExYng5dHo5djgKDS9nLzExajNzM3N0NzMKDS9nLzExYjZ2czNydmQSBBICCAESBAoCCAGaAQYKAhcZEAA%3D&src=2&serdesk=1&sa=X&ved=2ahUKEwjDtobIo7mEAxWdTGwGHQ94AHgQjGp6BAhiEAE&spp=Cg0vZy8xMWNzNGIyajBuOqwCV2g4UUFCQUJJaGxpWlhOMElHeGhibVJ6WTJGd1pTQnBiaUJyYjJ4cllYUmhtZ0VqUTJoYVJGTlZhRTVOUnpsdVV6QldTbEV3Um01VFZVNVBUa2hhZFZKcmNHNUZRVVdxQVd3S0NpOXRMekF5TlhNemNUQUtDQzl0THpCamRuYzVFQUVxRWlJT1ltVnpkQ0JzWVc1a2MyTmhjR1VvQURJZkVBRWlHeVc0eUhOU1NWWGVfWW42d3YwY0NRMEhnMDNfa1g1bHNjWXRqaklkRUFJaUdXSmxjM1FnYkdGdVpITmpZWEJsSUdsdUlHdHZiR3RoZEdIQ0FTY0lmaUlqUTJoYVJGTlZhRTVOUnpsdVV6QldTbEV3Um01VFZVNVBUa2hhZFZKcmNHNUZRVVU9&scp=ChhnY2lkOmxhbmRzY2FwZV9hcmNoaXRlY3QSSRISCWf2CEnbgvg5EbwsbI_mMONDGhIJyxufaAB3AjoRzhRx7JWHIFwiB0tvbGthdGEqFA1k6GENFRhilzQd31t7DSXXFro0MAAaDmJlc3QgbGFuZHNjYXBlIhliZXN0IGxhbmRzY2FwZSBpbiBrb2xrYXRhKhNMYW5kc2NhcGUgYXJjaGl0ZWN0#ts=4" target='__blank'><img src={google} className=' sm:tw-px-2 socials tw-px-[5px] ' /></a>
                                        <a target='__blank' href="https://youtube.com/@teamtaraaang?si=JgWkG-8BNauUBf9Y"><img src={yt} className=' sm:tw-px-2 socials tw-px-[5px] ' /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>

                </div>
                <div class="d-flex flex-column justify-content-center align-items-center tw-relative " style={{ maxWidth: '100%' }}>
                    <div style={{ backgroundColor: 'white', height: '1px', width: '95%' }}></div>
                     <div className='tw-flex tw-justify-between tw-items-center tw-w-[96%] tw-p-[1%]'>
                     <a href="https://www.hitwebcounter.com" target="_blank">
                    <img src="https://hitwebcounter.com/counter/counter.php?page=12704504&style=0006&nbdigits=5&type=page&initCount=10000" title="Counter Widget" Alt="Visit counter For Websites"   border="0" /></a>       
                    <p style={{ fontSize: '12px', fontWeight: 'lighter', textAlign:'center' }} className=' '>&copy; Copyright@2023 Taraaang Landscape. All Rights Reserved.</p>
                    <div style={{ fontSize: '12px', fontWeight: 'lighter', textAlign:'center' }} className=' '>
                    <span>Designed & Developed by Sicu-aura Innoviz</span>
                     </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Footer;