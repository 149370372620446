const Images = {
    clients: [
        require('../Images/partners/partner1.png'),
        require('../Images/partners/partner2.png'),
        require('../Images/partners/partner3.png'),
        require('../Images/partners/partner4.png'),
        require('../Images/partners/partner5.png'),
        require('../Images/partners/partner6.png'),
        require('../Images/partners/partner8.png'),
    ],   
    founder: require('../Images/Team/ceo.png'),
    HODs: [
        require('../Images/Team/1.jpeg'),
        require('../Images/Team/2.jpeg'),
        require('../Images/Team/3.jpeg'),
        require('../Images/Team/4.jpeg'),
    ],
    Incharge: [
        require('../Images/Team/37.jpeg'),
        
        require('../Images/Team/21.jpeg'),
        require('../Images/Team/22.jpeg'),
        require('../Images/Team/23.jpeg'),
    ],
    Management: [
        require('../Images/Team/24.jpeg'),
        require('../Images/Team/25.jpeg'),
        require('../Images/Team/26.jpeg'),
        require('../Images/Team/27.jpeg'),
    ],
    Executives:[
        require('../Images/Team/28.jpeg'),
        require('../Images/Team/29.jpeg'),
        require('../Images/Team/30.jpeg'),
        require('../Images/Team/31.jpeg'),
    ],
    SiteIncharge:[
        require('../Images/Team/36.jpeg'),
        require('../Images/Team/33.jpeg'),
        require('../Images/Team/34.jpeg'),
        require('../Images/Team/35.jpeg'),
    ],
    Sitesup:[
        require('../Images/Team/20.jpeg'),
        require('../Images/Team/38.jpeg'),
        require('../Images/Team/32.jpeg'),
        require('../Images/Team/21.jpeg'),
    ],

    maintainance: require('./maintainance.png'),
    landscaping: require('./landscaping.png'),
    plantselling: require('./plantselling.png'),
    logo_contact: require('./logo_contact.png'),
    email: require('./email.png'),
    call: require('./call.png'),
    location: require('./location.png'),
    website: require('./website.png'),
    phoneh: require('./phoneh.png'),
    emailh: require('./emailh.png'),
    maintainance1: require('./maintenance1.png'),
    maintainance2: require('./maintenance2.png'),
    weeding: require('./maintenance/weeding.png'),
    watering: require('./maintenance/watering.png'),
    trimming: require('./maintenance/trimming.png'),
    grafting: require('./maintenance/grafting.png'),
    ground: require('./maintenance/ground.png'),
    nourish: require('./maintenance/nourish.png'),
    shifting: require('./maintenance/shifting.png'),
    prepare: require('./maintenance/prepare.png'),
    hormone: require('./maintenance/hormone.png'),
    mulching: require('./maintenance/moulch.png'),
    report: require('./maintenance/report.png'),
    mixing: require('./maintenance/mixing.png'),
    shadowm: require('./maintenance/Ellipse 19.png'),
    rect1: require('./Rectangle 29.png'),
    rect2: require('./Rectangle 30.png'),
    rect3: require('./Rectangle 31.png'),
    tick: require('./tick.png'),
    yt: require('./yt.png'),
    c1:require('../Images/certificates/c1.jpg'),
    c2:require('../Images/certificates/c3.jpg'),
    c3:require('../Images/certificates/c4.jpg'),
    c4:require('../Images/certificates/c5.jpg'),
    c5:require('../Images/certificates/c6.jpg'),
    c6:require('../Images/certificates/c2.jpg'),
    bin:require('./bin.png'),
    cart:require('./cart.png'),
    circle:require('./circle.png'),
    whatsapp:require('./whatsapp.png')
};

const images = require.context('../Images/Team/Employees', true);
const Employees = images.keys().map(image => images(image));

export default { Images, Employees };
