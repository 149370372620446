import React, { useEffect } from 'react';
import AnimateContext from '../Components/useContext'
import { useContext } from 'react'
import Images from '../Images';
import { motion } from 'framer-motion'
import Connect from '../Components/Connect';
import Navbar2 from '../Navbar2';
import { Link, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";



function OurServices() {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "OurServices" })
    }, [])
    const animate = useContext(AnimateContext)
    const navigate = useNavigate();
    function handleClick1() {
        navigate("/plantSelling");
    }
    function handleClick2() {
        navigate("/maintenance");
    }
    function handleClick3() {
        navigate("/landscaping");
    }


    return (
        <motion.div initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }} className=' tw-mb-[10%] tw-overflow-hidden'>
            <Navbar2 />
            <Connect />
            <div className=' tw-w-full tw-bg-service tw-h-[42vh] tw-bg-no-repeat tw-bg-cover tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center'>
                <div className=' tw-font-Montserrat tw-text-white tw-text-[36px] tw-font-bold tw-justify-center tw-items-center'>OUR SERVICES</div>
            </div>
            <div className=' tw-flex tw-justify-center tw-items-center tw-mt-[15%] sm:tw-mt-130'>
                <div className=' w-[80%] tw-flex tw-min-w-[80%] tw-max-w-[90%] tw-flex-col '>
                    <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 0.5 } }} className=' tw-flex tw-justify-start tw-flex-col'>
                        <div className=' tw-font-semibold sm:tw-text-[36px] tw-text-[24px] '>
                            What&nbsp;{
                                <span className="c-green">
                                    <span className={` ${animate ? "logo is-animetion" : ""}`}>
                                        <span>W</span>
                                        <span>e</span>
                                    </span>
                                </span>
                            }&nbsp;Do?
                        </div>



                        <div className=' tw-mt-9 sm:tw-text-[20px] tw-text-[14px]  tw-font-Montserrat tw-font-extralight tw-text-[#707070] tw-text-justify sm:tw-leading-[40px] '>

                            We offer return/replacement of Products ordered on Starling Silk within 15 days of delivery of the order. We only ask that you don't use the product and preserve its original condition, tags, and packaging. You are welcome to try on a product but please take adequate measure to preserve its condition. All items to be returned or exchanged must be unused and in their original condition with all original tags and packaging intact.
                        </div>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 0.5 } }} className=' tw-flex tw-justify-start tw-flex-col  tw-mt-[15%] sm:tw-mt-130'>
                        <div className=' tw-font-semibold sm:tw-text-[36px] tw-text-[24px] '>
                            What&nbsp;{
                                <span className="c-green">
                                    <span className={` ${animate ? "logo is-animetion" : ""}`}>
                                        <span>K</span><span>i</span><span>n</span><span>d</span><span>&nbsp;</span><span>o</span><span>f</span><span>&nbsp;</span><span>S</span><span>e</span><span>r</span><span>v
                                        </span><span>i</span><span>c</span><span>e</span><span>s</span>

                                    </span>
                                </span>
                            }&nbsp;We Provide ?
                        </div>



                        <div className=' tw-mt-9 sm:tw-text-[20px] tw-text-[14px] tw-font-Montserrat tw-font-extralight tw-text-[#707070] tw-text-justify sm:tw-leading-[40px]' >
                            We endeavor to process your refunds within 15 business days from the date the returned product clears the Q.C at the Warehouse . However, the refund is subject to the transaction and processing time taken by the bank after the NEFT is initiated by Company. In case of any refund discrepancies, Starling Silk Mills Pvt Ltd. may at its sole discretion, request you to share with us a screenshot of your bank statement. We understand that in some scenarios, the Product may take more than 7 days to reach our warehousing and Quality Check facilities and thus the Q.C may take time. In such scenario, we may process the refund amounts intermittently but, incase the Product does not clear the Quality check, we reserve all right to take appropriate steps to recover the amount already credited to your account. Starling Silk Mills Pvt Ltd may refuse a refund request if we find evidence of fraud, refund abuse or other manipulative behavior that entitles Starling Silk Mills Pvt Ltd to a claim against you.
                        </div>
                    </motion.div>
                    <div className=' tw-flex tw-justify-between sm:tw-flex-row tw-flex-col-reverse  tw-mt-[15%] sm:tw-mt-130 tw-min-h-[450px]'>
                        <div className=' tw-flex tw-justify-between tw-flex-col  sm:tw-pr-8'>
                            <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 0.5 } }} className=' tw-font-semibold sm:tw-text-[36px] tw-text-[24px] sm:tw-mt-0 tw-mt-[5%] '>

                                {
                                    <span className="c-green">
                                        <span className={` ${animate ? "logo is-animetion" : ""}`}>
                                            <span>P</span><span>l</span><span>a</span><span>n</span><span>t</span>

                                        </span>
                                    </span>
                                }&nbsp;Selling
                            </motion.div>


                            <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 0.5 } }} className='  sm:tw-text-[20px] tw-text-[14px] tw-font-Montserrat tw-font-extralight tw-text-[#707070] tw-text-justify sm:tw-mt-0 tw-mt-5 sm:tw-leading-[40px]'>

                                Nestled amidst sun-dappled leaves and fragrant blooms lies a haven for those with earthy hearts. Our nursery isn't just a shop, it's a canvas where nature's artistry unfolds. We curate a vibrant symphony of vibrant flowers, stately trees, and delicate saplings, each waiting to weave their verdant magic into your life. Whether you seek a burst of color for your balcony, a towering guardian for your garden, or a tiny sprout to nurture, our haven whispers: let life blossom. Come, wander our leafy aisles, breathe in the sweet perfume of possibility, and discover the perfect green companion to bring your world alive.
                            </motion.div>
                            <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 0.5 } }} className=" tw-text-center tw-mt-5 sm:tw-mt-0  tw-flex ">
                                <button
                                    className="slides tw-font-Montserrat tw-text-lg tw-w-[200px] tw-h-[53px] tw-flex tw-flex-row sm:tw-justify-between tw-justify-evenly tw-items-center "
                                    style={{

                                        backgroundColor: "#66A400",
                                        color: "white",
                                        borderRadius: "10px",
                                        padding: "12px 28px",
                                        border: "none",
                                        transition: "box-shadow 0.5s ease",
                                    }}
                                    onClick={handleClick1}
                                >
                                    {"Explore more "}<i class="bi bi-chevron-right"></i>

                                </button>
                            </motion.div>
                        </div>
                        <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 0.5 } }} className=' tw-flex sm:tw-ml-8 sm:tw-min-w-[450px] sm:tw-mt-0 tw-mt-5 '>
                            <img src={Images.Images.plantselling} className=' tw-w-full tw-h-full' />
                        </motion.div>
                    </div>
                    <div className=' tw-flex tw-justify-between sm:tw-flex-row tw-flex-col  tw-mt-[15%] sm:tw-mt-130 tw-min-h-[450px]'>
                        <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 0.5 } }} className=' tw-flex tw-mr-8 sm:tw-min-w-[450px] sm:tw-mt-0 tw-mt-5 '>
                            <img src={Images.Images.landscaping} className=' tw-w-full tw-h-full' />
                        </motion.div>
                        <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 0.5 } }} className=' tw-flex tw-justify-between tw-flex-col  sm:tw-pl-8'>
                            <div className=' tw-font-semibold sm:tw-text-[36px] tw-text-[24px] sm:tw-mt-0 tw-mt-[5%]'>
                                Land{
                                    <span className="c-green">
                                        <span className={` ${animate ? "logo is-animetion" : ""}`}>
                                            <span>s</span><span>c</span><span>a</span><span>p</span><span>i</span><span>n</span><span>g</span>

                                        </span>
                                    </span>
                                }
                            </div>



                            <div className=' sm:tw-text-[20px] tw-text-[14px] tw-font-Montserrat tw-font-extralight tw-text-[#707070] tw-text-justify sm:tw-mt-0 tw-mt-5 sm:tw-leading-[40px] '>

                                We're passionate about crafting exquisite, customized gardens that reflect your unique vision. From lush havens to vibrant courtyards, our skilled landscapers bring your dreams to life. Whether you crave a tranquil koi pond, a fragrant rose garden, or an edible masterpiece, we handle every aspect, from design and plant selection to construction and ongoing maintenance. Let us transform your barren patch into a thriving sanctuary, a verdant extension of your own home. Contact [Company Name] today, and watch your green oasis blossom!
                            </div>
                            <div className=" tw-text-center tw-mt-5 sm:tw-mt-0  tw-flex ">
                                <button
                                    className="slides tw-font-Montserrat tw-text-lg tw-w-[200px] tw-h-[53px] tw-flex tw-flex-row sm:tw-justify-between tw-justify-evenly tw-items-center"
                                    style={{

                                        backgroundColor: "#66A400",
                                        color: "white",
                                        borderRadius: "10px",
                                        padding: "12px 28px",
                                        border: "none",
                                        transition: "box-shadow 0.5s ease",
                                    }}
                                    onClick={handleClick3}
                                >
                                    {"Explore more "}<i class="bi bi-chevron-right"></i>

                                </button>
                            </div>
                        </motion.div>

                    </div>



                    <div className=' tw-flex tw-justify-between sm:tw-flex-row tw-flex-col-reverse  tw-mt-[15%] sm:tw-mt-130 tw-min-h-[450px]'>
                        <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 0.5 } }} className=' tw-flex tw-justify-between tw-flex-col  sm:tw-pr-8'>
                            <div className=' tw-font-semibold sm:tw-text-[36px] tw-text-[24px] sm:tw-mt-0 tw-mt-[5%]'>
                                Maintenance
                            </div>
                            <div className='  sm:tw-text-[20px] tw-text-[14px] tw-font-Montserrat tw-font-extralight tw-text-[#707070] tw-text-justify sm:tw-mt-0 tw-mt-5 sm:tw-leading-[40px] '>

                                Imagine your garden flourishing, bursting with vibrant blooms and verdant leaves, without lifting a finger. That's the magic of <span className=' tw-text-[#DA3732] tw-font-semibold'>TARAaang Landscaping</span>, your one-stop shop for horticultural bliss. From meticulous lawn mowing and expert pruning to weed taming and seasonal planting, our dedicated team of green-thumbed professionals pampers your outdoor haven. Whether you crave a vibrant oasis, a meticulously manicured lawn, or a thriving vegetable patch, <span className=' tw-text-[#DA3732] tw-font-semibold'>TARAaang Landscaping</span>, its services to your unique vision. So, ditch the gardening gloves, kick back with a refreshing lemonade, and let us cultivate your dream garden, one bloom at a time.
                            </div>
                            <div className=" tw-text-center sm:tw-mt-0 tw-mt-5  tw-flex ">
                                <button
                                    className="slides tw-font-Montserrat tw-text-lg tw-w-[200px] tw-h-[53px] tw-flex tw-flex-row sm:tw-justify-between tw-justify-evenly tw-items-center"
                                    style={{

                                        backgroundColor: "#66A400",
                                        color: "white",
                                        borderRadius: "10px",
                                        padding: "12px 28px",
                                        border: "none",
                                        transition: "box-shadow 0.5s ease",
                                    }}
                                    onClick={handleClick2}
                                >
                                    {"Explore more "}<i class="bi bi-chevron-right"></i>

                                </button>
                            </div>
                        </motion.div>
                        <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 0.5 } }} className=' tw-flex sm:tw-ml-8 sm:tw-mt-0 tw-mt-5 sm:tw-min-w-[450px] '>
                            <img src={Images.Images.maintainance} className=' tw-w-full tw-h-full' />
                        </motion.div>
                    </div>

                </div>
            </div>

        </motion.div>

    );
}

export default OurServices;
