import React from "react";
function frame7() {
  return (
    <div className=" d-flex flex-column c-gray font-Montserrat slider-text">
      <h5
        className="text-center tw-mb-5"
        style={{ fontWeight: "bolder", color: "black", fontSize: "24px" }}
      >
        Low Cost Maintenance
      </h5>
      <p>
  

Experience top-notch maintenance without breaking the bank, with services starting at just  <span className=" tw-font-bold tw-text-[#86241D]">Rs. 99</span>. Our budget-friendly approach ensures that everyone can enjoy a well-maintained outdoor space. Trust our skilled professionals to deliver quality results tailored to your needs, without compromising on affordability. <br /><br /> <span className=" tw-font-bold tw-text-black">Explore more services on the Maintenance section!</span>
      </p>
    </div>
  );
}

export default frame7;
