import React from "react";
function frame1() {
  return (
    <div className=" c-gray font-Montserrat slider-text">
      <h5
        className="text-center tw-mb-5"
        style={{ fontWeight: "bolder", color: "black", fontSize: "24px" }}
      >
        Roof-Top Gardening
      </h5>
      <p>
        A verdant tapestry unfurls above the concrete jungle, where sun-kissed
        tomatoes peek from leafy vines and fragrant herbs mingle with vibrant
        blooms. Butterflies flit among lavender and rosemary, while bees buzz
        contentedly amidst rooftop tranquility. Rooftop suppers under starlit
        skies complete the idyllic haven, a verdant escape mere steps from the
        bustling city below.
      </p>
    </div>
  );
}

export default frame1;
