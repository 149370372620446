import React, { useEffect } from 'react';
import Navbar2 from '../../Navbar2';
import AnimateContext from '../../Components/useContext'
import { useContext } from 'react'
import Images from '../../Images';
import { motion } from 'framer-motion';
import Connect from '../../Components/Connect';
import { Navigate, useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";

function Maintenance() {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Maintenance" })
    }, [])
    const animate = useContext(AnimateContext)
    const navigate = useNavigate();
    const handleclick = () => {
        navigate('/contact');
    }
    return (

        <motion.div initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }} className=' tw-mb-[10%] '>
            <div className='sm:-tw-mt-[173px]  tw-absolute'> <Navbar2 /></div>
            <Connect />
            <div className=' tw-flex tw-justify-center tw-items-center sm:tw-mt-[180px] tw-overflow-hidden'>
                <div className=' tw-flex tw-flex-col sm:tw-min-w-[80%] sm:tw-max-w-[80%] tw-min-w-[90%] tw-max-w-[90%]'>
                    <div className='tw-flex tw-flex-col sm:tw-flex-row tw-justify-between'>
                        <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }} className=' tw-flex tw-flex-col tw-font-Montserrat'>
                            <div className=' tw-flex tw-font-extrabold sm:tw-text-5xl tw-text-3xl tw-flex-col sm:tw-max-w-[70%] tw-max-w-[100%] sm:tw-mt-10 tw-mt-[8rem] tw-leading-[40px] sm:tw-leading-[60px]'
                            >
                                {
                                    <span className="c-green">
                                        <span className={` ${animate ? "logo is-animetion" : ""}`}>
                                            <span>P</span>
                                            <span>e</span>
                                            <span>a</span>
                                            <span>c</span>
                                            <span>e</span>
                                            <span>&nbsp;</span>
                                            <span>o</span>
                                            <span>f</span>
                                            <span>&nbsp;</span>
                                            <span>m</span>
                                            <span>i</span>
                                            <span>n</span>
                                            <span>d</span>
                                        </span>

                                    </span>
                                }With our Guaranteed Top-notch&nbsp;
                                {
                                    <span className="c-green">
                                        <span className={` ${animate ? "logo is-animetion" : ""}`}>
                                            <span>M</span>
                                            <span>a</span>
                                            <span>i</span>
                                            <span>n</span>
                                            <span>t</span>
                                            <span>e</span>
                                            <span>n</span>
                                            <span>a</span>
                                            <span>n</span>
                                            <span>c</span>
                                            <span>e</span>
                                        </span>
                                    </span>
                                }
                            </div>
                            <div className=' smm:tw-mt-20 tw-mt-10 sm:tw-max-w-[60%] tw-max-w-[100%] tw-font-light tw-text-[14px] tw-leading-[25.2px]'>
                                <p>Enjoy peace of mind with our comprehensive guarantee. We stand behind our work, so you can be confident that your property is receiving the highest quality care. With our services, your property will look and function its best, year after year.</p>
                            </div>
                        </motion.div>
                        <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }} className='tw-flex tw-relative tw-flex-col sm:tw-mt-10 tw-mt-8 tw-items-end sm:tw-mr-0 tw-mr-[35px] '>
                            <img className='sm:tw-w-[50vw] tw-w-[60%] tw-flex ' src={Images.Images.maintainance2} alt="" />
                            <img className='sm:tw-w-full tw-w-[60%] tw-absolute tw-flex tw-z-10 sm:tw-top-[45%] tw-left-[11%] tw-top-[60%] sm:tw-left-[-40%] ' src={Images.Images.maintainance1} alt="" />
                        </motion.div>

                    </div>
                    <div className=' sm:tw-mt-[15%] tw-mt-[50%] tw-flex tw-flex-col'>
                        <motion.div initial={{ opacity: 0, y: -150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }} className=' tw-font-semibold sm:tw-text-[36px] tw-text-[24px]  tw-flex tw-justify-center '>
                            Our&nbsp;{
                                <span className="c-green">
                                    <span className={` ${animate ? "logo is-animetion" : ""}`}>
                                        <span>M</span>
                                        <span>a</span>
                                        <span>i</span>
                                        <span>n</span>
                                        <span>t</span>
                                        <span>e</span>
                                        <span>n</span>
                                        <span>a</span>
                                        <span>n</span>
                                        <span>c</span>
                                        <span>e</span>
                                    </span>
                                </span>
                            }&nbsp;Services
                        </motion.div>
                        <div className=' tw-grid tw-mt-[15%] sm:tw-mt-[5%] tw-grid-cols-2 sm:tw-grid-cols-3 tw-font-Montserrat sm:tw-gap-20 tw-gap-8 '>
                            <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1, delay: 0.2 } }} className='  tw-relative tw-max-w-[300px] tw-text-justify   '>
                                <img className=' tw-z-10' src={Images.Images.watering} alt="" />
                                <img src={Images.Images.shadowm} className=' tw-absolute tw-ml-[-1px] -tw-mt-4 -tw-z-10' alt="" />
                                <h1 className=' sm:tw-text-[18px] tw-text-[14px] tw-font-extrabold sm:tw-mt-6 tw-mt-3 '>Watering</h1>
                                <p className=' tw-mt-4 tw-text-[12px]'>Water deeply and infrequently. This encourages roots to grow deep and strong, making plants more resilient to drought.</p>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1, delay: 0.25 } }} className=' tw-relative  tw-max-w-[300px] tw-text-justify '>
                                <img className=' tw-z-10' src={Images.Images.weeding} alt="" />
                                <img src={Images.Images.shadowm} className=' tw-absolute tw-ml-[-1px] -tw-mt-4 -tw-z-10' alt="" />
                                <h1 className='  sm:tw-text-[18px] tw-text-[14px]  tw-font-extrabold sm:tw-mt-6 tw-mt-3 '>Weeding</h1>
                                <p className=' tw-mt-4 tw-text-[12px]'>Keep your landscape pristine with professional weeding services, ensuring the removal of unwanted plants and invasive weeds to maintain the health and beauty of your outdoor space.</p>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1, delay: 0.3 } }} className='  tw-relative tw-max-w-[300px] tw-text-justify  '>
                                <img className=' tw-z-10' src={Images.Images.hormone} alt="" />
                                <img src={Images.Images.shadowm} className=' tw-absolute tw-ml-[-1px] -tw-mt-4 -tw-z-10' alt="" />
                                <h1 className='  sm:tw-text-[18px] tw-text-[14px]  tw-font-extrabold sm:tw-mt-6 tw-mt-3 '>Hormone Spray</h1>
                                <p className=' tw-mt-4 tw-text-[12px]'>Optimize plant growth and health with our hormone spray maintenance service, tailored to stimulate root development, flowering, and overall vigor for a thriving and resilient landscape.</p>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1, delay: 0.35 } }} className=' tw-relative  tw-max-w-[300px] tw-text-justify  '>
                                <img className=' tw-z-10' src={Images.Images.trimming} alt="" />
                                <img src={Images.Images.shadowm} className=' tw-absolute tw-ml-[-1px] -tw-mt-4 -tw-z-10' alt="" />
                                <h1 className='  sm:tw-text-[18px] tw-text-[14px]  tw-font-extrabold sm:tw-mt-6 tw-mt-3 '>Trimming Hedge</h1>
                                <p className=' tw-mt-4 tw-text-[12px]'>Elevate the appearance of your landscape with our professional hedge trimming service, ensuring precise shaping and maintenance to enhance curb appeal and promote healthy growth.</p>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1, delay: 0.4 } }} className='  tw-relative tw-max-w-[300px] tw-text-justify  '>
                                <img className=' tw-z-10' src={Images.Images.grafting} alt="" />
                                <img src={Images.Images.shadowm} className=' tw-absolute tw-ml-[-1px] -tw-mt-4 -tw-z-10' alt="" />
                                <h1 className='  sm:tw-text-[18px] tw-text-[14px]  tw-font-extrabold sm:tw-mt-6 tw-mt-3 '>Grafting</h1>
                                <p className=' tw-mt-4 tw-text-[12px]'>Revitalize your landscape with our expert grafting service, meticulously performed to promote stronger, disease-resistant plants and unlock unique varieties for a distinctive and thriving outdoor environment.</p>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1, delay: 0.45 } }} className=' tw-relative  tw-max-w-[300px] tw-text-justify  '>
                                <img className=' tw-z-10' src={Images.Images.shifting} alt="" />
                                <img src={Images.Images.shadowm} className=' tw-absolute tw-ml-[-1px] -tw-mt-4 -tw-z-10' alt="" />
                                <h1 className=' sm:tw-text-[18px] tw-text-[14px]  tw-font-extrabold sm:tw-mt-6 tw-mt-3 '>Plant Shifting</h1>
                                <p className=' tw-mt-4 tw-text-[12px]'>Transform your landscape effortlessly with our plant shifting service, expertly relocating and arranging plants to optimize aesthetics and create dynamic outdoor spaces tailored to your preferences.</p>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1, delay: 0.5 } }} className='  tw-relative tw-max-w-[300px] tw-text-justify  '>
                                <img className=' tw-z-10' src={Images.Images.nourish} alt="" />
                                <img src={Images.Images.shadowm} className=' tw-absolute tw-ml-[-1px] -tw-mt-4 -tw-z-10' alt="" />
                                <h1 className='  sm:tw-text-[18px] tw-text-[14px] tw-font-extrabold sm:tw-mt-6 tw-mt-3 '>Nourishing</h1>
                                <p className=' tw-mt-4 tw-text-[12px]'>Ensure the vitality of your landscape with our nourishing service, providing essential nutrients and care to plants for robust growth, vibrant foliage, and lasting beauty.</p>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1, delay: 0.55 } }} className=' tw-relative  tw-max-w-[300px] tw-text-justify  '>
                                <img className=' tw-z-10' src={Images.Images.mulching} alt="" />
                                <img src={Images.Images.shadowm} className=' tw-absolute tw-ml-[-1px] -tw-mt-4 -tw-z-10' alt="" />
                                <h1 className='  sm:tw-text-[18px] tw-text-[14px] tw-font-extrabold sm:tw-mt-6 tw-mt-3 '>Mulching</h1>
                                <p className=' tw-mt-4 tw-text-[12px]'>Enhance soil health and conserve moisture with our mulching service, fostering optimal growing conditions while suppressing weeds and adding aesthetic appeal to your landscape.</p>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1, delay: 0.6 } }} className='  tw-relative tw-max-w-[300px] tw-text-justify  '>
                                <img className=' tw-z-10' src={Images.Images.report} alt="" />
                                <img src={Images.Images.shadowm} className=' tw-absolute tw-ml-[-1px] -tw-mt-4 -tw-z-10' alt="" />
                                <h1 className='  sm:tw-text-[18px] tw-text-[14px] tw-font-extrabold sm:tw-mt-6 tw-mt-3 '>Reporting Plants</h1>
                                <p className=' tw-mt-4 tw-text-[12px]'>Optimize plant health and aesthetics with our reporting service, offering detailed assessments and recommendations to address issues and promote the flourishing of your landscape.</p>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1, delay: 0.65 } }} className=' tw-relative  tw-max-w-[300px] tw-text-justify  '>
                                <img className=' tw-z-10' src={Images.Images.mixing} alt="" />
                                <img src={Images.Images.shadowm} className=' tw-absolute tw-ml-[-1px] -tw-mt-4 -tw-z-10' alt="" />
                                <h1 className='  sm:tw-text-[18px] tw-text-[14px] tw-font-extrabold sm:tw-mt-6 tw-mt-3 '>Mixing of Soil</h1>
                                <p className=' tw-mt-4 tw-text-[12px]'>Elevate plant health and growth potential with our soil mixing service, customizing soil composition to meet the specific needs of your landscape, ensuring optimal nutrient availability and water retention.</p>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1, delay: 0.7 } }} className='  tw-relative tw-max-w-[300px] tw-text-justify  '>
                                <img className=' tw-z-10' src={Images.Images.prepare} alt="" />
                                <img src={Images.Images.shadowm} className=' tw-absolute tw-ml-[-1px] -tw-mt-4 -tw-z-10' alt="" />
                                <h1 className='  sm:tw-text-[18px] tw-text-[14px] tw-font-extrabold sm:tw-mt-6 tw-mt-3 '>Prepare Soil</h1>
                                <p className=' tw-mt-4 tw-text-[12px]'>Enhance plant vitality and growth with our soil preparation service, meticulously cultivating and conditioning soil to create a fertile foundation for healthy and thriving landscapes.</p>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1, delay: 0.75 } }} className=' tw-relative  tw-max-w-[300px] tw-text-justify  '>
                                <img className=' tw-z-10' src={Images.Images.ground} alt="" />
                                <img src={Images.Images.shadowm} className=' tw-absolute tw-ml-[-1px] -tw-mt-4 -tw-z-10' alt="" />
                                <h1 className='  sm:tw-text-[18px] tw-text-[14px] tw-font-extrabold sm:tw-mt-6 tw-mt-3 '>Ground Preparation</h1>
                                <p className=' tw-mt-4 tw-text-[12px]'>Ensure the success of your landscaping projects with our ground preparation service, expertly grading, leveling, and clearing the terrain to lay the groundwork for beautiful and functional outdoor spaces.</p>
                            </motion.div>
                        </div>
                    </div>
                    <div className=' tw-mt-[15%] tw-flex tw-flex-col sm:tw-flex-row '>
                        <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }} className=' tw-flex tw-flex-row tw-mr-8 '>
                            <div className=' tw-mx-2'>
                                <img src={Images.Images.rect1} alt="" />
                            </div>
                            <div>
                                <div className=' tw-mb-[6px]'> <img src={Images.Images.rect2} alt="" /> </div>
                                <div className=' tw-mt-[6px] '><img src={Images.Images.rect3} alt="" /></div>
                            </div>
                        </motion.div>
                        <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }} className=' tw-flex tw-flex-col sm:tw-ml-8 sm:tw-max-w-[50%] sm:tw-mt-0 tw-mt-4'>
                            <div className=' tw-font-semibold  tw-text-[24px] md:tw-text-3xl tw-flex tw-justify-center tw-flex-col '>
                                Cleaning Soil And Make It&nbsp;<br />{
                                    <span className="c-green">
                                        <span className={` ${animate ? "logo is-animetion" : ""}`}>
                                            <span>R</span>
                                            <span>e</span>
                                            <span>a</span>
                                            <span>d</span>
                                            <span>y</span>
                                            <span>&nbsp;</span>
                                            <span>T</span>
                                            <span>o</span>
                                            <span>&nbsp;</span>
                                            <span>U</span>
                                            <span>s</span>
                                            <span>e</span>
                                        </span>
                                    </span>
                                }
                            </div>
                            <div className=' sm:tw-mt-8 tw-mt-6 tw-flex '>
                                <p className=' tw-text-[#707070] tw-text-sm sm:tw-text-[16px] tw-leading-[28.8px] tw-font-Montserrat tw-text-justify'>
                                    Our maintenance services are the ideal choice for ensuring the longevity and vitality of your landscape. With our expertise, we meticulously attend to every aspect of your outdoor space, from weeding and trimming to soil preparation and plant nourishment, ensuring a healthy and thriving environment year-round.
                                </p>
                            </div>

                        </motion.div>

                    </div>
                    <div className=' tw-mt-[10%] tw-flex tw-flex-col'>
                        <motion.div initial={{ opacity: 0, y: -150 }} whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }} className=' tw-font-semibold sm:tw-text-[36px] tw-text-[24px]  tw-flex tw-justify-center '>
                            Choose your&nbsp;{
                                <span className="c-green">
                                    <span className={` ${animate ? "logo is-animetion" : ""}`}>
                                        <span>P</span>
                                        <span>l</span>
                                        <span>a</span>
                                        <span>n</span>
                                        <span>s</span>
                                    </span>
                                </span>
                            }
                        </motion.div>
                        <div className=' tw-flex tw-flex-wrap tw-flex-row sm:tw-justify-between tw-justify-center tw-gap-5 tw-mt-[5%] tw-font-Montserrat'>
                            <div className=' tw-max-w-[250px] tw-border-[1px] tw-border-[#8BCD20]  tw-p-5 tw-rounded-2xl tw-flex tw-flex-col tw-justify-evenly'>
                                <div className=' tw-text-[#8BCD20] tw-font-bold tw-text-[20px]'>
                                    <h1>Starting Plan</h1>
                                </div>
                                <div className=' tw-text-[#707070] tw-text-xs tw-font-light tw-mt-5'>
                                    <p>
                                    Providing our best services as usual, just for you!
                                    </p>
                                </div>
                                <div className=' tw-mt-5 tw-flex tw-flex-row tw-justify-center'>
                                    <div className=' tw-text-[#8BCD20] tw-text-[2.5rem] tw-mt-2 tw-leading-[4rem] tw-font-semibold tw-mr-1'>₹</div>
                                    <div className=' tw-text-[#8BCD20] tw-text-[64px] tw-font-semibold tw-ml-1'  >99</div>
                                </div>
                                <ul className=' tw-font-medium tw-text-xs tw-mt-5  tw-text-left tw-gap-4  tw-text-[#707070] tw-flex tw-flex-col'>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Cleaning Properly</li>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Within 30 minutes </li>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Medicine Spray</li>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Trimming Hedge</li>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Proper Execution</li>
                                </ul>
                            </div>
                            <div className=' tw-max-w-[250px] tw-border-[1px] tw-border-[#8BCD20]  tw-p-5 tw-rounded-2xl tw-flex tw-flex-col tw-justify-evenly'>
                                <div className=' tw-text-[#8BCD20] tw-font-bold tw-text-[20px]'>
                                    <h1>Basic Plan</h1>
                                </div>
                                <div className=' tw-text-[#707070] tw-text-xs tw-font-light tw-mt-5'>
                                    <p>
                                    Providing our best services as usual, just for you!
                                    </p>
                                </div>
                                <div className=' tw-mt-5 tw-flex tw-flex-row tw-justify-center'>
                                    <div className=' tw-text-[#8BCD20] tw-text-[2.5rem] tw-mt-2 tw-leading-[4rem] tw-font-semibold tw-mr-1'>₹</div>
                                    <div className=' tw-text-[#8BCD20] tw-text-[64px] tw-font-semibold tw-ml-1'>  299</div>
                                </div>
                                <ul className=' tw-font-medium tw-text-xs tw-mt-5  tw-text-left tw-gap-4  tw-text-[#707070] tw-flex tw-flex-col'>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Cleaning Properly</li>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Within 2 Hours </li>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Medicine Spray</li>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Trimming Hedge</li>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Proper Execution</li>
                                </ul>
                            </div>
                            <div className=' tw-max-w-[250px] tw-border-[1px] tw-border-[#8BCD20]  tw-p-5 tw-rounded-2xl tw-flex tw-flex-col tw-justify-evenly'>
                                <div className=' tw-text-[#8BCD20] tw-font-bold tw-text-[20px]'>
                                    <h1>Standard Plan</h1>
                                </div>
                                <div className=' tw-text-[#707070] tw-text-xs tw-font-light tw-mt-5'>
                                    <p>
                                    Providing our best services as usual, just for you!
                                    </p>
                                </div>
                                <div className=' tw-mt-5 tw-flex tw-flex-row tw-justify-center'>
                                    <div className=' tw-text-[#8BCD20] tw-text-[2.5rem] tw-mt-2 tw-leading-[4rem] tw-font-semibold tw-mr-1'>₹</div>
                                    <div className=' tw-text-[#8BCD20] tw-text-[64px] tw-font-semibold tw-ml-1'>  499</div>
                                </div>
                                <ul className=' tw-font-medium tw-text-xs tw-mt-5  tw-text-left tw-gap-4  tw-text-[#707070] tw-flex tw-flex-col'>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Cleaning Properly</li>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Within 3 Hours </li>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Medicine Spray</li>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Trimming Hedge</li>
                                    <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Proper Execution</li>
                                </ul>
                            </div>
                            <div className=' tw-max-w-[250px] tw-border-[1px] tw-border-[#8BCD20]  tw-p-5 tw-rounded-2xl tw-flex tw-flex-col tw-justify-between'>
                                <div className=' tw-text-[#8BCD20] tw-font-bold tw-text-[20px]'>
                                    <h1>Premium Plan</h1>
                                </div>
                                <div className=' tw-text-[#707070] tw-text-xs tw-font-light tw-mt-5'>
                                    <p>
                                        Providing our best services as usual, just for you!
                                    </p>
                                </div>

                                <div className=' tw-mt-5 tw-flex tw-flex-row tw-justify-center'>
                                    <div className=' tw-text-[#8BCD20] tw-text-[2.5rem] tw-mt-2 tw-leading-[4rem] tw-font-semibold tw-mr-1'>₹</div>
                                    <div className=' tw-text-[#8BCD20] tw-text-[64px] tw-font-semibold tw-ml-1'>  999</div>
                                </div>
                                <div className=' tw-flex tw-flex-col tw-justify-evenly'> 
                                    <ul className=' tw-font-medium tw-text-xs tw-mt-5  tw-text-left tw-gap-4  tw-text-[#707070] tw-flex tw-flex-col tw-mb-2'>
                                        <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Customized services</li>
                                        <li className=' tw-flex'><img src={Images.Images.tick} className=' tw-mr-5' alt="" />Within 4-5 Hours</li>
                                    </ul>
                                    <div className=' '>
                                        <p className='tw-font-medium tw-text-[#707070] tw-text-justify tw-mt-0  tw-text-[12px]'></p>
                                        <p className='tw-font-bold tw-text-[#707070] tw-text-center tw-text-lg tw-mt-0 tw-mb-2'>To Get More Details</p>
                                        <div className='tw-flex tw-justify-center  '><button className='tw-min-w-[180px] tw-min-h-[50px] tw-rounded-[30px] tw-text-[16px] tw-font-semibold tw-mt-0 tw-text-white tw-bg-[#8BCD20] slides tw-transition-shadow tw-ease-in' onClick={handleclick}>Contact us</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </motion.div>

    );
}

export default Maintenance;
