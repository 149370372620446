import React, { useCallback, useEffect, useState } from "react";
import Navbar2 from "../../Navbar2";
import { motion, AnimatePresence } from 'framer-motion'
import Carousel from "react-elastic-carousel";
import LandScapingImg from "./LandScapingImg";
import ReactGA from "react-ga4";
import Connect from "../../Components/Connect";

function LandScaping() {
  const [Data, setData] = useState(LandScapingImg);
  const [dataFilter, setdataFilter] = useState([]);
  const [popUpActive, setActive] = useState(false);
  const [imgIndex, setIndex] = useState(false);
  const [mainImg, setMainImg] = useState(null);
  const [imgs, setImgs] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [isLoading,setLoading] = useState(false)
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  
  const handleSearch = useCallback(() => {
    if (inputValue === '') {
      setData(LandScapingImg);
    } else {
      const filterBySearch = LandScapingImg.TypeOfLandscape.filter((element) =>
        element.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      const filterBySearch2 = LandScapingImg.TypeOfPlantCaringHouse.filter((element) =>
      element.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    const filterBySearch3 = LandScapingImg.TypeOfPlayGroundLandscape.filter((element) =>
    element.name.toLowerCase().includes(inputValue.toLowerCase())
  );
  const filterBySearch4 = LandScapingImg.TypeOfLandscapesBorder.filter((element) =>
  element.name.toLowerCase().includes(inputValue.toLowerCase())
);
const filterBySearch5 = LandScapingImg.TypeOfGardeningLandscape.filter((element) =>
element.name.toLowerCase().includes(inputValue.toLowerCase())
);


      setData((prevData) => ({
        ...prevData,
        TypeOfLandscape: filterBySearch,
        TypeOfPlantCaringHouse: filterBySearch2,
        TypeOfPlayGroundLandscape:filterBySearch3,
        TypeOfLandscapesBorder:filterBySearch4,
        TypeOfGardeningLandscape:filterBySearch5,

      }));
    }
  }, [inputValue]);

 
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "LandScaping" })
    handleSearch();
  }, [handleSearch]);

  const newelement = [
    ...LandScapingImg.TypeOfLandscape,
    ...LandScapingImg.TypeOfPlantCaringHouse,
    ...LandScapingImg.TypeOfPlayGroundLandscape,
    ...LandScapingImg.TypeOfLandscapesBorder,
    ...LandScapingImg.TypeOfGardeningLandscape,

  ].find((element) => element.id === imgIndex);
  const handlePopUp = (index) => {
    const mainelement = [
      ...LandScapingImg.TypeOfLandscape,
      ...LandScapingImg.TypeOfPlantCaringHouse,
      ...LandScapingImg.TypeOfPlayGroundLandscape,
      ...LandScapingImg.TypeOfLandscapesBorder,
      ...LandScapingImg.TypeOfGardeningLandscape,
    ].find((element) => element.id === index);

    setMainImg(mainelement.images[0]);
    setIndex(index);
    setActive(true);
  };
  const handleBack = () => {
    setActive(false);
  };

    const handleClick = (imgUrl) => {
      setMainImg(imgUrl);
    };
    const handlePrev = () => {
      const currentIndex = imgIndex;
    if (currentIndex > 0 && currentIndex <= 7)
    {
      const newIndex = (currentIndex - 1 + LandScapingImg.TypeOfLandscape.length) % LandScapingImg.TypeOfLandscape.length;
      setIndex(newIndex);
      const mainelement = LandScapingImg.TypeOfLandscape.find((element) => element.id === newIndex)
      setMainImg(mainelement.images[0]);
    }
    else if (currentIndex > 20 && currentIndex <= 23)
    {
      const newIndex = (currentIndex - 1 ) ;
      setIndex(newIndex);
      const mainelement = LandScapingImg.TypeOfPlantCaringHouse.find((element) => element.id === newIndex)
      setMainImg(mainelement.images[0]);
    }
    else if (currentIndex > 30 && currentIndex <= 33)
    {
      const newIndex = (currentIndex - 1 ) ;
      setIndex(newIndex);
      const mainelement = LandScapingImg.TypeOfPlayGroundLandscape.find((element) => element.id === newIndex)
      setMainImg(mainelement.images[0]);
    }
    else if (currentIndex > 40 && currentIndex <= 44)
    {
      const newIndex = (currentIndex - 1 ) ;
      setIndex(newIndex);
      const mainelement = LandScapingImg.TypeOfLandscapesBorder.find((element) => element.id === newIndex)
      setMainImg(mainelement.images[0]);
    }
    else if (currentIndex > 50 && currentIndex <= 57)
    {
      const newIndex = (currentIndex - 1 ) ;
      setIndex(newIndex);
      const mainelement = LandScapingImg.TypeOfGardeningLandscape.find((element) => element.id === newIndex)
      setMainImg(mainelement.images[0]);
    }


    else
    {
      alert("Last item reached");
    }
    };

    const handleNext = () => {
     const currentIndex = imgIndex;
    if (currentIndex >= 0 && currentIndex < 7)
    {
      const newIndex = (currentIndex + 1) % LandScapingImg.TypeOfLandscape.length;
      setIndex(newIndex);
      const mainelement = LandScapingImg.TypeOfLandscape.find((element) => element.id === newIndex)
      setMainImg(mainelement.images[0]);
    }
    else if (currentIndex >= 20 && currentIndex < 23)
    {
      
      const newIndex = (currentIndex + 1 ) ;
      setIndex(newIndex);
      const mainelement = LandScapingImg.TypeOfPlantCaringHouse.find((element) => element.id === newIndex)
      setMainImg(mainelement.images[0]);
    }
    else if (currentIndex >= 30 && currentIndex < 33)
    {
      const newIndex = (currentIndex + 1 ) ;
      setIndex(newIndex);
      const mainelement = LandScapingImg.TypeOfPlayGroundLandscape.find((element) => element.id === newIndex)
      setMainImg(mainelement.images[0]);
    }
    else if (currentIndex >= 40 && currentIndex < 44)
    {
      const newIndex = (currentIndex + 1 ) ;
      setIndex(newIndex);
      const mainelement = LandScapingImg.TypeOfLandscapesBorder.find((element) => element.id === newIndex)
      setMainImg(mainelement.images[0]);
    }
    else if (currentIndex >= 50 && currentIndex < 57)
    {
      const newIndex = (currentIndex + 1 ) ;
      setIndex(newIndex);
      const mainelement = LandScapingImg.TypeOfGardeningLandscape.find((element) => element.id === newIndex)
      setMainImg(mainelement.images[0]);
    }



    else
    {
      alert("Last item reached");
    }
    };
  const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];

  return (
    <motion.div initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}>
      <Connect/>
      <Navbar2 />
      <div className=" tw-mb-[10%]">
        <div className=" tw-w-full tw-bg-contact tw-h-[42vh] tw-bg-no-repeat tw-bg-cover tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center">
          <div className=" tw-font-Montserrat tw-text-white tw-text-[36px] tw-font-bold tw-justify-center tw-items-center">
            Landscaping
          </div>
        </div>
        <div className=" tw-flex tw-justify-center tw-items-center tw-mt-20 ] ">
          <div className=" tw-min-w-[90%] tw-flex  tw-flex-col">

            <div>

              <div>
                <div className="tw-flex sm:tw-max-w-[90%] tw-max-w-[100%] tw-text-xs tw-justify-end tw-font-Montserrat sm:tw-ml-[5rem] tw-mb-10">
                  <input
                    style={{
                      width: "295px",
                      border: "1px solid lightgray",
                      padding: "10px 10px",
                      outline: "none",
                      borderRadius: "5px 0px 0px 5px",
                    }}
                    placeholder="Write here item’s name"
                    type="text"
                    value={inputValue}
                    onChange={handleChange}
                  ></input>
                  <button
                    type="submit"
                    onClick={() =>
                      handleSearch()
                    }
                    style={{
                      backgroundColor: "#7BC600",
                      color: "#ffff",
                      padding: "5px 15px",
                      borderRadius: " 0px 5px 5px 0px",
                    }}
                  >
                    Search
                  </button>
                </div>
                <h1 className="sm:tw-ml-[4.95rem] tw-ml-3 tw-mt-[10%] tw-mb-[5%] tw-font-bold tw-text-[24px] md:tw-text-3xl">Type of Landscape</h1>
                <div className=" tw-flex tw-mt-10 tw-justify-center">

                  <Carousel breakPoints={breakPoints}>
                    {Data.TypeOfLandscape.map((images, index) => {
                      return (
                        <div
                          key={index}
                          className="sm:tw-m-4 tw-m-1 tw-flex tw-flex-col tw-grid-cols-3 tw-rounded-md tw-cursor-pointer"
                          style={{
                            border: "1px solid lightgray",
                          }}
                         onClick={() => handlePopUp(images.id)}
                        >

                          <img
                            src={images.url}
                            className="tw-w-[300px] tw-h-[150px] sm:tw-w-[300px] sm:tw-h-[250px]  tw-object-cover tw-p-2 sm:tw-p-[7%] tw-pb-0   "
                          />
                          <h2 className="tw-text-xs sm:tw-text-xl tw-font-bold tw-text-[#808080] tw-text-center tw-font-Montserrat sm:tw-pb-5 tw-py-1">
                            {images.name}
                          </h2>
                        </div>
                      );
                    })}
                  </Carousel>

                </div>
             <AnimatePresence>
                    {popUpActive && (
                      <div className=" tw-fixed tw-z-[80]  tw-top-0 tw-left-0 tw-w-[100%] tw-h-[100%] tw-bg-black tw-bg-opacity-70 ">
                        <motion.div
                          initial={{
                            opacity: 0,
                            y: 60,
                            scale: 0.5,
                          }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            scale: 1,
                            transition: {
                              type: "spring",
                              stiffness: 300,
                            },
                          }}
                          exit={{
                            opacity: 0,
                            scale: 0.5,
                            transition: { duration: 0.3 },
                          }}
                          className=" sm:tw-absolute sm:tw-top-[10%] sm:tw-left-[15%] tw-flex sm:tw-flex-row  sm:tw-min-w-[70%] sm:tw-max-w-[70%] sm:tw-max-h-[80%] sm:tw-min-h-[80%] tw-w-[100%] tw-h-[100%] tw-flex-col tw-justify-between tw-border-4 tw-border-white "
                        >
                          <div className=" tw-flex sm:tw-w-[60%] tw-relative sm:tw-h-full tw-h-[50%]">
                          <div
                       onClick={handleBack}
                       className="  tw-flex tw-bg-cover sm:tw-py-[0.5%] tw-py-0 sm:tw-px-[0.3%] sm:tw-pr-5 tw-px-[2%] tw-items-center tw-justify-center tw-border-b-4 tw-border-r-4 tw-border-white tw-bg-[#5D9600] tw-absolute tw-font-Montserrat tw-font-semibold tw-cursor-pointer tw-text-white sm:tw-text-[24px] tw-text-[14px]"
                     >
                       <button><i className="bi bi-chevron-left "></i>&nbsp; Back</button>
                     </div>
                            <AnimatePresence>
                              <div className="tw-flex tw-w-screen tw-h-[50vh] sm:tw-h-[100%] tw-flex-col tw-border-white  tw-border-r-4">
                                <img
                                  src={mainImg || newelement.url}
                                  // alt={`Product Image ${index}`}
                                  className="tw-h-[100%]"
                                />
                                <div className="tw-absolute tw-flex tw-justify-center tw-left-[50%] tw-right-[50%] tw-bottom-0">
                                  <div className="tw-flex xl:tw-gap-5 lg:tw-gap-0 tw-gap-1">
                                    {newelement.images.map((imagee, index) => (
                                      <div className=" tw-flex tw-flex-col tw-cursor-pointer lg:tw-w-[60px] tw-w-[40px]">
                                        <img
                                          className={
                                            mainImg === imagee
                                              ? "tw-relative -tw-translate-y-5  des-img-border tw-object-cover  xl:tw-w-[100px] xl:tw-h-[60px] lg:tw-w-[50px] lg:tw-h-[50px] tw-w-[40px] tw-h-[40px]   tw-transition-all tw-ease-in "
                                              : "des-img-border tw-object-cover  xl:tw-w-[100px] xl:tw-h-[60px] lg:tw-w-[50px] lg:tw-h-[50px] tw-w-[40px] tw-h-[40px]  tw-transition-all tw-ease-in "
                                          }
                                          src={imagee}
                                          onClick={() => handleClick(imagee)}
                                          // style={{
                                          //   width: "100%",
                                          //   height: "70%",
                                          // }}
                                          // alt={`Image 1 ${index}`}
                                        />
                                        <span
                                          className={
                                            mainImg === newelement.images[index]
                                              ? "dis-img-bar tw-relative tw-bottom-5 tw-transition-all tw-ease-in tw-delay-100 "
                                              : "dis-img-bar tw-relative tw-bottom-0 tw-invisible tw-transition-all tw-delay-100 tw-ease-in "
                                          }
                                        ></span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </AnimatePresence>
                          </div>
                          <div className=" tw-overflow-y-auto tw-flex tw-flex-col tw-justify-between tw-bg-white sm:tw-w-[40%] sm:tw-h-[79.1vh]  tw-font-Montserrat">
                            <div className=" tw-flex tw-flex-col tw-justify-around sm:tw-px-[10%] tw-px-3 tw-mt-[10%]">
                              <div className=" tw-flex  tw-justify-center">
                              <h1 className=" tw-text-xl sm:tw-text-2xl xl:tw-text-3xl tw-font-bold tw-text-center tw-text-[#7BC600]">
                                  {newelement.he1} <span className="tw-text-black">
                                  {newelement.he2}
                                    </span>
                                </h1>
                                {/* &nbsp;&nbsp;&nbsp;&nbsp;
                                <h1 className=" tw-text-xl sm:tw-text-2xl xl:tw-text-4xl  tw-font-bold tw-text-center ">
                                  {newelement.he2}
                                </h1> */}
                              </div>
                              <div className=" tw-flex">
                                <h2 className="tw-font-bold  sm:tw-mt-[10%] tw-mt-[5%] tw-text-lg sm:tw-text-xl ">
                                  Description:
                                </h2>
                              </div>
                              <div
                                className=" tw-flex tw-text-[#707070] tw-font-light sm:tw-mt-[6%] tw-mt-[3%] tw-text-justify"
                                style={{ fontSize: "0.8vW" }}
                              >
                                <p>{newelement.Description}</p>
                              </div>
                              <div className=" tw-flex">
                                <h2 className="tw-font-bold sm:tw-mt-[10%] tw-mt-[5%] tw-text-lg sm:tw-text-xl ">
                                  Fact:
                                </h2>
                              </div>
                              <div
                                className=" tw-flex tw-text-[#707070] tw-font-light  sm:tw-mt-[6%] tw-mt-[3%]  tw-text-justify"
                                style={{ fontSize: "0.8vW" }}
                              >
                                <p>{newelement.Fact}</p>
                              </div>
                            </div>
                            <div className="tw-flex tw-w-[100%] tw-left-0 tw-text-lg sm:tw-sticky  tw-sticky tw-bottom-0 tw-bg-white ">
                              <button
                                className="tw-text-[#5D9600] tw-font-bold tw-border-4 tw-border-[#5D9600] sm:tw-py-2 tw-w-[50%]"
                                onClick={handlePrev}
                              >
                                Prev
                              </button>
                              <button
                                className="tw-bg-[#7BC600] tw-font-bold  sm:tw-py-2 tw-text-white  tw-w-[50%]"
                                onClick={handleNext}
                              >
                                Next
                              </button>
                            </div>
                          </div>
                        </motion.div>
                      </div>
                    )}
                  </AnimatePresence>
              </div>
              <div>
                <h1 className="sm:tw-ml-[4.95rem] tw-ml-3 tw-mt-[10%] tw-mb-[5%] tw-font-bold tw-text-[24px] md:tw-text-3xl">Type of Plants Caring House</h1>
                <div className=" tw-flex  tw-justify-center">

                  <Carousel breakPoints={breakPoints}>
                    {Data.TypeOfPlantCaringHouse.map((images, index) => {

                      return (
                        <div
                          key={index}
                          className=" sm:tw-m-4 tw-m-1 tw-flex tw-flex-col tw-grid-cols-3 tw-rounded-md tw-cursor-pointer"
                          style={{
                            border: "1px solid lightgray",
                          }}
                          onClick={() => handlePopUp(images.id)}
                        >

                          <img
                            src={images.url}
                            className="tw-w-[300px] tw-h-[150px] sm:tw-w-[300px] sm:tw-h-[250px]  tw-object-cover tw-p-2 sm:tw-p-[7%] tw-pb-0   "
                          />
                          <h2 className="tw-text-xs sm:tw-text-xl tw-font-bold tw-text-[#808080] tw-text-center tw-font-Montserrat sm:tw-pb-5 tw-py-1">
                            {images.name}
                          </h2>
                        </div>
                      );
                    })}
                  </Carousel>

                </div>
              </div>
              <div>
                <h1 className="sm:tw-ml-[4.95rem] tw-ml-3 tw-mt-[10%] tw-mb-[5%] tw-font-bold tw-text-[24px] md:tw-text-3xl">Type of Play Ground Landscape</h1>
                <div className=" tw-flex  tw-justify-center">

                  <Carousel breakPoints={breakPoints}>
                    {Data.TypeOfPlayGroundLandscape.map((images, index) => {

                      return (
                        <div
                          key={index}
                          className=" sm:tw-m-4 tw-m-1 tw-flex tw-flex-col tw-grid-cols-3 tw-rounded-md tw-cursor-pointer"
                          style={{
                            border: "1px solid lightgray",
                          }}
                          onClick={() => handlePopUp(images.id)}
                        >

                          <img
                            src={images.url}
                            className="tw-w-[300px] tw-h-[150px] sm:tw-w-[300px] sm:tw-h-[250px]  tw-object-cover tw-p-2 sm:tw-p-[7%] tw-pb-0   "
                          />
                          <h2 className="tw-text-xs sm:tw-text-xl tw-font-bold tw-text-[#808080] tw-text-center tw-font-Montserrat sm:tw-pb-5 tw-py-1">
                            {images.name}
                          </h2>
                        </div>
                      );
                    })}
                  </Carousel>

                </div>
              </div>
              <div>
                <h1 className="sm:tw-ml-[4.95rem] tw-ml-3 tw-mt-[10%] tw-mb-[5%] tw-font-bold tw-text-[24px] md:tw-text-3xl">Type of Landscape's Border</h1>
                <div className=" tw-flex  tw-justify-center">

                  <Carousel breakPoints={breakPoints}>
                    {Data.TypeOfLandscapesBorder.map((images, index) => {

                      return (
                        <div
                          key={index}
                          className=" sm:tw-m-4 tw-m-1 tw-flex tw-flex-col tw-grid-cols-3 tw-rounded-md tw-cursor-pointer"
                          style={{
                            border: "1px solid lightgray",
                          }}
                          onClick={() => handlePopUp(images.id)}
                        >

                          <img
                            src={images.url}
                            className="tw-w-[300px] tw-h-[150px] sm:tw-w-[300px] sm:tw-h-[250px]  tw-object-cover tw-p-2 sm:tw-p-[7%] tw-pb-0   "
                          />
                          <h2 className="tw-text-xs sm:tw-text-xl tw-font-bold tw-text-[#808080] tw-text-center tw-font-Montserrat sm:tw-pb-5 tw-py-1">
                            {images.name}
                          </h2>
                        </div>
                      );
                    })}
                  </Carousel>

                </div>
              </div>
              <div>
                <h1 className="sm:tw-ml-[4.95rem] tw-ml-3 tw-mt-[10%] tw-mb-[5%] tw-font-bold tw-text-[24px] md:tw-text-3xl">Type of Gardening Landscape</h1>
                <div className=" tw-flex  tw-justify-center">

                  <Carousel breakPoints={breakPoints}>
                    {Data.TypeOfGardeningLandscape.map((images, index) => {

                      return (
                        <div
                          key={index}
                          className="sm:tw-m-4 tw-m-1 tw-flex tw-flex-col tw-grid-cols-3 tw-rounded-md tw-cursor-pointer"
                          style={{
                            border: "1px solid lightgray",
                          }}
                          onClick={() => handlePopUp(images.id)}
                        >

                          <img
                            src={images.url}
                            className="tw-w-[300px] tw-h-[150px] sm:tw-w-[300px] sm:tw-h-[250px]  tw-object-cover tw-p-2 sm:tw-p-[7%] tw-pb-0   "
                          />
                          <h2 className="tw-text-xs sm:tw-text-xl tw-font-bold tw-text-[#808080] tw-text-center tw-font-Montserrat sm:tw-pb-5 tw-py-1">
                            {images.name}
                          </h2>
                        </div>
                      );
                    })}
                  </Carousel>

                </div>
              </div>
            </div>
        
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default LandScaping