import React from "react";

function frame4() {
  return (
    <div className="d-flex flex-column c-gray font-Montserrat slider-text">
      <h5
        className="text-center tw-mb-5"
        style={{ fontWeight: "bolder", color: "black",fontSize: "24px"  }}
      >
        Rose Bed
      </h5>
      <p>
        A Rose bed can be as simple as a single rose specimen interspersed
        with a few other plants. It can be as elaborate as a formal landscape
        embellished with hardscaping, arbors, seating, and statuary. Even
        smaller spaces can accommodate roses in containers, raised beds, or
        narrow side yards. Their stems are usually prickly and their glossy,
        green leaves have toothed edges. Rose flowers vary in size and shape.
        They burst with colours ranging from pastel pink, peach, and cream, to
        vibrant yellow, orange, and red.
      </p>
    </div>
  );
}

export default frame4;
