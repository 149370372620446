const clientData=[
    {id: 1,category:'School', img:  require('../Images/partners/partner1.png')},
    {id: 2,category:'School', img:  require('../Images/partners/partner2.png')},
    {id: 3,category:'Industry', img:  require('../Images/partners/partner3.png')},
    {id: 4,category:'Hospital', img:  require('../Images/partners/partner4.png')},
    {id: 5,category:'University', img:  require('../Images/partners/partner5.png')},
    {id: 6,category:'University', img:  require('../Images/partners/partner6.png')},
    {id: 7,category:'University', img:  require('../Images/partners/partner8.png')},
    {id: 8,category:'Hotel', img:  require('../Images/partners/partner9.png')},
    {id: 9,category:'School', img:  require('../Images/partners/partner10.png')},
    {id: 10,category:'Industry', img:  require('../Images/partners/partner11.png')},
    {id: 11,category:'Hospital', img:  require('../Images/partners/partner12.png')},
    {id: 12,category:'Industry', img:  require('../Images/partners/partner13.png')},
    {id: 13,category:'Hospital', img:  require('../Images/partners/partner14.png')},
    {id: 14,category:'Industry', img:  require('../Images/partners/partner15.png')},
    {id: 15,category:'Residence', img:  require('../Images/partners/partner16.png')},
    {id: 16,category:'Resort', img:  require('../Images/partners/partner17.png')}
    
]

export default clientData;