import React from "react";
function frame3() {
  return (
    <div className=" d-flex flex-column c-gray font-Montserrat slider-text">
      <h5
        className="text-center tw-mb-5"
        style={{ fontWeight: "bolder", color: "black",fontSize: "24px"  }}
      >
        Flowering Bed
      </h5>
      <p>
        Flowering bed landscapes are more than just a pretty addition to your
        property. They provide a multitude of benefits that can enhance your
        life and the environment around you. Flowering plants attract many
        pollinators like bees, butterflies, and hummingbirds, which are
        essential for plant reproduction and a healthy ecosystem.
      </p>
    </div>
  );
}

export default frame3;
