import React from 'react';
import whatsappicon from "../Images/whatsappIcon.png"
import callicon from "../Images/callicon.png"

function Connect  ()  {
    return (
        <div class="tw-fixed tw-right-5 tw-bottom-[17%] tw-bg-[#E3E3E3] sm:tw-py-[20px] tw-py-4 tw-shadow-xl  tw-rounded-[35px] tw-px-[10px] t tw-z-[100]" >
        <div style={{display:"flex",flexDirection:'column',}}>
            <a href="tel:1800 121 2441" target='__blank'><img src={callicon} style={{marginBottom:'30px'}} alt="" className="whatsapp hover:tw-scale-125 tw-transition-all tw-duration-300 tw-ease-in-out"  /></a>
            <a href="https://wa.me/919339023422?text=Hello,+I+am+interested+in+knowing+more+about+TARAaang+Landscapes" target='__blank'><img src={whatsappicon} alt="" className="call hover:tw-scale-110 tw-transition-all tw-duration-300 tw-ease-in-out" /></a>
            
            
            </div>
      </div>
    );
}

export default Connect;
