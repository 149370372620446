import React, { useReducer, useContext, createContext,useEffect } from 'react';

const CartStateContext = createContext();
const CartDispatchContext = createContext();

const localStorageKey = 'cart';

const reducer = (state, action) => {
    switch (action.type) {
        case "ADD":
            return [...state, { id: action.id, name: action.name, qty: action.qty, img: action.url }];
        case "REMOVE":
            let newArr = [...state];
            newArr.splice(action.index, 1);
            return newArr;
        case "DROP":
            return [];
        case "UPDATE":
            return state.map((product) => {
                if (product.id === action.id) {
                    return { ...product, qty: parseInt(action.qty) + product.qty };
                }
                return product;
            });
        case "INCREMENT":
            return state.map((product, index) => {
                if (index === action.index) {
                    return { ...product, qty: product.qty + 1 };
                }
                return product;
            });
        case "DECREMENT":
            return state.map((product, index) => {
                if (index === action.index && product.qty > 1) {
                    return { ...product, qty: product.qty - 1 };
                }
                return product;
            });
        case "UPDATE_QUANTITY":
            return state.map((product, index) => {
                if (index === action.index) {
                    return { ...product, qty: action.quantity };
                }
                return product;
            });
        default:
            console.log("Error in Reducer");
            return state;
    }
};

 
export const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, [],initial => {
        const localData = localStorage.getItem(localStorageKey);
        return localData ? JSON.parse(localData) : initial;
    });
    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(state));
    }, [state]);

    return (
        <CartDispatchContext.Provider value={dispatch}>
            <CartStateContext.Provider value={state}>
                {children}
            </CartStateContext.Provider>
        </CartDispatchContext.Provider>
    )
};

export const useCart = () => useContext(CartStateContext);
export const useDispatchCart = () => useContext(CartDispatchContext);