import React, { useEffect, useRef, useState } from 'react';
import Images from '../Images';
import AnimateContext from '../Components/useContext'
import { useContext } from 'react'
import {
    Input,
    initTE,
} from "tw-elements";

import { motion } from "framer-motion";
import Connect from '../Components/Connect';

import Navbar2 from '../Navbar2';
import ReactGA from "react-ga4";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


initTE({ Input });


function Contact() {
    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Contact" })
    },[])
    const animate = useContext(AnimateContext)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',
        text: ''
    });
    const form = useRef();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();   
        emailjs
        .sendForm('service_acpkiah', 'template_s1o1m2i', form.current, {
          publicKey: 'QjRwuvW-lY_CGkfrE',
        })
        .then(
          () => {
            setFormData({
                name: '',
                email: '',
                number: '',
                text: ''
            });
            toast.success("Email Sent✅");
          },
          (error) => {
            toast.error('FAILED...', error.text);
          },
        );
        console.log(formData);
    };
  
    return (

        <motion.div initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{duration: 0.7,ease: [0.6, -0.05, 0.01, 0.99]}} className='tw-mb-[10%] overflow-hidden'>
       
          <Navbar2/>
            <Connect/>
            <div>
  
    </div>
            <div className=' tw-w-full tw-bg-contact tw-h-[42vh] tw-bg-cover tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center'>
                <div className=' tw-font-Montserrat tw-text-white tw-text-[36px] tw-font-bold tw-justify-center tw-items-center'>CONTACT US</div>
            </div>
            <div>
            <div className=' tw-flex tw-justify-center tw-items-center sm:tw-mt-130 tw-mt-20 '>
                <div className=' tw-min-w-[90%] tw-flex tw-max-w-[90%] tw-flex-col'>
                    <div className=' tw-flex sm:tw-flex-row tw-flex-col tw-justify-around '>
                        <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}   className=' tw-flex sm:tw-w-max sm:tw-h-max'>
                            <img src={Images.Images.logo_contact} alt="" className=' tw-w-max tw-h-max' />
                        </motion.div>
                        <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}  className=' tw-flex sm:tw-ml-6 tw-max-w-[800px] sm:tw-mt-0 tw-mt-8 tw-flex-col '>
                            <div className=' tw-flex tw-flex-col tw-font-Montserrat tw-font-light sm:tw-text-[20px] tw-text-[16px] tw-text-[#707070] tw-text-justify '>
                                Feel free to talk to our representative at any time you please use our contact form on our website or one of our contact numbers. Let us build your future together.You can always visit us at our HQ, we have a friendly staff and a mean cup of coffee.
                                <br></br>
                                <br></br>

                                <span className=' tw-font-bold'>For any urgency call us</span>
                            </div>
                            <div className=' tw-flex tw-mt-8 tw-flex-col tw-font-Montserrat tw-text-[16px] tw-text-[#505050]'>
                                <div className=' tw-py-1 tw-my-1 tw-flex tw-flex-row '><img src={Images.Images.location} className=' tw-w-6 '  alt="" /><div className=' tw-ml-8'>Barrackpore, Kol - 700121</div></div>
                                <div className=' tw-py-1 tw-my-1 tw-flex tw-flex-row '><img src={Images.Images.call} className=' tw-w-6 ' alt="" /><div className=' tw-ml-8'>1800 121 2441</div></div>
                                <div className=' tw-py-1 tw-my-1 tw-flex tw-flex-row '><img src={Images.Images.email} className=' tw-w-6 ' alt="" /><div className=' tw-ml-8'>taraangmithun@gmail.com</div></div>
                                <div className=' tw-py-1 tw-my-1 tw-flex tw-flex-row '><img src={Images.Images.website} className=' tw-w-6 ' alt="" /><a href="" className=' tw-ml-8'>taraaanglandscape.com</a></div>
                            </div>
                        </motion.div>
                    </div>
                  
                </div>

            </div>
            <div className=' sm:tw-mt-[10%] tw-mt-16 tw-flex sm:tw-flex-row tw-p-[5%] tw-pb-[12%]   tw-flex-col tw-bg-[#F5F5F5] tw-w-screen'>
                        <motion.div initial={{ opacity: 0, x: -50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}  className=' tw-flex tw-flex-col sm:tw-w-[50%] sm:tw-mr-10 tw-mr-0'>
                            <div>
                                <div className=' tw-font-extrabold sm:tw-text-[54px] tw-text-4xl tw-font-Montserrat'>Get in {
                                    <span className="c-green">
                                        <span className={` ${animate ? "logo is-animetion" : ""}`}>
                                            <span>T</span><span>o</span><span>u</span><span>c</span><span>h</span>
                                        </span>
                                    </span>
                                }&nbsp;</div>
                                <div className=' tw-font-Montserrat sm:tw-text-[14px] tw-text-[12px] tw-text-[#909090] tw-font-bold tw-mt-6'>Hey! We Are Looking Forward To Start a Project With You.</div>
                            </div>
                            <form ref={form}  onSubmit={handleSubmit}>
            <div className='tw-flex tw-flex-col tw-mt-8'>
                <div className='tw-my-3 tw-w-[100%] tw-flex '>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        className="tw-w-[100%] rounded-md border border-[#E0E0E0] bg-white py-3 px-6 text-base font-medium text-[#828282] outline-none focus:border-[#6A64F1] focus:shadow-md placeholder:tw-p-4 tw-font-Montserrat"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>
                <div className='tw-my-3 tw-w-[100%]'>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        className="tw-w-[100%] rounded-md border border-[#E0E0E0] bg-white py-3 px-6 text-base font-medium text-[#828282] outline-none focus:border-[#6A64F1] focus:shadow-md placeholder:tw-p-4 tw-font-Montserrat"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
                <div className='tw-my-3 tw-w-[100%]'>
                    <input
                        type="tel"
                        name="number"
                        id="number"
                        placeholder="Phone number"
                        className="tw-w-[100%] rounded-md border border-[#E0E0E0] bg-white py-3 px-6 text-base font-medium text-[#828282] outline-none focus:border-[#6A64F1] focus:shadow-md placeholder:tw-p-4 tw-font-Montserrat"
                        value={formData.number}
                        onChange={handleChange}
                    />
                </div>
                <div className='tw-my-3 tw-w-[100%]'>
                    <input
                        name="text"
                        id="text"
                        placeholder="Your message here"
                        className="tw-w-[100%] rounded-md border border-[#E0E0E0] bg-white py-3 px-6 text-base font-medium text-[#828282] outline-none focus:border-[#6A64F1] focus:shadow-md placeholder:tw-p-4 tw-font-Montserrat"
                        value={formData.text}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className='tw-mt-3 tw-w-[100%]'>
                <button type="submit" value="Send" className='tw-w-[100%] tw-bg-[#7BC600] tw-h-[50px] tw-font-Montserrat tw-font-bold tw-text-white hover:tw-bg-[#678636]'>SEND</button>
            </div>
        </form>
                            <div className=' tw-mt-10 tw-flex tw-flex-row'>
                                <div className=' tw-flex tw-flex-row '>
                                    <div className=' sm:tw-mr-6 tw-flex justify-center tw-items-center  tw-mb-4 sm:tw-mb-0'>
                                        <img src={Images.Images.phoneh} alt="" />
                                    </div>
                                    <div className='tw-font-Montserrat sm:tw-ml-0 tw-ml-2'>
                                        <div className=' tw-font-bold'>PHONE</div>
                                        <div className='tw-text-sm tw-font-semibold tw-text-[#456E00] tw-max-w-[200px] tw-w-[115px]'><a href="tel:1800 121 2441">1800-121-2441</a></div>
                                    </div>
                                </div>
                                <div className=' tw-flex tw-flex-row sm:tw-ml-16'  >
                                    <div className=' sm:tw-mr-6 tw-flex justify-center tw-items-center tw-mb-4 sm:tw-mb-0'>
                                       <img src={Images.Images.emailh} alt="" />
                                    </div>
                                    <div className='tw-font-Montserrat sm:tw-ml-0 tw-ml-2 '>
                                        <div className=' tw-font-Montserrat tw-font-bold'>EMAIL</div>
                                        <div className='sm:tw-text-sm tw-text-xs tw-font-semibold tw-text-[#456E00]'><a href="mailto:taraangmithun@gmail.com">taraangmithun@gmail.com</a></div>
                                    </div>
                                </div>
                            </div>

                        </motion.div>
                        <motion.div initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0, transition: { duration: 1 } }}  className=' sm:tw-w-[50%]  sm:tw-ml-10 sm:tw-mt-0 tw-mt-[10%] sm:tw-h-[35rem] tw-h-[20rem]  '>


                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58869.435608235646!2d88.29131085238971!3d22.75277034247843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89aefcc6fe3a7%3A0x6a6d434ac504dbc5!2sBarrackpore%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1704985580407!5m2!1sen!2sin"
                                class="left-0 top-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg" width='100%' height='110%' frameborder="0"
                                allowfullscreen></iframe>

                        </motion.div>
                    </div>
            </div>

          
            <ToastContainer />
        </motion.div>

       

    );
}

export default Contact;