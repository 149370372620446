import React from "react";
import { useCart, useDispatchCart } from "./ContextReducer";
import Images from "../Images";
import { useState, useEffect } from "react";

const Cart = ({ onClose }) => {
    const [cart, setCart] = useState([]);
    let data = useCart();
    let dispatch = useDispatchCart();
    useEffect(() => {
        const localCart = localStorage.getItem('cart');
        if (localCart) {
            setCart(JSON.parse(localCart));
        }
    }, []);

    if (data.length === 0) {
        return (
            <div className="tw-fixed tw-z-[85] tw-top-0 tw-left-0 tw-w-[100%] tw-h-[100%] tw-bg-black tw-bg-opacity-70">
                <div className="tw-z-[86] tw-bg-white sm:tw-w-[60%] tw-w-full tw-h-[60%] container m-auto mt-5 table-responsive table-responsive-sm table-responsive-md tw-flex tw-flex-col tw-justify-center tw-items-center sm:*:tw-text-5xl tw-relative">
                    <div className="tw-font-Montserrat">The Cart is Empty!</div>
                    <div
                        onClick={onClose}
                        className="tw-absolute tw-top-[5%] tw-right-[5%] tw-text-3xl tw-font-bold tw-cursor-pointer"
                    >
                        <img
                            className="tw-w-[30px]"
                            src={Images.Images.circle}
                            alt="circle"
                        ></img>
                    </div>
                </div>
            </div>
        );
    }

    const formatCartItems = (cartItems) => {
        let message = "Cart Items:\n";
        cartItems.forEach((item, index) => {
            message += `${index + 1}. ${item.name} - Qty: ${item.qty}\n`;
        });
        return message;
    };

    const cartItemsMessage = formatCartItems(data);
    const phoneNumber = "+919339023422";
    const encodedMessage = encodeURIComponent(cartItemsMessage);
    const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    const handleWhatsAppCheckout = () => {
        // Open the WhatsApp checkout link in a new tab
        window.open(url, "_blank");
    };

    const handleIncrement = (index) => {
        dispatch({ type: "INCREMENT", index });
    };

    const handleDecrement = (index) => {
        dispatch({ type: "DECREMENT", index });
    };

    const handleChange = (index, value) => {
        const quantity = parseInt(value, 10);
        if (!isNaN(quantity) && quantity > 0) {
            dispatch({ type: "UPDATE_QUANTITY", index, quantity });
        }
    };

    return (
        <div className="tw-fixed tw-z-[85] tw-top-0 tw-left-0 tw-w-[100%] tw-h-[100%] tw-bg-black tw-bg-opacity-70">
            <div className="container m-auto mt-5 table-responsive table-responsive-sm table-responsive-md tw-shadow-xl tw-shadow-[#ffffff1c] tw-z-[86] tw-bg-white md:tw-w-[60%] tw-w-[90%] tw-h-[60%] tw-relative">
                <table className="table table-hover tw-mb-[10%] ">
                    <thead className="text-success">
                        <tr className="md:tw-text-base tw-text-sm">
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Quantity</th>
                            <th scope="col" onClick={onClose}>
                                <img
                                    className="md:tw-w-[25px] tw-w-[15px] tw-cursor-pointer hover:sca"
                                    src={Images.Images.circle}
                                    alt="circle"
                                ></img>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="md:tw-text-base tw-text-sm">
                        {data.map((food, index) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{food.name}</td>
                                <td className="tw-flex tw-gap-5">
                                    <button onClick={() => handleDecrement(index)}>-</button>
                                    <input
                                        type="number"
                                        className="tw-w-[50px] tw-text-center tw-border rounded"
                                        value={food.qty}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                    />
                                    <button onClick={() => handleIncrement(index)}>+</button>
                                </td>
                                <td>
                                    <button
                                        type="button"
                                        className="btn p-0"
                                        onClick={() => {
                                            dispatch({ type: "REMOVE", index: index });
                                        }}
                                    >
                                        <img
                                            src={Images.Images.bin}
                                            className="tw-w-[20px]"
                                            alt=""
                                        />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="md:tw-text-base tw-text-sm tw-relative tw-bg-white tw-left-0 tw-right-0 tw-bottom-0 md:tw-w-[60%] tw-w-[90%] tw-mx-auto tw-z-[86] tw-flex tw-justify-center py-2">
                <button
                    className="btn tw-bg-[#7BC600] tw-flex tw-gap-4 tw-items-center tw-justify-center md:tw-text-base tw-text-sm"
                    onClick={handleWhatsAppCheckout}
                >
                    Check Out to WhatsApp
                    <img
                        className="md:tw-w-[25px] tw-w-[20px]"
                        src={Images.Images.whatsapp}
                        alt="circle"
                    ></img>
                </button>
            </div>
        </div>
    );

};

export default Cart;
