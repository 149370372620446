import React from "react";
import logo from "./Images/Tarang-logo.png";
import { Link, useLocation } from "react-router-dom";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useBreakpointValue,
  Button,
  Stack,
} from '@chakra-ui/react'
import { GiHamburgerMenu } from 'react-icons/gi'

function Navbar() {
  const {pathname} = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isDrawer = useBreakpointValue({ base: true, md: false });

  return (
    <header className='md:text-white w-screen h-fit flex-row flex justify-around md:mx-6 gap-32 lg:gap-56  items-center nav-div-sm tw-z-50'>

    {isDrawer ? (
    <>
      <button className=" tw-w-0 tw-float-end tw-z-10 tw-relative tw-right-14 tw-top-14 tw-hidden" onClick={onOpen}><GiHamburgerMenu className="humb" size={25} /></button>
      <Drawer colorScheme='blackAlpha' isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader ><img src={logo} alt="phone" /></DrawerHeader>
            <DrawerBody >
               <Stack>
               <div className="d-flex flex-row align-items-center justify-content-between nav-div-nest">
        <div>
          <ul style={{ listStyle: "none" }} className="tw-flex pt-4 pb-2 tw-flex-col">
            <li className={pathname==="/"?" c-green d-flex flex-column align-items-center tw-bg-[#e3ffc4] hover:tw-text-[#87D900]":"item-div d-flex flex-column align-items-center hover:tw-text-[#87D900]"}><Link className="hover:tw-text-[#87D900]" to='/'>HOME</Link>
            {/* <span className={pathname==="/"?"  hover-bar-path relative":"relative hover-bar"}></span> */}
            </li>
            <li className="tw-text-gray-400 d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"><Link className="hover:tw-text-[#87D900]" to='/products'>PRODUCTS</Link>
             <span className="relative hover-bar "></span>
            </li>
            <li className=" tw-text-gray-400 d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"><Link className="hover:tw-text-[#87D900]" to='/ourServices'> SERVICES</Link>
            <span className="relative hover-bar"></span>
            </li>
            <li className="tw-text-gray-400 d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"><Link className="hover:tw-text-[#87D900]" to='/certificates'>CERTIFICATES</Link>
             <span className="relative hover-bar"></span>
            </li>
            <li className="tw-text-gray-400 d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"><Link className="hover:tw-text-[#87D900]" to='/clients'>OUR CLIENTS</Link>
            <span className="relative hover-bar"></span>
            </li>
            <li className="tw-text-gray-400 d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"><Link className="hover:tw-text-[#87D900]" to='/teams'> OUR TEAMS</Link>
            <span className="relative hover-bar"></span>
            </li>
            <li className="tw-text-gray-400 d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"><Link className="hover:tw-text-[#87D900]" to='/about'>ABOUT US</Link>
             <span className="relative hover-bar"></span>
            </li>
            <li className="tw-text-gray-400 d-flex flex-column align-items-center hover:tw-bg-[#e3ffc4] hover:tw-text-[#87D900]"><Link className="hover:tw-text-[#87D900]" to='/contact'>CONTACT</Link>
             <span className="relative hover-bar"></span>
            </li>
          </ul>
        </div>
      </div>
               </Stack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  ) : (
    <div className="nav-div tw-top-0 bg-white tw-z-50">
      <div className="d-flex flex-row align-items-center justify-content-between mx-5 px-4 nav-div-nest">
        <div className="p-1">
          <img src={logo} alt="logo"></img>
        </div>
        <div>
          <ul style={{ listStyle: "none",fontSize: "1.2vW" }} className="d-flex tw-flex-1 tw-items-center">
            <li className={pathname==="/"?" tw-text-[#86241D] tw-font-bold item-div d-flex flex-column align-items-center":"item-div d-flex flex-column align-items-center"}><Link className="hover:tw-text-[#86241D]  hover:tw-font-bold" to='/'>HOME</Link>
               <span className={pathname==="/"?"  hover-bar-path relative":"relative hover-bar"}></span>
            </li>
            <li className="item-div d-flex flex-column align-items-center">
              <Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/products'>
              PRODUCTS
              </Link>
             <span className="relative hover-bar "></span>
            </li>
            <li className="item-div drop d-flex flex-column align-items-center">
              <Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/ourServices'>
                 SERVICES
                </Link>
            <span className="relative hover-bar"></span>
            <ul class=" tw-bg-white menu tw-absolute tw-top-14 border tw-rounded-lg tw-text-gray-700 tw-pt-1">
    
    <li className=" d-flex flex-column tw-py-2 align-items-center tw-border-b-2"><Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/plantSelling'> PLANT SELLING</Link>

  </li>
  <li className=" d-flex flex-column tw-py-2 align-items-center tw-border-b-2"><Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/landscaping'>LANDSCAPING</Link>

  </li>
  <li className=" d-flex flex-column tw-py-2 align-items-center"><Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/maintenance'>MAINTENANCE</Link>
 
  </li>
</ul>
            </li>
            <li className="item-div d-flex flex-column align-items-center"><Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/certificates'>CERTIFICATES</Link>
             <span className="relative hover-bar"></span>
            </li>
            <li className="item-div d-flex flex-column align-items-center"><Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/clients'>OUR CLIENTS</Link>
            <span className="relative hover-bar"></span>
            </li>
            <li className="item-div d-flex flex-column align-items-center"><Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/teams'> OUR TEAMS</Link>
            <span className="relative hover-bar"></span>
            </li>
            <li className="item-div d-flex flex-column align-items-center"><Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/about'>ABOUT US</Link>
             <span className="relative hover-bar"></span>
            </li>
            <li className="item-div d-flex flex-column align-items-center"><Link className="hover:tw-text-[#86241D] hover:tw-font-bold" to='/contact'>CONTACT</Link>
             <span className="relative hover-bar"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )}
</header>
  );
}

export default Navbar;
