import React, { useCallback, useEffect, useState } from "react";
import Navbar2 from "../../Navbar2";
import ProductsImg from "../Prodcuts/ProductsImg";
import { motion, AnimatePresence } from "framer-motion";
import Carousel from "react-elastic-carousel";
import ReactGA from "react-ga4";
import Connect from "../../Components/Connect";
import { useDispatchCart, useCart } from "../../Components/ContextReducer";
import Cart from "../../Components/Cart";
import Images from "../../Images";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Products() {
  let dispatch = useDispatchCart();
  let displaydata = useCart();
  const [qty, setQty] = useState(1);
  const [Data, setData] = useState(ProductsImg);
  const [showCart, setShowCart] = useState(false);
  const [dataFilter, setdataFilter] = useState([]);
  const [popUpActive, setActive] = useState(false);
  const [imgIndex, setIndex] = useState(false);
  const [mainImg, setMainImg] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [imgs, setImgs] = useState(null);
  const [totalQty, setTotalQty] = useState(0);
  const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 3 },
  ];
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleQty = (e) => {
    setQty(e.target.value);
  };
  useEffect(() => {
    let total = 0;
    displaydata.forEach((item) => {
      total += item.qty;
    });
    setTotalQty(total);
  }, [displaydata]);

  console.log(displaydata);

  const handleAddtoCart = async (product) => {
    const existingProductIndex = displaydata.findIndex(
      (item) => item.id === product.id
    );

    if (existingProductIndex !== -1) {
      await dispatch({
        type: "UPDATE",
        id: product.id,
        name: product.name,
        qty: Number(qty) + Number(product.qty),
        img: product.url,
      });
    } else {
      await dispatch({
        type: "ADD",
        id: product.id,
        name: product.name,
        qty: Number(qty) + Number(product.qty),
        img: product.url,
      });
    }
    setQty(1);
    toast.success("Item added to cart", {
      position: "top-right",
    });
  };

  const handleSearch = useCallback(() => {
    if (inputValue === "") {
      setData(ProductsImg);
    } else {
      const filterBySearch = ProductsImg.GardenMachine.filter((element) =>
        element.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      const filterBySearch2 = ProductsImg.PotsImage.filter((element) =>
        element.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      const filterBySearch3 = ProductsImg.GardenTool.filter((element) =>
        element.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      const filterBySearch4 = ProductsImg.SoilImage.filter((element) =>
        element.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      const filterBySearch5 = ProductsImg.ProductsImages.filter((element) =>
        element.name.toLowerCase().includes(inputValue.toLowerCase())
      );

      // Update the data with the  filtered results using a callback function
      setData((prevData) => ({
        ...prevData,
        GardenMachine: filterBySearch,
        PotsImage: filterBySearch2,
        GardenTool: filterBySearch3,
        SoilImage: filterBySearch4,
        ProductsImages: filterBySearch5,
      }));
    }
  }, [inputValue]);

  // Use useEffect to trigger the state update after the component re-rendered
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Products",
    });
    handleSearch();
  }, [handleSearch]);

  const newelement = [
    ...ProductsImg.GardenMachine,
    ...ProductsImg.PotsImage,
    ...ProductsImg.GardenTool,
    ...ProductsImg.SoilImage,
    ...ProductsImg.ProductsImages,
  ].find((element) => element.id === imgIndex);
  const handlePopUp = (index) => {
    const mainelement = [
      ...ProductsImg.GardenMachine,
      ...ProductsImg.PotsImage,
      ...ProductsImg.GardenTool,
      ...ProductsImg.SoilImage,
      ...ProductsImg.ProductsImages,
    ].find((element) => element.id === index);
    setMainImg(mainelement.images[0]);
    setIndex(index);
    setActive(true);
  };

  const handleBack = () => {
    setActive(false);
  };

  const handleClick = (imgUrl) => {
    setMainImg(imgUrl);
  };
  const handlePrev = () => {
    // const currentIndex = imgs.images.indexOf(mainImg);
    const currentIndex = imgIndex;
    if (currentIndex > 0 && currentIndex <= 18) {
      //change it to desired value later
      const newIndex =
        (currentIndex - 1 + ProductsImg.ProductsImages.length) %
        ProductsImg.ProductsImages.length;
      setIndex(newIndex);
      const mainelement = ProductsImg.ProductsImages.find(
        (element) => element.id === newIndex
      );
      setMainImg(mainelement.images[0]);
    } else if (currentIndex > 37 && currentIndex <= 41) {
      const newIndex = currentIndex - 1;
      setIndex(newIndex);
      const mainelement = ProductsImg.PotsImage.find(
        (element) => element.id === newIndex
      );
      setMainImg(mainelement.images[0]);
    } else if (currentIndex > 52 && currentIndex <= 54) {
      const newIndex = currentIndex - 1;
      setIndex(newIndex);
      const mainelement = ProductsImg.SoilImage.find(
        (element) => element.id === newIndex
      );
      setMainImg(mainelement.images[0]);
    } else if (currentIndex > 64 && currentIndex <= 70) {
      const newIndex = currentIndex - 1;
      setIndex(newIndex);
      const mainelement = ProductsImg.GardenTool.find(
        (element) => element.id === newIndex
      );
      setMainImg(mainelement.images[0]);
    } else if (currentIndex > 80 && currentIndex <= 85) {
      const newIndex = currentIndex - 1;
      setIndex(newIndex);
      const mainelement = ProductsImg.GardenMachine.find(
        (element) => element.id === newIndex
      );
      setMainImg(mainelement.images[0]);
    } else {
      alert("Last item reached");
    }
  };

  const handleNext = () => {
    // const currentIndex = imgs.images.indexOf(mainImg);
    // const newIndex = (currentIndex + 1) % imgs.images.length;
    // setMainImg(imgs.images[newIndex]);
    const currentIndex = imgIndex;
    if (currentIndex >= 0 && currentIndex < 18) {
      //change it to desired value later
      const newIndex = (currentIndex + 1) % ProductsImg.ProductsImages.length;
      setIndex(newIndex);
      const mainelement = ProductsImg.ProductsImages.find(
        (element) => element.id === newIndex
      );
      setMainImg(mainelement.images[0]);
    } else if (currentIndex >= 37 && currentIndex < 41) {
      const newIndex = currentIndex + 1;
      setIndex(newIndex);
      const mainelement = ProductsImg.PotsImage.find(
        (element) => element.id === newIndex
      );
      setMainImg(mainelement.images[0]);
    } else if (currentIndex >= 52 && currentIndex < 54) {
      const newIndex = currentIndex + 1;
      setIndex(newIndex);
      const mainelement = ProductsImg.SoilImage.find(
        (element) => element.id === newIndex
      );
      setMainImg(mainelement.images[0]);
    } else if (currentIndex >= 64 && currentIndex < 70) {
      const newIndex = currentIndex + 1;
      setIndex(newIndex);
      const mainelement = ProductsImg.GardenTool.find(
        (element) => element.id === newIndex
      );
      setMainImg(mainelement.images[0]);
    } else if (currentIndex >= 80 && currentIndex < 85) {
      const newIndex = currentIndex + 1;
      setIndex(newIndex);
      const mainelement = ProductsImg.GardenMachine.find(
        (element) => element.id === newIndex
      );
      setMainImg(mainelement.images[0]);
    } else {
      alert("Last item reached");
    }
  };

  console.log(Data.PotsImage);
  return (
    <motion.div
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.7, ease: [0.6, -0.05, 0.01, 0.99] }}
      className="tw-overflow-hidden tw-mb-[10%]"
    >
      <Connect />
      <Navbar2 popUpActive={popUpActive} />
      <div className="">
        <div className=" tw-w-full tw-bg-contact tw-h-[42vh] tw-bg-no-repeat tw-bg-cover tw-bg-black tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center">
          <div className=" tw-font-Montserrat tw-text-white tw-text-[36px] tw-font-bold tw-justify-center tw-items-center">
            Our Products
          </div>
        </div>
        <div className=" tw-flex tw-justify-center tw-items-center tw-mt-20 tw-flex-col ">
          <div className=" tw-min-w-[90%] tw-flex tw-max-w-[90%] tw-flex-col">
            <div className="tw-flex sm:tw-max-w-[90%] tw-max-w-[100%] tw-text-xs tw-justify-end tw-font-Montserrat sm:tw-ml-[5rem] tw-mb-10">
              <input
                style={{
                  width: "295px",
                  border: "1px solid lightgray",
                  padding: "10px 10px",
                  outline: "none",
                  borderRadius: "5px 0px 0px 5px",
                }}
                placeholder="Write here item’s name"
                type="text"
                value={inputValue}
                onChange={handleChange}
              ></input>
              <button
                type="submit"
                onClick={() => handleSearch()}
                style={{
                  backgroundColor: "#7BC600",
                  color: "#ffff",
                  padding: "5px 15px",
                  borderRadius: " 0px 5px 5px 0px",
                }}
              >
                Search
              </button>
            </div>
            <div className=" tw-flex tw-mt-10 tw-justify-center">
              <div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 ">
                {Data.ProductsImages.length <= 0 ? (
                  <h1 className="tw-text-center">Products Not Available</h1>
                ) : (
                  Data.ProductsImages.map((images, index) => {
                    return (
                      <motion.div
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: { duration: 1, delay: images.id * 0.01 },
                        }}
                        key={index}
                        className=" sm:tw-m-4 tw-m-1 tw-flex tw-flex-col tw-grid-cols-3 tw-rounded-md tw-cursor-pointer"
                        style={{
                          border: "1px solid lightgray",
                        }}
                        /* we have to create an onclick function that will trigger a function that will trigger a useState hook, we have to pass the product index/ url to specify the product we want to display */
                      >
                        <img
                          src={images.url}
                          className="tw-w-[300px] tw-h-[150px] sm:tw-w-[300px] sm:tw-h-[250px]  tw-object-cover tw-p-2 sm:tw-p-[7%] tw-pb-0   "
                          onClick={() => handlePopUp(images.id)}
                        />
                        <h2 className="tw-text-xs sm:tw-text-xl tw-font-bold tw-text-[#808080] tw-text-center tw-font-Montserrat sm:tw-pb-5 tw-py-1">
                          {images.name}
                        </h2>
                        <hr />
                        <div className=" tw-flex lg:tw-flex-row tw-flex-col lg:gap-0 gap-2 tw-justify-between tw-font-Montserrat m-2 tw-font-semibold">
                          <button
                            className=" tw-px-3 tw-rounded tw-h-[40px] md:tw-text-base tw-text-sm tw-bg-[#7BC600]"
                            onClick={() => handleAddtoCart(images)}
                          >
                            Add to cart
                          </button>
                          <div className=" tw-flex tw-justify-center md:gap-0 gap-3 md:tw-text-base tw-text-sm tw-items-center ">
                            Qty:
                            <select
                              className=" md:tw-h-[40px] tw-h-[25px] tw-w-[50px] tw-bg-slate-200 tw-text-black  md:tw-text-base tw-text-sm tw-text-center rounded"
                              style={{ select: "#FF0000" }}
                              onBlur={handleQty}
                            >
                              {Array.from(Array(6), (e, i) => {
                                return (
                                  <option
                                    className=" flex"
                                    key={i + 1}
                                    value={i + 1}
                                  >
                                    {i + 1}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </motion.div>
                    );
                  })
                )}
              </div>
            </div>

            <AnimatePresence>
              {popUpActive && (
                <div className=" tw-fixed tw-z-[80]  tw-top-0 tw-left-0 tw-w-[100%] tw-h-[100%] tw-bg-black tw-bg-opacity-70 ">
                  <motion.div
                    initial={{
                      opacity: 0,
                      y: 60,
                      scale: 0.5,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      scale: 1,
                      transition: {
                        type: "spring",
                        stiffness: 300,
                      },
                    }}
                    exit={{
                      opacity: 0,
                      scale: 0.5,
                      transition: { duration: 0.3 },
                    }}
                    className=" sm:tw-absolute sm:tw-top-[10%] sm:tw-left-[15%] tw-flex sm:tw-flex-row  sm:tw-min-w-[70%] sm:tw-max-w-[70%] sm:tw-max-h-[80%] sm:tw-min-h-[80%] tw-w-[100%] tw-h-[100%] tw-flex-col tw-justify-between tw-border-4 tw-border-white "
                  >
                    <div className=" tw-flex sm:tw-w-[60%] tw-relative sm:tw-h-full tw-h-[50%]">
                      <div
                        onClick={handleBack}
                        className="  tw-flex tw-bg-cover sm:tw-py-[0.5%] tw-py-0 sm:tw-px-[0.3%] sm:tw-pr-5 tw-px-[2%] tw-items-center tw-justify-center tw-border-b-4 tw-border-r-4 tw-border-white tw-bg-[#5D9600] tw-absolute tw-font-Montserrat tw-font-semibold tw-cursor-pointer tw-text-white sm:tw-text-[24px] tw-text-[14px]"
                      >
                        <button>
                          <i className="bi bi-chevron-left "></i>&nbsp; Back
                        </button>
                      </div>
                      <AnimatePresence>
                        <div className="tw-flex tw-w-screen tw-h-[50vh] sm:tw-h-[100%] tw-flex-col tw-border-white  tw-border-r-4">
                          <img
                            src={mainImg || newelement.url}
                            // alt={`Product Image ${index}`}
                            className="tw-h-[100%]"
                          />
                          <div className="tw-absolute tw-flex tw-justify-center tw-left-[50%] tw-right-[50%] tw-bottom-0">
                            <div className="tw-flex  xl:tw-gap-5 lg:tw-gap-0 tw-gap-1">
                              {newelement.images.map((imagee, index) => (
                                <div className=" tw-flex tw-flex-col tw-cursor-pointer lg:tw-w-[60px] tw-w-[40px]">
                                  <img
                                    className={
                                      mainImg === imagee
                                        ? "tw-relative -tw-translate-y-5  des-img-border xl:tw-w-[100px] xl:tw-h-[60px] lg:tw-w-[50px] lg:tw-h-[50px] tw-w-[40px] tw-h-[40px]  tw-object-cover  tw-transition-all tw-ease-in "
                                        : "des-img-border tw-object-cover  xl:tw-w-[100px] xl:tw-h-[60px] lg:tw-w-[50px] lg:tw-h-[50px] tw-w-[40px] tw-h-[40px]  tw-transition-all tw-ease-in "
                                    }
                                    src={imagee}
                                    onClick={() => handleClick(imagee)}
                                    // style={{
                                    //   width: "100px",
                                    //   height: "60px",
                                    // }}
                                    // alt={`Image 1 ${index}`}
                                  />
                                  <span
                                    className={
                                      mainImg === newelement.images[index]
                                        ? "dis-img-bar tw-relative tw-bottom-5 tw-transition-all tw-ease-in tw-delay-100 "
                                        : "dis-img-bar tw-relative tw-bottom-0 tw-invisible tw-transition-all tw-delay-100 tw-ease-in "
                                    }
                                  ></span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </AnimatePresence>
                    </div>
                    <div className=" tw-overflow-y-auto tw-flex tw-flex-col tw-justify-between tw-bg-white sm:tw-w-[40%] sm:tw-h-[79.1vh]  tw-font-Montserrat">
                      <div className=" tw-flex tw-flex-col tw-justify-around sm:tw-px-[5%] tw-px-3 tw-mt-[10%]">
                        <div className=" tw-flex  tw-justify-center">
                          <h1 className=" tw-text-xl sm:tw-text-2xl xl:tw-text-3xl tw-font-bold tw-text-center tw-text-[#7BC600]">
                            {newelement.he1}{" "}
                            <span className="tw-text-black">
                              {newelement.he2}
                            </span>
                          </h1>
                          {/* &nbsp;&nbsp;&nbsp;&nbsp;
                                <h1 className=" tw-text-xl sm:tw-text-2xl xl:tw-text-3xl  tw-font-bold tw-text-center ">
                                  {newelement.he2}
                                </h1> */}
                        </div>
                        <div className=" tw-flex">
                          <h2 className="tw-font-bold  sm:tw-mt-[10%] tw-mt-[5%] tw-text-lg sm:tw-text-xl ">
                            Description:
                          </h2>
                        </div>
                        <div
                          className=" tw-flex tw-text-[#707070] tw-font-light sm:tw-mt-[6%] tw-mt-[3%] tw-text-justify"
                          style={{ fontSize: "0.8vW" }}
                        >
                          <p>{newelement.Description}</p>
                        </div>
                        <div className=" tw-flex">
                          <h2 className="tw-font-bold sm:tw-mt-[10%] tw-mt-[5%] tw-text-lg sm:tw-text-xl ">
                            Fact:
                          </h2>
                        </div>
                        <div
                          className=" tw-flex tw-text-[#707070] tw-font-light  sm:tw-mt-[6%] tw-mt-[3%]  tw-text-justify"
                          style={{ fontSize: "0.8vW" }}
                        >
                          <p>{newelement.Fact}</p>
                        </div>
                      </div>
                      <div className="tw-flex tw-w-[100%] tw-left-0 tw-text-lg sm:tw-sticky  tw-sticky tw-bottom-0 tw-bg-white ">
                        <button
                          className="tw-text-[#5D9600] tw-font-bold tw-border-4 tw-border-[#5D9600] sm:tw-py-2 tw-w-[50%]"
                          onClick={handlePrev}
                        >
                          Prev
                        </button>
                        <button
                          className="tw-bg-[#7BC600] tw-font-bold sm:tw-py-2 tw-text-white  tw-w-[50%]"
                          onClick={handleNext}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </motion.div>
                </div>
              )}
            </AnimatePresence>
          </div>
          <div className=" sm:tw-min-w-[90%] tw-min-w-[100%] tw-mt-[10%]">
            <h1 className="sm:tw-ml-[4.95rem3]  tw-ml-3 tw-font-bold tw-text-[24px] md:tw-text-3xl">
              Types of Pots
            </h1>
            <div className=" tw-flex sm:tw-mt-10 tw-mt-4 tw-justify-center tw-gap-2">
              <Carousel itemPadding={[0, 0]} breakPoints={breakPoints}>
                {Data.PotsImage.map((images, index) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0, x: 50 }}
                      whileInView={{
                        opacity: 1,
                        x: 0,
                        transition: { duration: 1, delay: index * 0.01 },
                      }}
                      key={index}
                      className=" sm:tw-m-4 tw-m-1 tw-flex tw-flex-col tw-grid-cols-3  tw-rounded-md tw-cursor-pointer"
                      style={{
                        border: "1px solid lightgray",
                      }}
                    >
                      <div onClick={() => handlePopUp(images.id)}>
                        <img
                          src={images.url}
                          className="tw-w-[300px] tw-h-[150px] sm:tw-w-[300px] sm:tw-h-[250px]  tw-object-cover tw-p-2 sm:tw-p-[7%] tw-pb-0   "
                        />
                        <h2 className="tw-text-xs sm:tw-text-xl tw-font-bold tw-text-[#808080] tw-text-center tw-font-Montserrat sm:tw-pb-5 tw-py-1">
                          {images.name}
                        </h2>
                      </div>
                      <div className=" tw-flex lg:tw-flex-row tw-flex-col lg:gap-0 gap-2 tw-justify-between tw-font-Montserrat m-2 tw-font-semibold">
                        <button
                          className=" tw-px-3 tw-rounded tw-h-[40px] md:tw-text-base tw-text-sm tw-bg-[#7BC600]"
                          onClick={() => handleAddtoCart(images)}
                        >
                          Add to cart
                        </button>
                        <div className=" tw-flex tw-justify-center md:gap-0 gap-3 md:tw-text-base tw-text-sm tw-items-center ">
                          Qty:
                          <select
                            className=" md:tw-h-[40px] tw-h-[25px] tw-w-[50px] tw-bg-slate-200 tw-text-black  md:tw-text-base tw-text-sm tw-text-center rounded"
                            style={{ select: "#FF0000" }}
                            onBlur={handleQty}
                          >
                            {Array.from(Array(6), (e, i) => {
                              return (
                                <option
                                  className=" flex"
                                  key={i + 1}
                                  value={i + 1}
                                >
                                  {i + 1}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </motion.div>
                  );
                })}
              </Carousel>
            </div>
          </div>
          <div className=" sm:tw-min-w-[90%] tw-min-w-[100%] tw-mt-[10%]">
            <h1 className="sm:tw-ml-[4.95rem] tw-ml-3 tw-font-bold tw-text-[24px] md:tw-text-3xl ">
              Types of Soil
            </h1>
            <div className=" tw-flex sm:tw-mt-10 tw-mt-4 tw-justify-center tw-gap-2">
              <Carousel itemPadding={[0, 0]} breakPoints={breakPoints}>
                {Data.SoilImage.map((images, index) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0, x: 50 }}
                      whileInView={{
                        opacity: 1,
                        x: 0,
                        transition: { duration: 1, delay: index * 0.01 },
                      }}
                      key={index}
                      className=" sm:tw-m-4 tw-m-1 tw-flex tw-flex-col tw-grid-cols-3  tw-rounded-md tw-cursor-pointer"
                      style={{
                        border: "1px solid lightgray",
                      }}
                    >
                      <div onClick={() => handlePopUp(images.id)}>
                        <img
                          src={images.url}
                          className="tw-w-[300px] tw-h-[150px] sm:tw-w-[300px] sm:tw-h-[250px]  tw-object-cover tw-p-2 sm:tw-p-[7%] tw-pb-0   "
                        />
                        <h2 className="tw-text-xs sm:tw-text-xl tw-font-bold tw-text-[#808080] tw-text-center tw-font-Montserrat sm:tw-pb-5 tw-py-1">
                          {images.name}
                        </h2>
                      </div>
                      <div className=" tw-flex lg:tw-flex-row tw-flex-col lg:gap-0 gap-2 tw-justify-between tw-font-Montserrat m-2 tw-font-semibold">
                        <button
                          className=" tw-px-3 tw-rounded tw-h-[40px] md:tw-text-base tw-text-sm tw-bg-[#7BC600]"
                          onClick={() => handleAddtoCart(images)}
                        >
                          Add to cart
                        </button>
                        <div className=" tw-flex tw-justify-center md:gap-0 gap-3 md:tw-text-base tw-text-sm tw-items-center ">
                          Qty:
                          <select
                            className=" md:tw-h-[40px] tw-h-[25px] tw-w-[50px] tw-bg-slate-200 tw-text-black  md:tw-text-base tw-text-sm tw-text-center rounded"
                            style={{ select: "#FF0000" }}
                            onBlur={handleQty}
                          >
                            {Array.from(Array(6), (e, i) => {
                              return (
                                <option
                                  className=" flex"
                                  key={i + 1}
                                  value={i + 1}
                                >
                                  {i + 1}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </motion.div>
                  );
                })}
              </Carousel>
            </div>
          </div>
          <div className=" sm:tw-min-w-[90%] tw-min-w-[100%] tw-mt-[10%]">
            <h1 className="sm:tw-ml-[4.95rem] tw-ml-3 tw-font-bold tw-text-[24px] md:tw-text-3xl ">
              Gardening Tools
            </h1>
            <div className=" tw-flex sm:tw-mt-10 tw-mt-4 tw-justify-center tw-gap-2">
              <Carousel itemPadding={[0, 0]} breakPoints={breakPoints}>
                {Data.GardenTool.map((images, index) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0, x: 50 }}
                      whileInView={{
                        opacity: 1,
                        x: 0,
                        transition: { duration: 1, delay: index * 0.01 },
                      }}
                      key={index}
                      className=" sm:tw-m-4 tw-m-1 tw-flex tw-flex-col tw-grid-cols-3  tw-rounded-md tw-cursor-pointer"
                      style={{
                        border: "1px solid lightgray",
                      }}
                    >
                      <div onClick={() => handlePopUp(images.id)}>
                        <img
                          src={images.url}
                          className="tw-w-[300px] tw-h-[150px] sm:tw-w-[300px] sm:tw-h-[250px]  tw-object-cover tw-p-2 sm:tw-p-[7%] tw-pb-0   "
                        />
                        <h2 className="tw-text-xs sm:tw-text-xl tw-font-bold tw-text-[#808080] tw-text-center tw-font-Montserrat sm:tw-pb-5 tw-py-1">
                          {images.name}
                        </h2>
                      </div>
                      <div className=" tw-flex lg:tw-flex-row tw-flex-col lg:gap-0 gap-2 tw-justify-between tw-font-Montserrat m-2 tw-font-semibold">
                        <button
                          className=" tw-px-[2%] tw-rounded tw-h-[40px] md:tw-text-base tw-text-sm tw-bg-[#7BC600]"
                          onClick={() => handleAddtoCart(images)}
                        >
                          Add to cart
                        </button>
                        <div className=" tw-flex tw-justify-center md:gap-0 gap-3 md:tw-text-base tw-text-sm tw-items-center ">
                          Qty:
                          <select
                            className=" md:tw-h-[40px] tw-h-[25px] tw-w-[50px] tw-bg-slate-200 tw-text-black  md:tw-text-base tw-text-sm tw-text-center rounded"
                            style={{ select: "#FF0000" }}
                            onBlur={handleQty}
                          >
                            {Array.from(Array(6), (e, i) => {
                              return (
                                <option
                                  className=" flex"
                                  key={i + 1}
                                  value={i + 1}
                                >
                                  {i + 1}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </motion.div>
                  );
                })}
              </Carousel>
            </div>
          </div>
          <div className=" sm:tw-min-w-[90%] tw-min-w-[100%] tw-mt-[10%]">
            <h1 className="sm:tw-ml-[4.95rem] tw-ml-3 tw-font-bold tw-text-[24px] md:tw-text-3xl">
              Gardening Machines
            </h1>
            <div className=" tw-flex sm:tw-mt-10 tw-mt-4 tw-justify-center tw-gap-2">
              <Carousel itemPadding={[0, 0]} breakPoints={breakPoints}>
                {Data.GardenMachine.map((images, index) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0, x: 50 }}
                      whileInView={{
                        opacity: 1,
                        x: 0,
                        transition: { duration: 1, delay: index * 0.01 },
                      }}
                      key={index}
                      className=" sm:tw-m-4 tw-m-1 tw-flex tw-flex-col tw-grid-cols-3  tw-rounded-md tw-cursor-pointer"
                      style={{
                        border: "1px solid lightgray",
                      }}
                    >
                      <div onClick={() => handlePopUp(images.id)}>
                        <img
                          src={images.url}
                          className="tw-w-[300px] tw-h-[150px] sm:tw-w-[300px] sm:tw-h-[250px]  tw-object-cover tw-p-2 sm:tw-p-[7%] tw-pb-0   "
                        />
                        <h2 className="tw-text-xs sm:tw-text-xl tw-font-bold tw-text-[#808080] tw-text-center tw-font-Montserrat sm:tw-pb-5 tw-py-1">
                          {images.name}
                        </h2>
                      </div>
                                            <div className=" tw-flex lg:tw-flex-row tw-flex-col lg:gap-0 gap-2 tw-justify-between tw-font-Montserrat m-2 tw-font-semibold">
                        <button
                          className=" tw-px-[2%] tw-rounded tw-h-[40px] md:tw-text-base tw-text-sm tw-bg-[#7BC600]"
                          onClick={() => handleAddtoCart(images)}
                        >
                          Add to cart
                        </button>
                        <div className=" tw-flex tw-justify-center md:gap-0 gap-3 md:tw-text-base tw-text-sm tw-items-center ">
                          Qty:
                          <select
                            className=" md:tw-h-[40px] tw-h-[25px] tw-w-[50px] tw-bg-slate-200 tw-text-black  md:tw-text-base tw-text-sm tw-text-center rounded"
                            style={{ select: "#FF0000" }}
                            onBlur={handleQty}
                          >
                            {Array.from(Array(6), (e, i) => {
                              return (
                                <option
                                  className=" flex"
                                  key={i + 1}
                                  value={i + 1}
                                >
                                  {i + 1}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </motion.div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>

        <div className="tw-relative tw-bg-black">
          <div
            className=" tw-fixed tw-right-5 tw-z-40 tw-bottom-[45%] flex tw-justify-center tw-items-center tw-shadow-xl  tw-bg-[#7BC600] tw-w-[56px] tw-h-[56px] tw-rounded-full"
            onClick={() => setShowCart(true)}
          >
            <span className=" tw-absolute tw-right-0 tw-top-0 tw-bg-red-600 tw-rounded-full tw-z-50 tw-w-[25px] hover:tw-scale-125 tw-cursor-pointer tw-transition-all tw-ease-in-out tw-duration-300 tw-h-[25px] tw-flex tw-items-center tw-justify-center tw-text-xs tw-text-white">
              {totalQty}
            </span>
            <img
              src={Images.Images.cart}
              className="tw-w-[50%] tw-mx-auto tw-mt-[15px] hover:tw-scale-125 tw-cursor-pointer tw-transition-all tw-ease-in-out tw-duration-300"
              alt=""
            />

            {/* <div className=" tw-font-Montserrat mt-2 tw-text-lg tw-font-semibold">My cart</div> */}
          </div>
        </div>
        {showCart && <Cart onClose={() => setShowCart(false)} />}
      </div>
      <ToastContainer />
    </motion.div>
  );
}

export default Products;
